const _ = require('lodash')

export const entity = {
    name: 'EndowmentHistory',
    fields: [
        {type: 'Exercise'},
        {path: 'date', type: 'date'},
        {path: 'user', type: 'CUser'},
        {path: 'status', type: 'UserStatus'},
        {path: 'exerciseLimit'},
        {path: 'limitByBenefit'}
    ],
    filters: [
        {
            name: "byExercise",
            path: "exercise",
            object: "Exercise",
            display: "code",
            client: true,
            nullable: true,
            isDefault: false,
            default: {id: null},
            query: function (context) {
                const exerciseId = _.get(context, "data.exercise.id");
                if (!exerciseId) return {}
                return {exercise: new global.ObjectID(exerciseId)};
            }
        },
        {
            name: "byUser",
            path: "user",
            object: "CUser",
            display: "fullName",
            client: true,
            nullable: true,
            isDefault: false,
            default: {id: null},
            query: function (context) {
                const userId = _.get(context, "data.user.id");

                if (!userId) return {}
                return {user: new global.ObjectID(userId)};
            }
        },
        {
            name: "byUserStatus",
            path: "status",
            object: "UserStatus",
            display: "name",
            client: true,
            nullable: true,
            isDefault: false,
            default: {id: null},
            query: function (context) {
                const statusId = _.get(context, "data.status.id");

                if (!statusId) return {}
                return {status: statusId};
            }
        },
    ]
}

export const module_ = {
    object: 'EndowmentHistory',
    tKey: 'mTitle_endowmentHistory',
    kp: true,
    displayLog: false,
    category: {
        path: 'administrator',
        icon: 'clipboard'
    },
    viewMap: {
        dt: [
            {path: 'exercise', display: 'code'},
            {path: 'user', display: 'fullName'},
            {path: 'status', display: 'name', initiallyNotVisible: true, translate: true},
            {path: 'date', initiallyNotVisible: true},
            'exerciseLimit',
            {path: 'limitByBenefit', dynamic: true}
        ]
    },
    filters: ['byExercise', 'byUser', 'byUserStatus']
}
