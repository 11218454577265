export const colourStyles = {
    menu: provided => ({
        ...provided,
        zIndex: 99,
    }),
    valueContainer:(provided) => ({
        ...provided,
        maxHeight: '120px',
        overflow: 'auto',
    }),
    control: (provided, state) => {
        const colorRange = state.getValue()['0']?.optionData?.colorRange
        const background = !!colorRange?
            {
                background: `linear-gradient(to right, ${colorRange[0]}, ${colorRange[1]})`,
                caretColor: 'transparent',
            }
            : {}
        const isDisabled = state.isDisabled
        return {
        ...provided,
        /*
        maxHeight: '120px',
        overflow: 'auto',

         */
        backgroundColor: 'white',
        ...background,
        pointerEvents: isDisabled
            ? 'auto'
            : provided.pointerEvents,
        borderColor: isDisabled
            ? '#e3e3e3'
            : '#a1a1a1',
        cursor: isDisabled
            ? 'not-allowed'
            : 'pointer',
        minHeight: '50px',
        color: 'rgb(72 72 72)',
        //outline: 'none !important',
        //borderColor: '#CCCCCC !important',
        //boxShadow:'none !important',
        ':hover': {
            ...provided[':hover'],
            color: 'rgb(72 72 72)',
            borderColor: isDisabled
                ? '#e3e3e3'
                : 'rgb(0 0 0 / 50%)'
        },
        ':focus': {
            ...provided[':focus'],
            //outline: 'none !important',
            //boxShadow:'none !important',
        }
    }},
    option: (provided, { data, isDisabled, isFocused, isSelected, selectProps: {selectedColor} }) => {
        const background = !!data.optionData?.colorRange?
            {background: `linear-gradient(to right, ${data.optionData.colorRange[0]}, ${data.optionData.colorRange[1]})`}
            : {}
        const height = data.label === '' ? {height: 35} : {}
        return {
            ...provided,
            ...background, //'linear-gradient(to right, #0076CE, #E9DCC9)',
            ...height,
            backgroundColor: isSelected
                ? 'white'
                : isFocused
                    ? '#ebf5ff'
                    : provided.backgroundColor,
            color: !isDisabled
                ? data.value === 'selectAll'
                    ? '#1e74d9'
                    : isSelected
                        ? selectedColor || '#e94e24'
                        : 'black'
                : '#ccc',
            cursor: isDisabled
                ? 'not-allowed'
                : 'pointer',

            ':active': {
                ...provided[':active'],
                backgroundColor: isDisabled
                    ? provided[':active'].backgroundColor
                    : isSelected
                        ? 'white'
                        : isFocused
                            ? '#bbdcff'
                            :provided[':active'].backgroundColor,
            },
            fontWeight: isDisabled
                ? provided.fontWeight
                : data.value === 'selectAll'
                    ? 500
                    :  isSelected
                        ? 500
                        : provided.backgroundColor
        };
    },
    singleValue: styles => ({ ...styles,
        color: 'rgb(72 72 72)'
    }),

    multiValue: (provided, {data}) => {
        return {
            ...provided,
            border: data.notFound ? '1px solid #ffc3c3' : '1px solid #c2e0ff',
            backgroundColor: data.notFound ? '#FFEBEBFF' : '#ebf5ff',
        };
    },
    multiValueLabel: (provided, {data, selectProps: {style = {}}}) => {
        console.log('style', style)
        return ({
            ...provided,
            color: data.notFound ? '#FF5A5AFF' : '#007eff',
            ...style
        })
    },
    multiValueRemove: (provided, {data, isDisabled, selectProps}) => {
        return {
            ...provided,
            display: isDisabled || !selectProps.isClearable ? 'none' : 'flex',
            ':hover': {
                ...provided[':hover'],
                backgroundColor: '#d5dfe9',
                color: 'black'
            }
        }
    }
    ,
};
