import _ from 'lodash'
import {basicContext} from "../../utils/contextUtils";
import {decrypt} from "../../utils/crypto"
import {setFieldVisibility} from "../../../apps/KpModule/actions";

export const entities = [
    {
        name: "DtObjectsColumn",
        type: "static",
        fields: [
            {path: "data", type: 'integer'},
            {path: "type"}
        ]
    },
    {
        name: "DateDtObjectsColumn",
        type: "static",
        fields: [
            {path: "data", type: 'date'},
            {path: "type"}
        ]
    },
    {
        name: "BooleanDtObjectsColumn",
        type: "static",
        fields: [
            {path: "data", type: 'boolean'},
            {path: "type"}
        ]
    },
    {
        name: 'OrganizationDetail',
        fields: [
            'Exercise',
            {path: 'administratorsNumber', type: 'integer'},
            {path: 'employeesNumber', type: 'integer'},
            {path: 'volunteersNumber', type: 'integer'},
            {path: 'investigatorsNumber', type: 'integer'},
            {path: 'hostFamiliesNumber', type: 'integer'},
            {path: 'ACACEDNumber', type: 'integer'},
        ]
    },
    {
        name: "ManualOrganizationDetail",
        type: "mongoInternal",
        fields: [
            'Exercise',
            {path: 'administratorsNumber', type: "DtObjectsColumn"},
            {path: 'employeesNumber', type: "DtObjectsColumn"},
            {path: 'volunteersNumber', type: "DtObjectsColumn"},
            {path: 'investigatorsNumber', type: "DtObjectsColumn"},
            {path: 'hostFamiliesNumber', type: "DtObjectsColumn"},
            {path: 'ACACEDNumber', type: "DtObjectsColumn"},
        ]
    },
    {
        name: 'OrganizationMembership',
        fields: [
            'Exercise',
            {path: 'upToDate', type: 'boolean'},
            {path: 'date', type: 'date'},
        ]
    },
    {
        name: "ManualOrganizationMembership",
        type: "mongoInternal",
        fields: [
            'Exercise',
            {path: 'upToDate', type: "BooleanDtObjectsColumn"},
            {path: 'date', type: "DateDtObjectsColumn"}
        ]
    },
    {
        name: "OrganizationImage",
        type: "mongoInternal",
        fields: [
            {path: 'name', notEmpty: true},
            'User',
            {path: 'date', notEmpty: true}
        ]
    },
    {
        name: 'Organization',
        facets: [{name: 'codeName', nameOptional: false, uniqueName: false}, 'comments', 'files'],
        fields: [
            {type: 'OrganizationType'},
            {path: 'zone', type: 'Zone'},
            {type: 'Member', nullable: true},
            'address',
            'additionalAddress',
            'zipCode',
            'city',
            'country',
            'siren',
            'siret',
            'rna',
            'accountNumber',
            {path: 'iban', encrypted: true},
            {path: 'bic', encrypted: true},
            {path: 'rup', type: 'boolean'},
            {path: 'endowmentFund', type: 'boolean'},
            {path: 'activities', type: 'OrganizationActivity', link: 'MTM', nullable: true},
            {path: 'logo', type: 'OrganizationImage', link: "OTO"},
            {path: 'images', type: 'OrganizationImage', link: 'OTM'},
            { path: 'lastUser', type: 'User', nullable: true },
            { path: 'lastModificationDate', type: 'date', nullable: true },
            {
                path: 'usersNumber',
                $f: (organization, context, callback) => {
                    global.app.C.CUser.find({
                        ...basicContext(context),
                        fieldPath: ['id', 'civility.id', 'civility.name', 'firstname', 'lastname', 'mail', 'phone', 'shelters.id', 'shelters.name', 'functions.id', 'functions.name'],
                        query: {organization: global.ObjectID(organization.id)}
                    }, (e, users) => {
                        callback(
                            e,
                            users.length
                        )
                    })
                }
            },
            {
                path: 'users',
                $f: (organization, context, callback) => {
                    global.app.C.CUser.find({
                        ...basicContext(context),
                        fieldPath: ['id', 'civility.id', 'civility.name', 'firstname', 'lastname', 'mail', 'phone', 'active', 'shelters.id', 'shelters.name', 'functions.id', 'functions.name'],
                        query: {organization: global.ObjectID(organization.id)}
                    }, (e, users) => {
                        callback(
                            e,
                            users.map(user => ({
                                ...user,
                                civility: context.tc(user.civility?.name),
                                mail: decrypt(user.mail),
                                phone: decrypt(user.phone),
                                active: user.active,
                                shelters: user.shelters.map(shelter => shelter.name).join(', '),
                                functions: user.functions.map(userFunction => userFunction.name).join(', ')
                            }))
                        )
                    })
                }
            },
            {
                path: 'ongoingAdministratorsNumber',
                nullable: true,
                fieldPath: ['organizationDetails.administratorsNumber', 'organizationDetails.exercise.exerciseStatus.id'],
                $f: (organization, context, callback) => {
                    const organizationDetailsForOngoingExercise = organization.organizationDetails?.find(object => _.get(object, 'exercise.exerciseStatus.id') === 'ongoing')
                    return callback(null, organizationDetailsForOngoingExercise?.administratorsNumber)
                }
            },
            {
                path: 'ongoingEmployeesNumber',
                nullable: true,
                fieldPath: ['organizationDetails.employeesNumber', 'organizationDetails.exercise.exerciseStatus.id'],
                $f: (organization, context, callback) => {
                    const organizationDetailsForOngoingExercise = organization.organizationDetails?.find(object => _.get(object, 'exercise.exerciseStatus.id') === 'ongoing')
                    return callback(null, organizationDetailsForOngoingExercise?.employeesNumber)
                }
            },
            {
                path: 'ongoingVolunteersNumber',
                nullable: true,
                fieldPath: ['organizationDetails.volunteersNumber', 'organizationDetails.exercise.exerciseStatus.id'],
                $f: (organization, context, callback) => {
                    const organizationDetailsForOngoingExercise = organization.organizationDetails?.find(object => _.get(object, 'exercise.exerciseStatus.id') === 'ongoing')
                    return callback(null, organizationDetailsForOngoingExercise?.volunteersNumber)
                }
            },
            {
                path: 'ongoingInvestigatorsNumber',
                nullable: true,
                fieldPath: ['organizationDetails.investigatorsNumber', 'organizationDetails.exercise.exerciseStatus.id'],
                $f: (organization, context, callback) => {
                    const organizationDetailsForOngoingExercise = organization.organizationDetails?.find(object => _.get(object, 'exercise.exerciseStatus.id') === 'ongoing')
                    return callback(null, organizationDetailsForOngoingExercise?.investigatorsNumber)
                }
            },
            {
                path: 'ongoingHostFamiliesNumber',
                nullable: true,
                fieldPath: ['organizationDetails.hostFamiliesNumber', 'organizationDetails.exercise.exerciseStatus.id'],
                $f: (organization, context, callback) => {
                    const organizationDetailsForOngoingExercise = organization.organizationDetails?.find(object => _.get(object, 'exercise.exerciseStatus.id') === 'ongoing')
                    return callback(null, organizationDetailsForOngoingExercise?.hostFamiliesNumber)
                }
            },
            {
                path: 'ongoingACACEDNumber',
                nullable: true,
                fieldPath: ['organizationDetails.ACACEDNumber', 'organizationDetails.exercise.exerciseStatus.id'],
                $f: (organization, context, callback) => {
                    const organizationDetailsForOngoingExercise = organization.organizationDetails?.find(object => _.get(object, 'exercise.exerciseStatus.id') === 'ongoing')
                    return callback(null, organizationDetailsForOngoingExercise?.ACACEDNumber)
                }
            },
            {
                path: 'manualOrganizationDetails',
                type: 'ManualOrganizationDetail',
                fieldPath: ['organizationDetails.id', 'organizationDetails.exercise.id'],
                //lazy: true,
                $f: (organization,context,callback) => {
                    global.app.C.Exercise.find({
                        ...basicContext(context),
                        fieldPath: ['id', 'exerciseStatus.id'],
                        query: {}
                    }, (e, exercises) => {
                        const objects = exercises.map(exercise => {
                            const type = exercise.exerciseStatus.id === 'finalClosure' ? 'text' : 'editText'
                            const exerciseDetails = organization.organizationDetails?.find(details => details.exercise?.id === exercise.id)
                            return exerciseDetails
                                ? {
                                    ..._.pick(exerciseDetails, 'id', 'exercise'),
                                    administratorsNumber: {data: exerciseDetails.administratorsNumber || 0, type},
                                    employeesNumber: {data: exerciseDetails.employeesNumber || 0, type},
                                    volunteersNumber: {data: exerciseDetails.volunteersNumber || 0, type},
                                    investigatorsNumber: {data: exerciseDetails.investigatorsNumber || 0, type},
                                    hostFamiliesNumber: {data: exerciseDetails.hostFamiliesNumber || 0, type},
                                    ACACEDNumber: {data: exerciseDetails.ACACEDNumber || 0, type},
                                }
                                : {
                                    id: new global.ObjectID(),
                                    exercise: _.pick(exercise, ['id', 'code']),
                                    administratorsNumber: {data: 0, type},
                                    employeesNumber: {data: 0, type},
                                    volunteersNumber: {data: 0, type},
                                    investigatorsNumber: {data: 0, type},
                                    hostFamiliesNumber: {data: 0, type},
                                    ACACEDNumber: {data: 0, type},
                                }
                        })
                        return callback(null, objects)
                    })
                },
                $s: ( manualOrganizationDetails, organization, context, callback) => {
                    organization.organizationDetails = manualOrganizationDetails.map(object => {
                        return {
                            ..._.pick(object, 'id', 'exercise'),
                            administratorsNumber: object.administratorsNumber.data,
                            employeesNumber: object.employeesNumber.data,
                            volunteersNumber: object.volunteersNumber.data,
                            investigatorsNumber: object.investigatorsNumber.data,
                            hostFamiliesNumber: object.hostFamiliesNumber.data,
                            ACACEDNumber: object.ACACEDNumber.data,

                        }
                    })
                    organization.manualOrganizationDetails = null
                    callback()
                }
            },
            {
                path: 'organizationDetails',
                type: 'OrganizationDetail',
                link: {
                    type: 'OTM',
                    onParent: true,
                }
            },
            {
                path: 'manualOrganizationMemberships',
                type: 'ManualOrganizationMembership',
                //lazy: true,
                fieldPath: ['organizationMemberships.id', 'organizationMemberships.exercise.id'],
                $f: (organization,context,callback) => {
                    global.app.C.Exercise.find({
                        ...basicContext(context),
                        fieldPath: ['id', 'exerciseStatus.id'],
                        query: {}
                    }, (e, exercises) => {
                        const objects = exercises.map(exercise => {
                            const editable = exercise.exerciseStatus.id !== 'finalClosure'
                            const exerciseMembership = organization.organizationMemberships?.find(details => details.exercise?.id === exercise.id)
                            return exerciseMembership
                                ? {
                                    ..._.pick(exerciseMembership, 'id', 'exercise'),
                                    upToDate: {data: exerciseMembership.upToDate, type: 'checkbox', disabled: !editable},
                                    date: {data: exerciseMembership.date, type: editable ? 'datePicker' : 'text'},
                                }
                                : {
                                    id: new global.ObjectID(),
                                    exercise: _.pick(exercise, ['id', 'code']),
                                    upToDate: {data: false, type: 'checkbox', disabled: !editable},
                                    date: {data: "", type: editable ? 'datePicker' : 'text'},
                                }
                        })
                        return callback(null, objects)
                    })
                },
                $s: ( manualOrganizationMemberships, organization, context, callback) => {
                    organization.organizationMemberships = manualOrganizationMemberships.map(object => {
                        return {
                            ..._.pick(object, 'id', 'exercise'),
                            upToDate: object.upToDate.data,
                            date: object.date.data
                        }
                    })
                    organization.manualOrganizationMemberships = null
                    callback()
                }
            },
            {
                path: 'organizationMemberships',
                type: 'OrganizationMembership',
                link: {
                    type: 'OTM',
                    onParent: true,
                }
            },
        ],
        filters: [
            {
                name: "isSupplier",
                isDefault: false,
                async: true,
                query: (context, callback) => {
                    global.app.C.OrganizationType.find({
                        ...basicContext(context),
                        fieldPath: ['id'],
                        query: {supplier: true}
                    }, (error, organizationTypes) => {
                        if(error) return callback(error)
                        callback(null, {
                            organizationType: {
                                $in: organizationTypes.map(orgT => global.ObjectID(orgT.id))
                            }
                        })
                    })
                }
            },
            {
                name: 'isAssociation',
                isDefault: false,
                async: true,
                query: (context, callback) => {
                    global.app.C.OrganizationType.find({
                        ...basicContext(context),
                        fieldPath: ['id'],
                        query: {
                            $or: [
                                {association: true},
                                {memberAssociation: true},
                                {internal: true}
                            ]
                        }
                    }, (error, organizationTypes) => {
                        if(error) return callback(error)
                        callback(null, {
                            organizationType: {
                                $in: organizationTypes.map(orgT => global.ObjectID(orgT.id))
                            }
                        })
                    })
                }
            },
            {
                name: "isMemberAssociation",
                isDefault: false,
                async: true,
                query: (context, callback) => {
                    global.app.C.OrganizationType.find({
                        ...basicContext(context),
                        fieldPath: ['id'],
                        query: {memberAssociation: true}
                    }, (error, organizationTypes) => {
                        if(error) return callback(error)
                        callback(null, {
                            organizationType: {
                                $in: organizationTypes.map(orgT => global.ObjectID(orgT.id))
                            }
                        })
                    })
                }
            },
            {
                name: "byUser",
                isDefault: false,
                async: true,
                query: function(context, callback) {
                    global.app.C.CUser.get({kpUser: global.ObjectID(context.user.id)}, {
                        ...basicContext(context),
                        fieldPath: ['id', 'organization.id'],
                    }, (error, cUser) => {
                        if(error) return callback(error)
                        callback(null, {
                            _id: cUser
                                ? global.ObjectID(cUser.organization.id)
                                : null
                        })
                    })
                }
            }
        ],
        beforeSave: (object, oldObject, context, callback) => {
            object.logo = _.sortBy(object.images, 'date' ).reverse()[0]
            object.lastUser = new global.ObjectID(context.user.id)
            object.lastModificationDate = new Date()
            callback(null, object, oldObject)
        }
    }
]

export const module_ = {
    object: 'Organization',
    tKey: 'mTitle_organization',
    defaultSortBy: 'code',
    objectIdentifier: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'phoneBook',
        icon: 'clipboard'
    },
    viewMap: {
        dt: [
            {path: 'organizationType'},
            'code',
            'name',
            {path: 'address', initiallyNotVisible: true},
            {path: 'additionalAddress', initiallyNotVisible: true},
            {path: 'zipCode', initiallyNotVisible: true},
            {path: 'city', initiallyNotVisible: true},
            {path: 'zone', tKey: 'administrativeRegion', initiallyNotVisible: true},
            {path: 'country', initiallyNotVisible: true},
            'rup',
            'endowmentFund',
            {path: 'member', tKey: 'type'},
            'activities',
            {path: 'usersNumber', tKey: 'contactsNumber'},
            {path: 'ongoingAdministratorsNumber', tKey: 'administratorsNumber'},
            {path: 'ongoingEmployeesNumber', tKey: 'employeesNumber'},
            {path: 'ongoingVolunteersNumber', tKey: 'volunteersNumber'},
            {path: 'ongoingInvestigatorsNumber', tKey: 'investigatorsNumber'},
            {path: 'ongoingHostFamiliesNumber', tKey: 'hostFamiliesNumber'},
            {path: 'ongoingACACEDNumber', tKey: 'ACACEDNumber'},
            {path: 'lastUser'},
            {path: 'lastModificationDate'},
        ],
        form: {
            fields: [
                {
                    path: 'organizationType',
                    fieldPath: ['id', 'name', 'memberAssociation'],
                    subscriptions: {
                        onChange: (newValue, oldValue, {store}) => {
                            const visible = !!newValue?.memberAssociation
                            store.dispatch(setFieldVisibility('e_manualOrganizationDetails', visible))
                            store.dispatch(setFieldVisibility('e_manualOrganizationMemberships', visible))
                        }
                    }
                },
                'code',
                'name',
                {
                    path: 'manualOrganizationMemberships',
                    tKey: 'memberships',
                    type: 'dtObjects',
                    fields: [
                        {path: 'exercise', type: 'object', display: 'code'},
                        {path: 'upToDate', tKey: 'upToDateContributions', type: 'customized'},
                        {path: 'date', type: 'customized'},
                    ]
                },
                'address',
                'additionalAddress',
                'zipCode',
                'city',
                {path: 'zone', tKey: 'administrativeRegion'},
                'country',
                'siren',
                'siret',
                'rna',
                'accountNumber',
                'iban',
                'bic',
                'rup',
                'endowmentFund',
                {path: 'member', tKey: 'type'},
                'activities',
                {
                    path: 'users',
                    tKey: 'contacts',
                    type: 'dtObjects',
                    disabled: true,
                    fields: [
                        {path: 'civility'},
                        {path: 'firstname'},
                        {path: 'lastname'},
                        {path: 'mail'},
                        {path: 'phone'},
                        {path: 'shelters'},
                        {path: 'functions'},
                        {path: 'active', type: 'boolean', width: 100}
                    ]
                },
                {
                    path: 'manualOrganizationDetails',
                    tKey: 'structure',
                    type: 'dtObjects',
                    fields: [
                        {path: 'exercise', type: 'object', display: 'code'},
                        {path: 'administratorsNumber', type: 'customized'},
                        {path: 'employeesNumber', type: 'customized'},
                        {path: 'volunteersNumber', type: 'customized'},
                        {path: 'investigatorsNumber', type: 'customized'},
                        {path: 'hostFamiliesNumber', type: 'customized'},
                        {path: 'ACACEDNumber', type: 'customized'},
                    ]
                },
                {path: "images", type: 'logoPicker', placeholder: 'addLogo', maxLength: 1, fieldPath: ['id', 'name', 'user.id', 'user.name', 'date']},
                'files',
                'comments',
                {path: 'organizationMemberships', hidden: true},
                {path: 'organizationDetails', hidden: true}
            ]
        }
    }
}


export const userModule = {
    name: 'MyOrganization',
    object: 'Organization',
    tKey: 'mTitle_myOrganization',
    newable: false,
    removable: false,
    defaultSortBy: 'code',
    objectIdentifier: 'code',
    defaultSortDirection: 'ASC',
    category: {
        path: 'myBinder',
        icon: 'folder'
    },
    viewMap: {
        dt: [
            'code',
            'name',
            {path: 'address', initiallyNotVisible: true},
            {path: 'additionalAddress', initiallyNotVisible: true},
            {path: 'zipCode', initiallyNotVisible: true},
            {path: 'city', initiallyNotVisible: true},
            {path: 'zone', initiallyNotVisible: true},
            {path: 'country', initiallyNotVisible: true},
            'rup',
            'endowmentFund',
            {path: 'member', tKey: 'type'},
            'activities',
            {path: 'ongoingAdministratorsNumber', tKey: 'administratorsNumber'},
            {path: 'ongoingEmployeesNumber', tKey: 'employeesNumber'},
            {path: 'ongoingVolunteersNumber', tKey: 'volunteersNumber'},
            {path: 'ongoingInvestigatorsNumber', tKey: 'investigatorsNumber'},
            {path: 'ongoingHostFamiliesNumber', tKey: 'hostFamiliesNumber'},
            {path: 'ongoingACACEDNumber', tKey: 'ACACEDNumber'}
        ],
        form: {
            fields: [
                'name',
                'address',
                'additionalAddress',
                'zipCode',
                'city',
                {path: 'zone'},
                'country',
                'iban',
                'bic',
                'rup',
                'endowmentFund',
                {path: 'member', tKey: 'type'},
                'activities',
                {
                    path: 'users',
                    tKey: 'contacts',
                    type: 'dtObjects',
                    disabled: true,
                    fields: [
                        {path: 'civility'},
                        {path: 'firstname'},
                        {path: 'lastname'},
                        {path: 'mail'},
                        {path: 'phone'},
                        {path: 'shelters'},
                        {path: 'functions'},
                        {path: 'active', type: 'boolean', width: 100}
                    ]
                },
                {
                    path: 'manualOrganizationDetails',
                    tKey: 'structure',
                    type: 'dtObjects',
                    fields: [
                        {path: 'exercise', type: 'object', display: 'code'},
                        {path: 'administratorsNumber', type: 'customized'},
                        {path: 'employeesNumber', type: 'customized'},
                        {path: 'volunteersNumber', type: 'customized'},
                        {path: 'investigatorsNumber', type: 'customized'},
                        {path: 'hostFamiliesNumber', type: 'customized'},
                        {path: 'ACACEDNumber', type: 'customized'},
                    ]
                },
                {path: "images", type: 'logoPicker', placeholder: 'addLogo', maxLength: 1, fieldPath: ['id', 'name', 'user.id', 'user.name', 'date']},
                'files',
                {path: 'organizationDetails', hidden: true}
            ]
        }
    },
    filters: [
        'byUser'
    ]
}
