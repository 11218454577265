const async = require('async')
const _ = require('lodash')
const moment = require('moment')
const Errors = require("../../utils/Errors").default
const { basicContext } = require("../../utils/contextUtils")
const { workflowMailing } = require("./mails/reallocationWorkflowMails")
const {getFNCSNotificationData} = require('../../utils/notificationData')
const { calculateExerciseEndowment, calculateBenefitEndowment } = require("./utils/refundRequestUtils")

function getAccountEntries(request, bank, context){
    const toDayDate = new Date()
    const earliestDate = toDayDate < request.benefit.exercise.endDate
        ? toDayDate
        : request.benefit.exercise.endDate

    const defaultObject = {
        date: moment(earliestDate).format('YYYY-MM-DD'),
        generated: moment().format("YYYY-MM-DD"),
        journal: 'VEN',
        amount: request.refund,
        wording: request.reference,
        recordType: 'refund',
        issued: request.issued,
        status: request.status,
        group: new global.ObjectID(context.group.id)
    }

    const basicEntries = [
        {...defaultObject, type: 'G', generalAccount: request.benefit.accountNumber, direction: 'C', analyticSection: request.benefit.analyticalCode.code},
        {...defaultObject, type: 'G', generalAccount: '411000000', auxiliaryAccount: request.user.accountNumber, direction: 'D'}
    ]

    if(request.status === 'paid') {
        const date = moment().format('YYYY-MM-DD')
        return [
            ...basicEntries,
            {...defaultObject, type: 'G', generalAccount: '411000000', journal: 'VIR', auxiliaryAccount: request.user.accountNumber, direction: 'C', date},
            {...defaultObject, type: 'G', generalAccount: bank.provisionalAccount, journal: 'VIR', direction: 'D', date}
        ]
    }

    return basicEntries
}

async function getCurrentRefund(user, context) {

    const exercises = await global.app.R.Exercise.find({
        ...basicContext(context),
        query: {exerciseStatus: 'ongoing'}

    })

    const benefits = await global.app.R.Benefit.find({
        ...basicContext(context),
        query: {exercise: {$in: exercises.map(exercise => new global.ObjectID(exercise.id))}}

    })

    const requests = await global.app.R.Refund.find({
        ...basicContext(context),
        fieldPath: ['benefit.id', 'benefit.exercise.id', 'refund'],
        query: {
            user: new global.ObjectID(user.id),
            status: { $nin: ['draft', 'rectification', 'refused', 'reallocated'] },
            benefit: {$in: benefits.map(benefit => new global.ObjectID(benefit.id))}
        }
    })

    const limitByExercise = exercises.reduce((acc, exercise) => ({...acc, [exercise.id]: calculateExerciseEndowment(exercise, user)}), {})

    const refundByExercise = exercises.reduce((acc, exercise) => {
        return {
            ...acc,
            [exercise.id]: requests
                .filter(request => _.get(request, 'benefit.exercise.id') === exercise.id)
                .reduce((acc, request) => acc + parseFloat(request.refund), 0)
        }
    }, {})


    const restByBenefitByExercise = exercises.reduce((acc, exercise) => {
        return {
            ...acc,
            [exercise.id]: benefits
                .filter(benefit => _.get(benefit, 'exercise.id') === exercise.id)
                .reduce((acc, benefit) =>
                    ({
                        ...acc,
                        [benefit.id]: (calculateBenefitEndowment(benefit, user) - requests
                            .filter(request => request.benefit.id === benefit.id)
                            .reduce((acc, request) => acc + parseFloat(request.refund), 0)).toFixed(2)
                    }), {})
        }
    }, {})

    return {limitByExercise, refundByExercise, restByBenefitByExercise}
}

export const entity = {
    name: 'Reallocation',
    facets:['comments', 'files'],
    fields: [
        'ribCSE',
        'reference',
        {path: 'beneficiary', type:'CUser', nullable: true},
        'Benefit',
        {path: 'requests', type: 'Refund', link: 'MTM'},
        {path: 'refundSent', type: 'boolean'},
        {path: 'refundReceived', type: 'boolean'},
        {path: 'reallocationAmount'},
        {
            path: 'exercise',
            fieldPath: ['benefit', 'benefit.exercise.code'],
            $f: function(reallocation, context, callback) {
                callback(null, _.get(reallocation, 'benefit.exercise.code'))
            }
        },
        {
            path: 'registrationNumber',
            fieldPath: ['beneficiary', 'beneficiary.registrationNumber'],
            $f: function(reallocation, context, callback) {
                callback(null, _.get(reallocation, 'beneficiary.registrationNumber'))
            }
        },
        {path: 'date'},
        {path: 'status'},
        {
            path: 'sequence', unique: true, ps: {
                object: [{
                    type: 'nextSequence',
                    sequenceId: 'r.reallocationSeq',
                    formatResult: result => `${result}`
                }]
            }
        },
        {
            path: 'amountToRepay',
            fieldPath: ['requests.refund'],
            $f: function (reallocation, context, callback) {
                if(reallocation.requests && reallocation.requests !== 0) {
                    const amount = reallocation.requests.filter(request => ['waiting', 'actualized', 'validated', 'paid'].includes(request.status)).reduce((acc, request) => {
                        return acc + parseFloat(request.refund)
                    }, 0)
                    callback(null, amount.toFixed(2))
                }
            }
        },
        {
            path: 'ongoingPayment',
            fieldPath: ['requests.refund'],
            $f: function (reallocation, context, callback) {
                const amount = reallocation.requests
                    .filter(request => ['validated', 'waiting', 'actualized'].includes(request.status))
                    .reduce((acc, request) => acc + parseFloat(request.refund), 0)
                callback(null, amount.toFixed(2))
            }
        },
        {
            path: 'noDeleteButtonAccess',
            $f: function (reallocation, context, callback) {
                const module = context.clientContext && context.clientContext.moduleId
                if(module === 'm-R-reallocationRequest')
                    callback(null, !['waitingPayment', 'rectification'].includes(reallocation.status))
                else
                    callback(null, true)
            }
        },
        {
            path: 'greenStyledRow',
            fieldPath: ['status'],
            $f: function (reallocation, context, callback) {
                const module = context.clientContext && context.clientContext.moduleId

                const status = reallocation.status

                if(module === 'm-R-reallocationRequest')
                    callback(null, ['waitingPayment', 'rectification'].includes(status))
                else if(module === 'm-R-reallocationValidation')
                    callback(null, status === 'ongoing')
                else
                    callback(null, true)

            }
        },
    ],
    filters: [
        {
            name: 'byUser',
            isDefault: false,
            async: true,
            query: (context, callback) => {
                global.app.R.CUser.collection.findOne(
                    { kpUser: new global.ObjectID(context.user.id) },
                    (e, user) => {
                        if(e) callback(e)
                        const userId = user && user._id

                        callback(
                            null,
                            userId
                                ? {beneficiary: new global.ObjectID(userId)}
                                : {beneficiary: false}
                        )
                    }
                )
            }
        }

    ],
    ps: {
        context: [{
            $$u: function (context, callback) {
                if (this.options.accessType === 'S' && context.restAction && context.restAction.crudType === 'C') {
                    context.internalFieldPath = [
                        ...new Set([
                            ...context.internalFieldPath,
                            'sequence'
                        ])
                    ]
                }
                callback(null, context)
            }
        }]
    },
    OnlyOneOngoingBenefitReallocation: function(newObject, oldObject, context, callback) {
        const action  = context.restAction && context.restAction.crudType
        if(action === 'C') {
            const benefitId = _.get(newObject, 'benefit.id')
            global.app.R.CUser.collection.findOne(
                {kpUser: new global.ObjectID(context.user.id)},
                (e, cUser) => {
                    global.app.R.Reallocation.collection.findOne(
                        {
                            beneficiary:  new global.ObjectID(cUser._id),
                            benefit: new global.ObjectID(benefitId),
                            status: {$nin: ['refused', 'validated']},
                        }, (e, reallocation) => {
                            if(e) return callback(e)
                            else if(reallocation) return callback(new Errors.ValidationError(context.tc('benefitInOngoingReallocationRequest')))
                            callback()
                        }
                    )
                })
        } else callback()
    },
    shouldNotIncludeToPayRequest: function(newObject, oldObject, context, callback) {
        if(context.action === 'forward' && newObject.ongoingPayment && parseFloat(newObject.ongoingPayment) !== 0) return callback(new Errors.ValidationError('ongoingPaymentShouldBeNull'))
        callback()
    },
    refundShouldBeSent: function(newObject, oldObject, context, callback) {
        const includesPaidRequest = newObject.requests.some(request => request.status === 'paid')
        if(includesPaidRequest && !newObject.refundSent && context.action === 'forward') callback(new Errors.ValidationError(context.tc('refundShouldBeSent', {amount: newObject.amountToRepay})))
        else callback()
    },
    shouldHaveFile: function(newObject, oldObject, context, callback) {
        if(newObject.refundSent && (!newObject.files || newObject.files.length === 0)) callback(new Errors.ValidationError(context.tc('proofMissing')))
        else callback()
    },
    refundShouldBeReceived: function(newObject, oldObject, context, callback) {
        const includesPaidRequest = newObject.requests.some(request => request.status === 'paid')
        if(includesPaidRequest && !newObject.refundReceived && context.action === 'validate') callback(new Errors.ValidationError(context.tc('refundShouldBeReceived', {amount: newObject.amountToRepay})))
        else callback()
    },
    validateSave: function(newObject, oldObject, context, callback) {
        async.series([
            callback => this.OnlyOneOngoingBenefitReallocation(newObject, oldObject, context, callback),
            callback => this.shouldHaveFile(newObject, oldObject, context, callback),
            callback => this.shouldNotIncludeToPayRequest(newObject, oldObject, context, callback),
            callback => this.refundShouldBeSent(newObject, oldObject, context, callback),
            callback => this.refundShouldBeReceived(newObject, oldObject, context, callback),
        ], callback)
    },
    beforeSave: async function(newObject, oldObject, context, callback) {
        const action  = context.restAction && context.restAction.crudType

        if(action === 'C') {
            newObject.beneficiary = await global.app.R.CUser.get({kpUser: new global.ObjectID(context.user.id)},
                {
                    fieldPath: ['fullName', 'registrationNumber'],
                    group: context.group
                })
            newObject.reference = `${newObject.beneficiary.registrationNumber}-${newObject.benefit.code}-${newObject.sequence}`
        }
        newObject.date = moment().format('YYYY-MM-DD HH-mm-ss')

        switch (context.action) {
            case 'save':
                newObject.status = 'waitingPayment'
                break
            case 'wait':
                newObject.status = 'waitingRefund'
                break
            case 'forward':
                newObject.status = 'ongoing'
                break
            case 'refuse':
                newObject.status = 'refused'
                break
            case 'rectify':
                newObject.status = 'rectification'
                break
            case 'validate':
                newObject.status = 'validated'
                break
        }

        const comment = newObject.comments.find(
            comment =>  !oldObject || !oldObject.comments.some(
                oldComment => comment.id === oldComment.id
            )
        )
        newObject.lastComment = comment && comment.text

        newObject.comments.push({
            user: _.pick(context.user, ['id', 'name']),
            text: `${context.tc(newObject.status)}`,
            date: moment().format("YYYY-MM-DD HH:mm")
        })

        if(newObject.status === 'validated') {
            const {limitByExercise, refundByExercise, restByBenefitByExercise} = await getCurrentRefund(newObject.beneficiary, context)

            async.parallel([
                callback => {
                    global.app.R.CUser.collection.update(
                        {_id: new global.ObjectID(newObject.beneficiary.id), declaration2: true},
                        {
                            $set: { declaration2: false },
                            $push: {
                                comments: {
                                    user: _.pick(context.user, ['id', 'name']),
                                    text: `Réallocation : La case « Acceptation du Plafond ${newObject.benefit.limit.maximum}€ pour Soutien scolaire / garde d’enfants»  a été réinitialisée`,
                                    date: moment().format("YYYY-MM-DD HH:mm")
                                }
                            }
                        },
                        callback
                    )
                },
                callback => {
                    async.series(
                        newObject.requests.map(
                            request => callback => {
                                global.app.R.Refund.collection.update(
                                    { _id: new global.ObjectID(request.id) },
                                    { $set: {status: 'reallocated', refund: '0.00', updated: moment().format("YYYY-MM-DD")} },
                                    err => {
                                        if(err) return callback(err)
                                        global.app.R.RequestGroup.collection.update(
                                            {_id: new global.ObjectID(request.requestGroup.id)},
                                            {$push: {
                                                    comments: {
                                                        text: `${request.reference}: ${context.tc('reallocated')}`,
                                                        user: _.pick(context.user, ['id', 'name']),
                                                        date: moment().format("YYYY-MM-DD HH:mm")
                                                    }
                                                }},
                                            callback
                                        )
                                    }
                                )
                            }),
                        callback
                    );
                },
                callback => {
                    const accStatements = []

                    const date = new Date()
                    const reallocationByExercise = {}
                    const reallocationByBenefit = {}

                    newObject.requests.filter(request => _.get(request, 'benefit.exercise.exerciseStatus.id') === 'ongoing').forEach(request => {
                        const exerciseId = _.get(request, 'benefit.exercise.id')
                        const benefitId = _.get(request, 'benefit.id')

                        if(!reallocationByExercise[exerciseId]) {
                            reallocationByExercise[exerciseId] = parseFloat(request.refund)
                            reallocationByBenefit[benefitId] = parseFloat(request.refund)

                        } else if(!reallocationByBenefit[benefitId]) {
                            reallocationByExercise[exerciseId] += parseFloat(request.refund)
                            reallocationByBenefit[benefitId] = parseFloat(request.refund)
                        }
                        else {
                            reallocationByExercise[exerciseId] += parseFloat(request.refund)
                            reallocationByBenefit[benefitId] += parseFloat(request.refund)
                        }

                        accStatements.push({
                            reference: request.reference,
                            exercise: new global.ObjectID(exerciseId),
                            benefit: new global.ObjectID(benefitId),
                            user: new global.ObjectID(newObject.beneficiary.id),
                            accountStatementReason: 'reallocated',
                            accountStatementOperation: 'C',
                            amount: parseFloat(request.amount).toFixed(2),
                            refund: parseFloat(request.refund).toFixed(2),
                            globalRest: _.round(limitByExercise[exerciseId] - refundByExercise[exerciseId] + reallocationByExercise[exerciseId], 2).toFixed(2),
                            restByBenefit: {
                                ...restByBenefitByExercise[exerciseId],
                                [request.benefit.id]: _.round(parseFloat(restByBenefitByExercise[exerciseId][benefitId]) + reallocationByBenefit[benefitId], 2).toFixed(2)
                            },
                            date,
                            group: new global.ObjectID(context.group.id)
                        })
                    })

                    global.app.R.AccountStatement.collection.insertMany(accStatements, callback)
                },
                callback => {
                    global.app.R.BankFlow.collection.updateMany(
                        {
                            reason: {
                                $in: newObject.requests
                                    .filter(request => ['validated', 'waiting', 'actualized'].includes(request.status))
                                    .map(request => request.reference)
                            }
                        },
                        { $set: {status: 'reallocated'} },
                        callback
                    )
                },
                callback => {
                    global.db.collection('r.bank').findOne({
                        active: true,
                        process: { $elemMatch: {$eq : 'refund'} }
                    }, (e, bank) => {
                        if(e) return callback(e)

                        async.series(
                            newObject.requests.filter(request => ['validated', 'waiting', 'actualized', 'paid'].includes(request.status)).map(
                                request => callback => {
                                    const accountEntries = getAccountEntries(request, bank, context)
                                    global.app.R.AccountingEntries.collection.insertMany(accountEntries, e => callback(e))
                                }),
                            callback
                        );
                    })
                },
                callback => {
                    const paidRequests =  newObject.requests.filter(request => request.status === 'paid')
                    if(paidRequests.length) {
                        const totalAmount = paidRequests.reduce((acc, request) => acc + parseFloat(request.refund), 0)
                        async.waterfall([
                            callback => {
                                global.app.R.BankFlow.collection.insertMany( paidRequests.map(request => ({
                                    bank: 'ASC',
                                    process: 'Salarié',
                                    processType: 'refund',
                                    recipient: request.user.firstname + ' ' + request.user.lastname ,
                                    reason: request.reference,
                                    IBAN: request.user.iban,
                                    BIC: request.user.bic,
                                    userId: request.user.id,
                                    accountNumber: request.user.accountNumber,
                                    amount: '-' + request.refund,
                                    generated: moment().format("YYYY-MM-DD"),
                                    status: request.status,
                                    reference: `Lot-D-${moment().format('YYYY-MM-DD-HH-mm-ss')}`,
                                    group: new global.ObjectID(context.group.id)
                                })), (e, result) => {
                                    if(e) return callback(e)
                                    callback(null, result.insertedIds)
                                })
                            },
                            (insertedIds, callback) => {
                                global.app.R.Batch.collection.insertOne( {
                                    reference: `Lot-D-${moment().format('YYYY-MM-DD-HH-mm-ss')}`,
                                    process: 'refund',
                                    orderLines: insertedIds,
                                    creationDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
                                    status: 'paid',
                                    orderLinesLength: insertedIds.length,
                                    amount: '-' + totalAmount.toFixed(2),
                                    group: new global.ObjectID(context.group.id)
                                }, e => callback(e))
                            }
                        ], callback)
                    } else callback(null)
                },
            ], function(err) {
                if(err) return callback(err)
                callback(null, newObject, oldObject)
            })
        } else {
            callback(null, newObject, oldObject)
        }
    },
    afterSave: async function(newObject, oldObject, context, callback) {
        workflowMailing(newObject, newObject.files, context).then(() => console.log('sending mails done'))
        getFNCSNotificationData(context.group.id).then(
            data => global.ioSocket.emit('afterSave', data)
        )
        callback()
    }
}
