const _ = require('lodash')
const moment = require('moment')
const Errors = require("../../utils/Errors").default
const { setFieldVisibility} = require("../../../apps/KpModule/actions")
const { generateFetchFieldListAction} = require("../../../apps/KpModule/actions/api")
const { generateConsumptionsReport } = require("./reportsUtils/consumptionsReport")
const { generateTaxReturnDocument } = require("./reportsUtils/taxReturnDocument")

export const entity = {
    name: 'Reports',
    facets: [{name: 'files', linkType: 'OTO'}],
    fields: [
        {path: 'date'},
        {path: 'title'},
        {path: 'report', type: 'ReportType'},
        {
            path: 'reportName',
            fieldPath: ['report.name'],
            f: function() {
                return this.report?.name
            }
        },
        {path: 'exercise', type: 'Exercise', sorters: ['byCode']},
        {path: 'comparisonExercise', type: 'Exercise', sorters: ['byCode'], nullable: true},
        {path: 'beneficiaries', type: 'CUser', link: 'MTM', nullable: true},
        {path: 'beneficiariesNumber', type: 'integer', nullable: true},
        {path: 'archive', type: 'boolean'},
        {path: 'emailing', type: 'boolean'},
        {
            path: "sequence", unique: true, ps: {
                object: [{
                    type: "nextSequence",
                    sequenceId: "r.reportSeq",
                    formatResult: result => `${result}`
                }]
            }
        }
    ],
    ps: {
        context: [{
            $$u: function (context, callback) {
                if (this.options.accessType === "S" && context.restAction && context.restAction.crudType === "C") {
                    const isTaxReturnDocument = _.get(context, 'clientObject.report.id') === 'taxReturnDocument'

                    if(isTaxReturnDocument) {
                        context.internalFieldPath = [
                            ...new Set([
                                ...context.internalFieldPath,
                                "sequence"
                            ])
                        ]
                    }
                }
                callback(null, context)
            }
        }]
    },
    validateSave: async (object, oldObject, context, callback) => {

        const exerciseId = _.get(object, 'exercise.id')
        const comparisonExerciseId = _.get(object, 'comparisonExercise.id')
        if(exerciseId === comparisonExerciseId) {
            callback(new Errors.ValidationError('cannotUseSameExerciseInComparison'))
        } else callback(null, object, oldObject)
    },
    beforeSave: async (object, oldObject, context, callback) => {

        object.date = moment().format("YYYY-MM-DD HH:mm:ss")
        if(object.report.id === 'consumptions') {
            await generateConsumptionsReport(object, oldObject, context, callback)
        } else if(object.report.id === 'taxReturnDocument') {
            await generateTaxReturnDocument(object, oldObject, context, callback)
        } else {
            callback(null, object, oldObject)
        }
    }
}

export const module_ = {
    object: 'Reports',
    tKey: 'mTitle_reports',
    defaultSortBy: 'date',
    objectIdentifier: 'reportName',
    defaultSortDirection: 'DESC',
    category: {
        path: 'reporting',
        icon: 'activity'
    },
    viewMap: {
        dt: [
            {path: 'report', width: 250},
            {path: 'title', width: 370},
            {path: 'exercise', width: 100, display: 'code'},
            {path: 'beneficiariesNumber', tKey: 'beneficiary_plural', width: 130},
            {path: 'emailing', width: 50, tKey: 'mail'},
            {path: 'archive', width: 70, tKey: 'archiving'},
            {path: 'date', width: 220, tKey: 'dateAndTime', required: true},
            {path: 'file', width: 60, noTimestamp: true, fieldPath: ['id', 'filename', 'password']}
        ],
        form: {
            fields: [
                {
                    path: 'report',
                    editable: false,
                    subscriptions: {
                        onChange: (newValue, oldValue, { store }) => {
                            if (newValue && newValue.id) {
                                const visible = newValue.id === 'taxReturnDocument'
                                const taxReturnDocumentFields = ['beneficiaries', 'archive', 'emailing']
                                taxReturnDocumentFields.forEach(field =>
                                    store.dispatch(setFieldVisibility(`e_${field}`, visible))
                                )
                                const comparisonExerciseVisible = newValue.id === 'consumptions'
                                store.dispatch(setFieldVisibility(`e_comparisonExercise`, comparisonExerciseVisible))
                            }
                        }
                    }
                },
                {path: 'title', placeholder: 'typeTitleIfNecessary', required: false, editable: false},
                {
                    path: 'exercise',
                    fieldPath: ['id', 'code', 'codeAndStatus', 'startDate', 'endDate', 'variableDot', 'fixedDot', 'exerciseStatus.id'],
                    display: 'codeAndStatus',
                    editable: false,
                    sortList: false,
                    subscriptions: {
                        onChange: (newValue, oldValue, { store }) => {

                            const data = {exercise: newValue}
                            store.dispatch(
                                generateFetchFieldListAction(
                                    "m-R-reports.Reports_beneficiaries",
                                    store.getState,
                                    'form',
                                    {data}
                                )
                            )
                        }
                    }
                },
                {
                    path: 'comparisonExercise',
                    fieldPath: ['id', 'code', 'codeAndStatus', 'startDate', 'endDate', 'variableDot', 'fixedDot', 'exerciseStatus.id'],
                    display: 'codeAndStatus',
                    editable: false,
                    sortList: false,
                    filters: ['closed']
                },
                {path: 'beneficiaries', tKey: 'beneficiary_plural', placeholder: 'selectOneOrManyBeneficiaries', display: 'fullName', filters: ['salariedAndRetired', 'inExercise'], editable: false},
                {path: 'archive', editable: false, default: true},
                {path: 'emailing', tKey: 'sendByEmail', editable: false, default: true},
                {path: 'reportName', hidden: true},
            ],
            onOpen: ({module, store}) => {
                const reportField = module.viewMap.form.fields.find(
                    field => field.path === 'report'
                )
                const report = reportField.getValue()
                const visible = report && report.id === 'taxReturnDocument'

                const taxReturnDocumentFields = ['beneficiaries', 'archive', 'emailing']
                taxReturnDocumentFields.forEach(field =>
                    store.dispatch(setFieldVisibility(`e_${field}`, visible))
                )
                const comparisonExerciseVisible = report && report.id === 'consumptions'
                store.dispatch(setFieldVisibility(`e_comparisonExercise`, comparisonExerciseVisible))
            }
        }
    }

}
