const moment = require('moment')
const _ = require('lodash')
const async = require('async')
const Errors = require("../../utils/Errors").default
const {invoiceRequestButtons, validationButtons, saveButton, returnButton} = require('./utils')
const {workflowMailing} = require('./mails/invoiceWorflowMails')

export const entities = [
    {
        name: 'InvoiceLine',
        fields: [
            'AccountsGroup',
            {path: 'amountInclTax', type: 'decimal'},
            'object'
        ]
    },
    {
        name: 'Invoice',
        facets: ['files', 'comments'],
        fields: [
            {path: 'invoiceNumber',unique: true, uniqueWith: 'supplier'},
            'Supplier',
            'invoiceDate',
            {path: 'deadline', type: 'date'},
            {path: 'wording', index: true},
            'journal',
            {path: 'amountInclTax', type: 'financial'},
            {path: 'invoiceLines', type: 'InvoiceLine', link: 'OTM'},
            {path: 'status', default: 'draft'},
            'userFullName',
            {path: 'user', type: 'CUser', nullable: true},
            {path: 'validator', type: 'CUser', nullable: true},
            'lastComment',
            {path: 'issued', type: 'date', nullable: true},
            {path: 'updated', type: 'date', nullable: true},
            {
                path: "buttons",
                $f: function (request, context, callback) {

                    if(context.clientContext.moduleId === 'm-R-invoiceKpModule') {
                        callback(null, [ saveButton, returnButton])
                    } else if(['draft', 'justification'].includes(request.status) && context.clientContext.moduleId === 'm-R-registration') {
                        callback(null, invoiceRequestButtons)
                    } else if(['controlled'].includes(request.status)  && context.clientContext.moduleId === 'm-R-validation') {
                        callback(null, validationButtons)
                    } else {
                        callback(null, [returnButton])
                    }
                }
            }
        ],
        filters: [
            {
                name: 'byExercise',
                object: 'Exercise',
                path: 'exercise',
                display: 'code',
                sortList: false,
                fieldPath: ['startDate', 'endDate'],
                clearable: false,
                client: true,
                isDefault: false,
                query: context => {
                    const startDate = _.get(context, 'data.exercise.startDate')
                    const endDate = _.get(context, 'data.exercise.endDate')

                    return {
                        $and: [
                            {'invoiceDate' : {$gte: startDate}},
                            {'invoiceDate' : {$lte: endDate}}
                        ]
                    }
                }
            }
        ],
        beforeSave: function (newObject, oldObject, context, callback) {
            const action  = context.restAction && context.restAction.crudType

            global.app.R.CUser.collection.findOne(
                {kpUser: new global.ObjectID(context.user.id)},
                (error, user) => {
                    if(error) callback(error)
                    if(!user) callback(new Errors.ValidationError('missingNecessaryPermissions'))
                    else {
                        const date = moment(newObject.invoiceDate).format('YYYY-MM-DD')
                        newObject.wording = `${newObject.supplier.code}-${newObject.invoiceNumber}-${date}`

                        if(action === 'C') {
                            newObject.status = 'draft'
                            newObject.user = new global.ObjectID(user._id)
                            newObject.journal = 'ACH'
                            newObject.userFullName = `${user.lastname} ${user.firstname}`
                        }

                        if(context.action && context.action !== 'save'){
                            switch (context.action) {
                                case 'validate':
                                    switch (newObject.status) {
                                        case 'draft':
                                            newObject.status = 'controlled'
                                            newObject.issued = moment().format("YYYY-MM-DD")
                                            break
                                        case 'justification':
                                            newObject.status = 'controlled'
                                            newObject.updated = moment().format("YYYY-MM-DD")
                                            break
                                        case 'controlled':
                                            newObject.status = 'validated'
                                            newObject.validatorFullName = `${user.lastname} ${user.firstname}`
                                            newObject.validator = new global.ObjectID(user._id)
                                            newObject.updated = moment().format("YYYY-MM-DD")
                                    }
                                    break
                                case 'justify':
                                    newObject.status = 'justification'
                                    newObject.validatorFullName = `${user.lastname} ${user.firstname}`
                                    newObject.validator = new global.ObjectID(user._id)
                                    newObject.updated = moment().format("YYYY-MM-DD")
                                    break
                                case 'refuse':
                                    newObject.status = 'refused'
                                    newObject.validatorFullName = `${user.lastname} ${user.firstname}`
                                    newObject.validator = new global.ObjectID(user._id)
                                    newObject.updated = moment().format("YYYY-MM-DD")
                                    break
                                default:
                                    console.log(`Action ${context.action} not allowed!!!`)
                            }

                            const oldComments = oldObject && oldObject.comments

                            const difference = _.differenceBy(newObject.comments, oldComments, comment => comment.date)

                            newObject.lastComment = _.last(difference) && _.last(difference).text

                            newObject.comments.push({
                                user: _.pick(context.user, ['id', 'name']),
                                text: `${context.tc(newObject.status)}`,
                                date: moment().format("YYYY-MM-DD HH:mm")
                            })
                        }
                        callback(null, newObject, oldObject)
                    }
                }
            )
        },
        afterSave: function (newObject, oldObject, context, callback) {
            if(context.action && context.action !== 'save') {
                workflowMailing(newObject, context)

                if(newObject.status === 'validated') {

                    const defaultObejct = {
                        date: newObject.invoiceDate,
                        generated: moment().format("YYYY-MM-DD"),
                        journal: 'ACH',
                        wording: newObject.wording,
                        recordType: 'invoice',
                        issued: newObject.issued,
                        status: newObject.status,
                        group: new global.ObjectID(context.group.id)
                    }

                    async.series(
                        [
                            ...newObject.invoiceLines.map(line => {
                                return function(callback) {
                                    async.waterfall([
                                        callback => global.app.R.AccountsTemplate.find({
                                            query: {
                                                accountsGroup: new global.ObjectID(line.accountsGroup.id)
                                            },
                                            group: new global.ObjectID(context.group.id)
                                        }, callback),

                                        (templates, callback) => {
                                            global.app.R.Account.find({
                                                query: {
                                                    accountsTemplate: new global.ObjectID(templates[0].id)
                                                },
                                                fieldPath: ['accountNumber', 'key', 'analyticalCode.code'],
                                                group: new global.ObjectID(context.group.id)
                                            }, callback)
                                        },
                                        (accounts, callback) => {
                                            const accountingEntries =_.flatten(accounts.map(account => {
                                                const accNumber = account.accountNumber
                                                const amount = (line.amountInclTax * account.key)/100
                                                const financialNumber = amount.toFixed(2)
                                                return [
                                                    {...defaultObejct, type: 'G', generalAccount: accNumber, direction: 'D', amount: financialNumber, analyticSection: account.analyticalCode.code},
                                                    {...defaultObejct, type: 'G', generalAccount: '401000000', auxiliaryAccount: newObject.supplier.accountNumber, direction: 'C', amount: financialNumber}
                                                ]
                                            }))

                                            global.app.R.AccountingEntries.collection.insertMany(accountingEntries, callback)


                                        }
                                    ], e => {
                                        callback(e)
                                    });
                                }

                            }),
                            function(callback) {
                                global.app.R.BankFlow.collection.insertOne({
                                    bank: 'Fonctionnement',
                                    process: 'Fournisseur',
                                    processType: 'invoice',
                                    recipient: newObject.supplier.corporateName,
                                    supplierId: newObject.supplier.id,
                                    reason: newObject.wording,
                                    IBAN: newObject.supplier.iban,
                                    BIC: newObject.supplier.bic,
                                    accountNumber: newObject.supplier.accountNumber,
                                    amount: newObject.amountInclTax,
                                    generated: moment().format("YYYY-MM-DD"),
                                    status: newObject.status,
                                    group: new global.ObjectID(context.group.id)
                                }, e => {
                                    console.warn(e);
                                    callback(e)
                                })
                            }
                        ],

                        function(err) {
                            callback(err);
                        });

                } else {
                    callback()
                }
            } else {
                callback()
            }
        }
    }
]

export const module_ = {
    object: 'Invoice',
    name: 'Registration',
    tKey: 'mTitle_registration',
    defaultSortBy: 'deadline',
    objectIdentifier: 'invoiceNumber',
    defaultSortDirection: 'ASC',
    category: {
        path: 'invoice',
        icon: 'fileText'
    },
    defaultFormButtons: invoiceRequestButtons,
    viewMap: {
        dt: [
            {path: 'wording', initiallyNotVisible: true},
            {path: 'supplier', tKey: 'corporateName', display: 'corporateName'},
            'invoiceNumber',
            {path: 'invoiceDate'},
            'deadline',
            {path: 'amountInclTax', tKey: 'amountInclTax_abbreviation'},
            {path: 'issued', initiallyNotVisible: true},
            {path: 'status', translate: true},
            'updated'
        ],
        form: [
            {path: 'wording', hidden: true},
            {path: 'supplier', tKey: 'corporateName', display: 'fullName', required: true},
            {path: 'invoiceNumber', required: true},
            {path: 'invoiceDate', type: 'datePicker', required: true, endDate: moment().format('YYYY-MM-DD')},
            {path: 'deadline', required: true, startDate: moment().format('YYYY-MM-DD')},
            {
                path: 'invoiceLines',
                defaultSortBy: 'accountsGroup',
                defaultSortDirection: 'ASC',
                removable: true,
                required: true,
                viewMap: {
                    dt: [
                        {path: 'accountsGroup', tKey: 'Gr. compte', display: 'fullName'},
                        {path: 'amountInclTax', tKey: 'amountInclTax_abbreviation'}
                    ],
                    form: [
                        {path: 'accountsGroup', tKey: 'Gr. compte', display: 'fullName', required: true, filters: ['hasTemplate']},
                        {path: 'amountInclTax', tKey: 'amountInclTax_abbreviation', positiveNumber: true, required: true},
                        {path: 'object', required: true}
                    ]
                },
                subscriptions: {
                    onChange: (newValue, oldValue, { formInitialize, module, store }) => {
                        if (!formInitialize && newValue && newValue.length > 0) {
                            const field = module.viewMap.form.fields.find(
                                field => field.path === 'amountInclTax'
                            )
                            const amount = newValue.reduce((acc, line) => acc + parseFloat(line.amountInclTax), 0)

                            field.setValue(amount)
                        }
                    }
                }
            },
            {path: 'amountInclTax', positiveNumber: true, disabled: true},
            {path: 'files', tKey: 'supportingDocuments', required: true},
            'comments',
            {path: 'status', hidden: true},
            {path: 'userFullName', hidden: true},
            {path: 'user', hidden: true},
            {
                path: "buttons",
                hidden: true
            }
        ]
    },
    filters: [
        {
            path: "registrationStep",
            match: function(invoice) {
                return ['draft', 'justification'].includes(invoice.status)
            }
        }
    ]
}
