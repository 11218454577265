
export const jobs = () => []
export const entities = () => []
export const modules = () =>[{
    tKey: 'Logs',
    object: 'SaveLog',
    category: {
        path: 'administrator',
        icon: 'clipboard'
    },
    newable: false,
    displayLog: false,
    manualFilters: true,
    protectedExport: true,
    removable: false,
    viewMap: {
        dt: ['date', 'module', {path: 'actionType'}, 'identifier', { path: 'user', initiallyNotVisible: true }],
        form: [
            {path: 'date', disabled: true},
            {path: 'module', disabled: true},
            {path: 'user', disabled: true},
            {path: 'actionType', disabled: true},
            {path: 'identifier', disabled: true},
            {
                path: 'changes',
                type: 'dtObjects',
                autoGrow: true,
                displayTooltip: true,
                fullWidth: true,
                required: true,
                fields: [
                    { path: 'field', tKey: 'element'},
                    { path: 'oldValue'},
                    { path: 'newValue'}
                ]
            },
        ]
    }, filters: ['byPeriod', 'byUsers', 'byModule', 'byUserAction']
}]
