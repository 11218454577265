export const jobs = () => []
export const entities = () =>[
    {
        name: "DocumentModelPlatform",
        facets: [{name: 'files'}],
        fields: [
            //{path: "code", unique: true},
            {path: "name", unique: true},
            {path: "lastUpdate", type: 'date', dateFormat: "YYYY-MM-DD HH:mm:ss", nullable: true},
            "Language",
            {type: "Profile", link: "MTM"},
            {path: "visible", type: "boolean"},
            {
                path: "file",
                $f: function (object, context, callback) {
                    callback(null, object.files[0])
                }
            },
        ],
        beforeSave: (object, oldObject, context, callback) => {
            object.lastUpdate = new Date()
            callback(null, object, oldObject)
        }
    },
]

export const modules = () =>[
    {
        tKey: "m-T-Documents",
        object: "DocumentModelPlatform",
        category: {
            path: 'communication',
            icon: 'messageCircle'
        },
        objectIdentifier: 'name',
        defaultSortBy: "lastUpdate",
        defaultSortDirection: "DESC",
        viewMap: {
            dt: [
                {path: "name", tKey: "documentName"},
                {path: "language", width: 100},
                {path: "profiles", width: 350},
                {path: "visible", width: 100},
                {path: "lastUpdate", tKey: 'dateAndTime', width: 250},
                {path: "file", width: 50, type: 'fileLink'},
            ],
            form: [
                {path: "name", tKey: "documentName", required: true},
                {path: "language", required: true},
                {path: "visible"},
                {path: "profiles", filters: ["thisModel"]},
                {path: "files", tKey: 'file', maxLength: 1, required: true}
            ]
        }
    }
]
