import _ from 'lodash'
import moment from 'moment'
import async from 'async'
const { setFormButtons } = require('../../../apps/KpModule/actions')
import { decrypt } from '../../utils/crypto'
import { sendMail } from './ticketsSendMail'
import { basicContext } from "../../utils/contextUtils"
import { getTicketNotificationData } from "../../utils/notificationData";
import { setFieldVisibility } from "../../../apps/KpModule/actions";

const sendButton = {
    type: 'save',
    tKey: 'send',
    bsStyle: 'success'
}

const closeButton = {
    type: 'action',
    action: 'close',
    tKey: 'close',
    bsStyle: 'primary'
}

const returnButton = {
    type: 'return',
    tKey: 'return',
    bsStyle: 'default'
}

const decryptUser = o => ({...o, mail: decrypt(o.mail)})

export async function generateAndSendMails(object, oldObject, context) {
    try {
        console.log('--> Sending ticket emails')

        const action  = context.restAction && context.restAction.crudType
        const groupModelName = (context.groupModel && context.groupModel.name) || 'Keenpoint'
        const userContext = _.get(context, 'user')
        const noReply = (context.group && context.group.noReply) || 'no-reply@keenpoint.com'
        const link = global.isProd
            ? `https://${context.host}/business/${context.groupModel.id}/communication/m-${context.model.id}-tickets`
            : `http://localhost:3000/business/${context.groupModel.id}/communication/m-${context.model.id}-tickets`

        const ticketSubject = await global.app[context.model.id].TicketSubject.find({
            ...basicContext(context),
            fieldPath: ['id', 'users.id', 'users.mail', 'users.name'],
            query: {
                _id: new global.ObjectID(_.get(object, 'ticketSubject.id'))
            }
        })

        const modelAdmins = ticketSubject.flatMap(o => o.users)

        const mailOptions = {
            groupModelName,
            numTicket: object.formattedNumTicket,
            theme: object.ticketSubjectName,
            sender: _.get(context, 'user'),
            link,
            noReply
        }
        if(action === 'C') {
            sendMail({...mailOptions, recipient: userContext, type: 'creation'}, (error, msg) => {console.log(`${msg} - to user`)})
            modelAdmins.forEach(modelAdmin => {
                sendMail({...mailOptions, recipient: decryptUser(modelAdmin), type: 'adminNotification'}, (error, msg) => {console.log(`${msg} - to admin`)})
            })
        } else {
            if(object.user.id === _.get(context, "user.id")) {
                sendMail({...mailOptions, recipient: userContext, type: 'update'}, (error, msg) => {console.log(`${msg} - to user`)})
                modelAdmins.forEach(modelAdmin => {
                    sendMail({...mailOptions, recipient: decryptUser(modelAdmin), type: 'adminNotification'}, (error, msg) => {console.log(`${msg} - to admin`)})
                })
            } else {
                sendMail({...mailOptions, recipient: decryptUser(object.user), type: 'response'}, (error, msg) => {console.log(`${msg} - to user`)})
            }
        }
        return 'OK'
    } catch (e) {
        throw(e)
    }
}

export const entities = () => [
    {
        name: 'TicketSubject',
        facets: [{name: 'translatedField', referenceField: 'wording'}],
        fields: [
            {path:'wording', notEmpty: true},
            {path: 'active', type: 'boolean', default: true},
            {path: 'profiles', type: 'Profile', link: 'MTM'},
            {path: 'users', type: 'User', link: 'MTM'}
        ],
        filters: [
            {
                name: 'active',
                isDefault: false,
                query: function(context) {
                    return {active: true}
                }
            },
            {
                name: 'profileMatchUserContext',
                isDefault: false,
                async: true,
                query: function(context, callback) {
                    const profiles = global.User.getUserProfilesFromGroupModel(context.user, context.groupModel)
                    if(profiles.length) {
                        callback(null, {
                            profiles: {$in: profiles.map(o => new global.ObjectID(o.id))}
                        })
                    } else {
                        callback(null, {id: null})
                    }
                }
            }
        ]
    },
    {
        name: 'TicketStatus',
        type: 'static',
        facets: ['translatedField'],
        fields: [
            {path: 'id', type: 'string'},
            {path: 'name', type: 'string'},
        ],
        objects: [
            {id: 'inProgress', name: 'inProgress'},
            {id: 'closed', name: 'closed'},
            {id: 'autoClosed', name: 'autoClosed'}
        ]
    },
    {
        name: 'Ticket',
        facets: ['files', 'comments'],
        fields: [
            {
                path: "numTicket", unique: true, ps: {
                    object: [{
                        type: "nextSequence",
                        sequenceId: "kp.tickets",
                        formatResult: result => `${result}`
                    }]
                }
            },
            'TicketSubject',
            {type:'User', nullable: true},
            {type:'TicketStatus', nullable: true},
            { path: 'creationDate', type: 'date', dateFormat: 'YYYY-MM-DD HH:mm', nullable: true},
            {
                path: 'formattedNumTicket',
                fieldPath: ['creationDate', 'numTicket'],
                f: function () {
                    return `${moment(this.creationDate).format('YYMMDD')}_${this.numTicket}`
                }
            },
            {
                path: 'ticketSubjectName',
                fieldPath: ['ticketSubject.tWording'],
                f: function () {
                    return _.get(this, 'ticketSubject.tWording')
                }
            },
            {
                path: 'lastContributor',
                f: function() {
                    if(this.comments.length) {
                        const comment = _.last(_.orderBy(this.comments, 'date'))
                        return _.get(comment, 'user.name')
                    }
                    return ''
                }
            },
            {
                path: 'lastUpdate',
                f: function() {
                    if(this.comments.length) {
                        const update = _.last(_.orderBy(this.comments, 'date')).date
                        return moment(update).format('YYYY-MM-DD HH:mm')
                    }
                    return ''
                }
            },
            {
                path: 'greenStyledRow',
                nullable: true,
                fieldPath: ['id', 'ticketStatus.id', 'comments.user.id', 'user.id'],
                $f: function (ticket, context, callback) {
                    const lastComment = _.last(_.orderBy(ticket.comments, 'date'))
                    const isOwner = _.get(ticket, 'user.id') === _.get(context, 'user.id')
                    callback(
                        null,
                        ticket.id && lastComment &&
                        ticket.ticketStatus && ticket.ticketStatus.id === 'inProgress' &&
                        (
                            isOwner && _.get(lastComment, 'user.id') !== _.get(context, 'user.id') ||
                            !isOwner && _.get(lastComment, 'user.id') === _.get(ticket, 'user.id')
                        )

                    )
                }
            },
            {
                path: 'buttons',
                fieldPath: ['id', 'ticketStatus.id', 'user.id'],
                $f: function (ticket, context, callback) {
                    const buttons = []
                    if(ticket.id) {
                        if(ticket.ticketStatus.id !== 'closed') {
                            const aditionalButtons = context.user.id === ticket.user.id
                                ? [sendButton, closeButton]
                                : [sendButton]
                            buttons.push(...aditionalButtons)
                        }
                    } else {
                        buttons.push(sendButton)
                    }
                    buttons.push(returnButton)
                    callback(null, buttons)
                }
            }
        ],
        beforeSave: function(object, oldObject, context, callback) {
            const action  = context.restAction && context.restAction.crudType
            // initialize status, user and creation date
            if(action === 'C') {
                object.ticketStatus =  {id: 'inProgress'}
                object.creationDate = new Date()
                object.user = context.user
            }

            if(context.action === 'close') {
                object.ticketStatus = {id: 'closed'}
            }

            callback(null, object, oldObject)
        },
        afterSave: function(object, oldObject, context, callback) {
            if(object.ticketStatus.id !== 'closed') {
                generateAndSendMails(object, oldObject, context)
                    .then(result => {
                        console.log(`Mails sent ${result}`)
                    })
                    .catch(err => {
                        console.error(err.message)
                    })
            }

            getTicketNotificationData(
                _.get(context, 'user.id'),
                _.get(context, 'group.id'),
                _.get(context, 'model.id'),
                _.get(context, 'groupModel.id')
            ).then( data => global.ioSocket.emit('afterSave', data) )

            callback(null, object)
        },
        filters: [
            {
                name: 'ownMessageOrGroupModelAdmin',
                isDefault: false,
                async: true,
                query: function(context, callback) {
                    global.app[context.model.id].TicketSubject.find({
                        ...basicContext(context),
                        fieldPath: ['id'],
                        query: {
                            users: new global.ObjectID(_.get(context, 'user.id'))
                        }
                    }, (e, ticketSubjects) => {
                        if(e) return callback(e)
                        const query = {
                            $or: [
                                { user: new global.ObjectID(context.user.id) },
                                { ticketSubject: {$in: ticketSubjects.map(o => new global.ObjectID(o.id))} },
                            ]
                        }
                        callback(e, query)
                    })

                }
            },
            {
                name: 'byStatusTicket',
                title: 'ticketStatus',
                path: 'ticketStatus',
                object: 'TicketStatus',
                display: 'name',
                client: true,
                translate: true,
                default: {id: 'inProgress'},
                query: function(context) {
                    const ticketStatusId = _.get(context.data, 'ticketStatus.id')
                    return ticketStatusId
                        ? {ticketStatus: ticketStatusId}
                        : {}
                }
            }
        ],
        ps: {
            context: [{
                $$u: function (context, callback) {
                    if (this.options.accessType === "S" && context.restAction && context.restAction.crudType === "C") {
                        context.internalFieldPath = [
                            ...new Set([
                                ...context.internalFieldPath,
                                "numTicket"
                            ])
                        ]
                    }
                    callback(null, context)
                }
            }]
        },
    }
]

export const modules = () => [
    {
        tKey: "m-T-TicketSubject",
        object: "TicketSubject",
        category: {
            path: 'communication',
            icon: 'messageCircle'
        },
        objectIdentifier: 'wording',
        defaultSortBy: 'wording',
        viewMap: {
            dt: [
                {
                    path: 'wording',
                    type: 'translatedText',
                    tKey: 'subject'
                },
                'profiles',
                {path: 'users', tKey: 'interlocutor(s)'},
                'active'
            ],
            form: [
                {
                path: 'wording',
                type: 'textarea',
                tKey: 'subject',
                placeholder: 'fr:: Sujet du message en français\nen:: Sujet du message en Anglais\nnl:: Sujet du message en Anglais'
                },
                {
                    path: 'profiles',
                    filters: ['thisModel']
                },
                {
                    path: 'users',
                    tKey: 'interlocutor(s)',
                    filters: ['isComAdmin']
                },
                'active'
            ]
        }
    },
    {
        tKey: "m-T-Ticket",
        name: "tickets",
        category: {
            path: 'communication',
            icon: 'messageCircle'
        },
        object: "Ticket",
        objectIdentifier: 'formattedNumTicket',
        removable: false,
        defaultSortBy: 'lastUpdate',
        defaultSortDirection: 'DESC',
        viewMap: {
            dt: [
                {path: 'ticketSubject', display: 'tWording', width: '400'},
                {path: 'formattedNumTicket', tKey: 'requestNum', width: '100'},
                {path: 'user', tKey: 'applicant', width: '100'},
                {path: 'creationDate', tKey: 'requestDate', width: '100'},
                {path:'lastUpdate', tKey: 'updatedOn', width: '100'},
                {path: 'lastContributor', width: '100'},
                {path: 'ticketStatus', width: '50'},
                {path: 'greenStyledRow', hidden: true}
            ],
            form: {
                fields: [
                    {path: 'formattedNumTicket', tKey: 'requestNum', type: 'readOnly'},
                    {path: 'ticketSubjectName', tKey: 'ticketSubject', type: 'readOnly'},
                    {path: 'ticketSubject', filters: ['profileMatchUserContext', 'active'], display: 'tWording', editable: false},
                    {path: 'comments', placeholder: 'writeAMessage', tKeyText: 'message', validationMessage: 'messageValidation'},
                    'files',

                    // Hidden fields
                    {path: 'ticketStatus', hidden: true},
                    {path: 'creationDate', hidden: true},
                    {path: 'user', display: 'fullName', hidden: true},
                    {path: 'buttons', hidden: true}
                ],
                onOpen: ({ store }) => {
                    const state = store.getState()
                    const objectMode = state.ui.objectMode
                    let visible = true
                    if(objectMode === 'newObject') {
                        visible = false
                        store.dispatch(
                            setFormButtons(
                                [sendButton, returnButton]
                            )
                        )
                    }
                    store.dispatch(setFieldVisibility(`e_ticketSubjectName`, visible))
                    store.dispatch(setFieldVisibility(`e_ticketSubject`, !visible))
                }
            }
        },
        filters: [
            'ownMessageOrGroupModelAdmin',
            'byStatusTicket'
        ]
    }
]

export const jobs = () => [{
    name: 'closeOldTickets',
    title: 'Close tickets older than 30 days',
    cron: '5 1 * * *',
    single: true,
    execute: function(context, callback) {
        const modelId = _.get(context, 'model.id')
        console.log(`Model ${modelId}: Closing tickets older than 30 days`)

        global.GroupModel.find({
            fieldPath: ["id", "group.id"],
            query: {model: modelId}
        }, (e, groupModels) => {
            async.each(
                groupModels,
                (groupModel, callback) => {
                    const groupId = _.get(groupModel, 'group.id')
                    const groupModelName = _.get(groupModel, 'name')
                    global.app[modelId].Ticket.find({
                        query: {ticketStatus: 'inProgress'},
                        fieldPath: ['id', 'name', 'user.id', 'comments.user.id', 'comments.date'],
                        group: new global.ObjectID(groupId)
                    }, (e, tickets) => {
                        if(e) return callback(e)

                        const thirtyDaysAgo = moment().subtract(1, 'days')
                        const oldTickets = tickets.filter(ticket => {
                            const lastComment = _.last(_.orderBy(ticket.comments, 'date'))
                            return ticket.user.id !== _.get(lastComment, 'user.id')
                                && moment(lastComment.date).isBefore(thirtyDaysAgo)
                        })

                        if(oldTickets.length) {
                            global.app[modelId].Ticket.collection.updateMany(
                                {_id: {$in: oldTickets.map(o => new global.ObjectID(o.id))}},
                                { $set: {'ticketStatus': 'autoClosed'} },
                                e => {
                                    if(e) return callback(e)
                                    console.log(`Group Model ${groupModelName}: ${oldTickets.length} tickets closed`)
                                    callback(null, `Group Model ${groupModelName}: ${oldTickets.length} tickets closed`)
                                }
                            )
                        } else {
                            console.log(`Group Model ${groupModelName}: No tickets closed`)
                            callback()
                        }
                    })
                },
                (e, results) => {
                    if(e) return callback(e)
                    callback(null, {message: `Model ${modelId}: Closing tickets DONE`})
                }
            )
        })
    }
}]

