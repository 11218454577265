import React from 'react'
import './StyledInput.css'
import ValidationUI from "../ValidationUI";

export class StyledInput extends React.Component {
    render() {
        const {id, path, type, value, placeholder, displayError = true, touched, error, onChange, t = x => x, required, disabled, readOnly, onKeyUp, timerIsOn, timeLeft, timer, style, getPreview} = this.props

        const disabledInputStyle = disabled
            ? {cursor: 'not-allowed', background: 'white', borderColor: 'hsl(0deg 0% 89%)'}
            : {}
        const inputStyle = {
            ...(style || {}),
            ...disabledInputStyle
        }
        const timerStyle = timerIsOn
            ? {
                marginTop: '2px',
                width: `${(timeLeft -1) * 100 / (timer)}%`,
                transition: 'width 1s linear',
                height: '2px',
                backgroundColor: 'rgb(255, 152, 0)'
            }
            : {
                marginTop: '2px',
                width: '100%',
                height: '2px'
            }

        return (
            <div className="StyledInput-group">
                <label className="StyledInput-label">
                    {t(path)}{required && "*"}
                </label>
                <div>
                    <input
                        id={id}
                        style={inputStyle}
                        placeholder={placeholder}
                        type={type}
                        name={path}
                        className="StyledInput-input"
                        value={value}
                        onChange={onChange}
                        readOnly={readOnly}
                        disabled={disabled}
                        onKeyUp={onKeyUp}
                    />
                </div>
                { displayError && touched && (error && <ValidationUI error={error}/>) }
                { timer && <div style={timerStyle}/> }
                { getPreview && <div style={{marginTop: "5px", marginLeft: "5px", fontStyle: "italic"}}>{getPreview(value, t)}</div> }
            </div>
        )
    }
}
