const async = require('async')
const _ = require('lodash')
const moment = require('moment')
const Errors = require("../../utils/Errors").default
const { basicContext } = require("../../utils/contextUtils");
const { setFieldVisibility, changeFieldDisabled, setFormButtons} = require("../../../apps/KpModule/actions")
const { validateAge, distributeButton, simulateButton, returnButton } = require('./utils')
const { calculateAge } = require('../../../utils/ageUtils')
const { getProratedValue } = require('./utils/refundRequestUtils')
const { generateExcel } = require('./utils/generateGiftsExcel')
const { shareFiles } = require('./utils/shareFiles')
const { decrypt } = require('../../utils/crypto')

export const entity = {
    name: 'Gifts',
    facets:[
        {name: 'files', linkType: 'OTO', path: 'simulation'},
        {name: 'files', linkType: 'OTO', path: 'distribution'},
        {name: 'files', linkType: 'OTM', path: 'files'},
        'comments'
    ],
    fields: [
        {path: 'title', unique: true},
        'CampaignType',
        {path: 'statuses', type: 'UserStatus', link: 'MTM'},
        'otherRecipients',
        {path: 'campaignDate', type: 'date', $default: () => moment().format('YYYY-MM-DD')},
        {path: 'beneficiaryAmount', type: 'decimal'},
        {path: 'childAmount', type: 'decimal', nullable: true},
        {path: 'maxChildAge', type: 'integer'},
        {path: 'minPerHousehold', type: 'decimal', nullable: true},
        {path: 'maxPerHousehold', type: 'decimal', nullable: true},
        {path: 'applyProrating', type: 'boolean'},
        {path: 'applySplit', type: 'boolean'},
        {path: 'useCampaignDate', type: 'boolean'},
        {path: 'share', type: 'DocumentVersion', nullable: true},
        {path: 'shareWith', type: 'CUser', link: 'MTM', nullable: true},
        {path: 'dateAndTime'},
        'status',
        {
            path: "buttons",
            $f: function (object, context, callback) {
                if(object.status === 'validated') callback(null, [distributeButton, returnButton])
                else callback(null, [distributeButton, simulateButton, returnButton])
            }
        },
    ],
    filters: [
        {
            name: 'byExercise',
            object: 'Exercise',
            path: 'exercise',
            display: 'code',
            sortList: false,
            fieldPath: ['startDate', 'endDate'],
            clearable: false,
            client: true,
            isDefault: false,
            query: context => {
                const startDate = _.get(context, 'data.exercise.startDate')
                const endDate = _.get(context, 'data.exercise.endDate')

                if(startDate && endDate) {
                    return {
                        $and: [
                            {'campaignDate' : {$gte: new Date(startDate)}},
                            {'campaignDate' : {$lte: new Date(endDate)}}
                        ]
                    }
                }

                return {}
            }
        }
    ],
    maxPerHouseholdControl: function(newObject, oldObject, context, callback) {
        const max = newObject.maxPerHousehold
        if(max && max < newObject.beneficiaryAmount )
            return callback(new Errors.ValidationError('maxPerHouseholdShouldNotExceedBeneficiaryAmt'))
        callback()
    },
    minPerHouseholdControl: function(newObject, oldObject, context, callback) {
        const min = newObject.minPerHousehold

        if(!min && newObject.campaignType.id === 'giftVoucher&CultureVoucher')
            return callback(new Errors.ValidationError('minPerHouseholdRequired'))
        if(min && min < newObject.beneficiaryAmount )
            return callback(new Errors.ValidationError('minPerHouseholdShouldNotExceedBeneficiaryAmt'))
        callback()
    },
    /*
    mustSelectUsersIfShareOptionIsSelected: function(newObject, oldObject, context, callback) {
        const shareWithField = newObject.shareWith && newObject.shareWith.length !== 0
        const isSimulation = context.action === 'simulate'
        const isDistribution = context.action === 'distribute'
        if(isSimulation && !shareWithField)
            return callback(new Errors.ValidationError('mustSelectUsersIfShareOptionIsSelected'))
        else if(isDistribution && !shareWithField && !newObject.otherRecipients)
            return callback(new Errors.ValidationError('mustSelectRecipientsIfShareOptionIsSelected'))
        callback()
    },
     */
    cannotShareSimulationWithOtherUsers: function(newObject, oldObject, context, callback) {
        if(context.action === 'simulate' && newObject.otherRecipients)
            return callback(new Errors.ValidationError('cannotShareSimulationWithOtherUsers'))
        callback()
    },
    validateSave: function(newObject, oldObject, context, callback) {
        async.series([
            callback => this.maxPerHouseholdControl(newObject, oldObject, context, callback),
            callback => this.minPerHouseholdControl(newObject, oldObject, context, callback),
            //callback => this.mustSelectUsersIfShareOptionIsSelected(newObject, oldObject, context, callback),
            callback => this.cannotShareSimulationWithOtherUsers(newObject, oldObject, context, callback),
        ], callback)
    },
    beforeSave: async function(newObject, oldObject, context, callback) {

        const campaignType = _.get(newObject, 'campaignType.id')

        if(newObject.status === 'validated') {

            const recipients = newObject.otherRecipients
                ? _.concat(newObject.shareWith, newObject.otherRecipients.split(';'))
                : newObject.shareWith

            if ( recipients && recipients.length ) {
                const file = newObject.distribution

                shareFiles(campaignType, recipients, file, 'distribution', context)
                    .then(() => console.log('sending emails done'))

                const defaultObject = {
                    user: _.pick(context.user, ['id', 'name']),
                    date: moment().format("YYYY-MM-DD HH:mm")
                }
                const sharedFilename = newObject.distribution.filename

                const otherRecipients = newObject.otherRecipients && newObject.otherRecipients.split(';')
                const usernames = newObject.shareWith.map(user => `${user.firstname} ${user.lastname}`)

                const recipientsNames = otherRecipients
                    ? _.concat(usernames, otherRecipients).join(', ')
                    : usernames

                const commentObject = {
                    text: `${sharedFilename} a été partagé avec ${recipientsNames}`,
                    ...defaultObject
                }

                newObject.comments.push(commentObject)
            }

            return callback(null, newObject, oldObject)
        }

        const { campaignDate, beneficiaryAmount, childAmount, maxChildAge, minPerHousehold, maxPerHousehold, statuses, useCampaignDate } = newObject
        const exercises = await global.app.R.Exercise.find({
            ...basicContext(context),
            query: {
                exerciseStatus: 'ongoing',
                startDate: {$lte: new Date(campaignDate)},
                endDate: {$gte: new Date(campaignDate)}
            }
        })

        const currentExercise = exercises[0]

        if(!currentExercise) return callback(new Errors.ValidationError('no current Exercise'))

        const users = await global.app.R.CUser.find({
            ...basicContext(context),
            fieldPath: ['civility.id', 'status.id', 'rightHolders.id', 'rightHolders.kinship.id'],
            query: {$or: [
                    {

                        entryDate: {$lt: useCampaignDate ? new Date(campaignDate) : currentExercise.endDate},
                        releaseDate: {$gt: useCampaignDate ? new Date(campaignDate): currentExercise.startDate},
                        status: {$in: statuses.map(status => status.id)}
                    },
                    {
                        entryDate: {$lt: useCampaignDate ? new Date(campaignDate) : currentExercise.endDate},
                        releaseDate: {$in: [null, '']},
                        status: {$in: statuses.map(status => status.id)}
                    }
                ]}
        })


        const objects = users.map(user => {
            const eligibleRightHolders = user.rightHolders.filter(rh => {
                return rh.kinship.id === 'child' && rh.dependent && validateAge(rh.birthDate, currentExercise.startDate, maxChildAge)
            })

            const nonEligibleRightHolders = user.rightHolders.filter(rh => {
                return rh.kinship.id === 'child' && (!rh.dependent || !validateAge(rh.birthDate, currentExercise.startDate, maxChildAge))
            })

            const applySplit = amount => {
                const userSplit = user['split'] || 0

                return ((amount * (100 - userSplit)) / 100)
            }

            const applyProratingAndSplit = amount => {
                const prorated = newObject.applyProrating
                    ? getProratedValue(currentExercise, user, amount, true, true)
                    : amount

                return  newObject.applySplit
                    ? applySplit(prorated)
                    : prorated
            }

            const min = minPerHousehold
            const max = maxPerHousehold
            const amount = beneficiaryAmount + (eligibleRightHolders.length * childAmount)

            const giftVoucher = ['giftVoucher', 'giftVoucher&CultureVoucher'].includes(campaignType)
                ? max && (amount > max)
                    ? max
                    : amount
                : 0

            const cultureVoucher = campaignType === 'cultureVoucher'
                ? max && (amount > max)
                    ? max
                    : amount
                : campaignType === 'giftVoucher&CultureVoucher' && min && (amount < min)
                    ? min - amount
                    : 0

            const total = giftVoucher + cultureVoucher

            return {
                civility: context.tc(user.civility.id),
                firstname: user.firstname,
                lastname: user.lastname,
                registrationNumber: user.registrationNumber,
                status: context.tc(user.status.id),
                entryDate: moment(user.entryDate).format('DD/MM/YYYY'),
                releaseDate: user.releaseDate && moment(user.releaseDate).format('DD/MM/YYYY'),
                childrenInCharge: eligibleRightHolders.map(child => `${decrypt(child.firstname)} ${decrypt(child.lastname)}`).join('; '),
                childrenInChargeBirthDates: eligibleRightHolders.map(child => moment(child.birthDate).format('DD/MM/YYYY')).join('; '),
                childrenInChargeAges: eligibleRightHolders.map(child => calculateAge(child.birthDate, moment(currentExercise.startDate).format('YYYY-MM-DD'))).join('; '),
                childrenInChargeNb: eligibleRightHolders.length,
                childrenNotInCharge: nonEligibleRightHolders.map(child => `${decrypt(child.firstname)} ${decrypt(child.lastname)}`).join('; '),
                childrenNotInChargeBirthDates: nonEligibleRightHolders.map(child => moment(child.birthDate).format('DD/MM/YYYY')).join('; '),
                childrenNotInChargeAges: nonEligibleRightHolders.map(child => calculateAge(child.birthDate, moment(currentExercise.startDate).format('YYYY-MM-DD'))).join('; '),
                childrenNotInChargeNb: nonEligibleRightHolders.length,
                giftVoucher: applyProratingAndSplit(giftVoucher),
                cultureVoucher: applyProratingAndSplit(cultureVoucher),
                total: applyProratingAndSplit(total),
            }
        })

        const simulationFilename = `${moment().format("YYYY-MM-DD-HH:mm:ss")}-Simulation-${newObject.title}`
        const distributionFilename = `${moment().format("YYYY-MM-DD-HH:mm:ss")}-Distribution-${newObject.title}`

        if(context.action === 'distribute') {
            async.parallel([
                    function(callback) {
                        generateExcel('simulation', simulationFilename, newObject, objects, context)
                            .then(file => callback(null, file))
                            .catch(err => callback(err))
                    },
                    function(callback) {
                        generateExcel('distribution', distributionFilename, newObject, objects, context)
                            .then(file => callback(null, file))
                            .catch(err => callback(err))
                    }
                ],
                function(err, results) {
                    if(err) callback(err)
                    else {
                        const defaultObject = {
                            user: _.pick(context.user, ['id', 'name']),
                            date: moment().format("YYYY-MM-DD HH:mm")
                        }
                        const dateAndTime = moment().format("YYYY-MM-DD HH:mm")
                        const simulationFileObject = {
                            ...results[0],
                            ...defaultObject
                        }

                        const distributionFileObject = {
                            ...results[1],
                            ...defaultObject
                        }

                        newObject.dateAndTime = dateAndTime
                        newObject.files.push(simulationFileObject)
                        newObject.simulation = simulationFileObject
                        newObject.distribution = distributionFileObject
                        newObject.files.push(distributionFileObject)
                        newObject.status = 'validated'

                        const otherRecipients = newObject.otherRecipients && newObject.otherRecipients.split(';')
                        const recipients = otherRecipients
                            ? _.concat(newObject.shareWith, otherRecipients)
                            : newObject.shareWith

                        if( recipients && recipients.length ) {
                            const file = newObject.distribution

                            const sharedFilename = results[1].filename

                            shareFiles(campaignType, recipients, file, 'distribution', context)

                            const usernames = newObject.shareWith.map(user => `${user.firstname} ${user.lastname}`)
                            const recipientsNames = otherRecipients
                                ? _.concat(usernames, otherRecipients).join(', ')
                                : usernames

                            const commentObject = {
                                text: `${sharedFilename} a été partagé avec ${recipientsNames}`,
                                ...defaultObject
                            }
                            newObject.comments.push(commentObject)
                        }
                        callback(null, newObject, oldObject)
                    }
                })
        } else {
            generateExcel('simulation', simulationFilename, newObject, objects, context)
                .then(file => {
                    const dateAndTime = moment().format("YYYY-MM-DD HH:mm")
                    const fileObject = {
                        ...file,
                        user: _.pick(context.user, ['id', 'name']),
                        date: dateAndTime
                    }
                    newObject.dateAndTime = dateAndTime
                    newObject.files.push(fileObject)
                    newObject.simulation = fileObject

                    if( newObject.shareWith && newObject.shareWith.length ) {

                        const file = newObject.simulation

                        shareFiles(campaignType, newObject.shareWith, file, 'simulation', context)
                        newObject.shareWith.forEach(
                            user => global.sms.sendMessage(
                                {
                                    user: {
                                        language: user.language,
                                        phone: decrypt(user.phone)
                                    },
                                    groupModel: (context.groupModel && context.groupModel.name) || "Keenpoint",
                                    context: {password: file.password, filename: file.filename},
                                    template: 'filePassword'
                                }
                            )
                        )

                        const usernames = newObject.shareWith.map(user => `${user.firstname} ${user.lastname}`).join(', ')
                        const commentObject = {
                            text: `${file.filename} a été partagé avec ${usernames}`,
                            user: _.pick(context.user, ['id', 'name']),
                            date: dateAndTime
                        }
                        newObject.comments.push(commentObject)
                    }

                    callback(null, newObject, oldObject)
                })
                .catch(err => callback(err))
        }
    }
}

export const module_ = {
    object: 'Gifts',
    tKey: 'mTitle_gifts',
    defaultSortBy: 'dateAndTime',
    objectIdentifier: 'title',
    defaultSortDirection: 'DESC',
    category: {
        path: 'administrator',
        icon: 'clipboard'
    },
    viewMap: {
        dt: [
            {path: 'title'},
            {path: 'campaignType', tKey: 'type', translate: true},
            {path: 'statuses', tKey: 'beneficiary_plural'},
            {path: 'campaignDate'},
            {path: 'dateAndTime'},
            {path: 'useCampaignDate'},
            {path: 'simulation', fieldPath: ['id', 'filename', 'password'], width: 100, noTimestamp: true},
            {path: 'distribution', width: 100, noTimestamp: true}
        ],
        form: {
            fields: [
                {path: 'title', required: true},
                {
                    path: 'campaignType',
                    tKey: 'type',
                    subscriptions: {
                        onChange: (newValue, oldValue, { module, store }) => {
                            if (newValue && newValue.id) {
                                const visible = newValue.id === 'giftVoucher&CultureVoucher'
                                store.dispatch(setFieldVisibility(`e_minPerHousehold`, visible))

                                const minAmtField = module.viewMap.form.fields.find(
                                    field => field.path === 'minPerHousehold'
                                )

                                if(!visible) minAmtField.setValue(null)
                            }
                        }

                    },
                    translate: true
                },
                {path: 'statuses', default: [{id: 'salaried', name: 'salaried'}, {id: 'retired', name: 'retired'}], tKey: 'beneficiary_plural', filters: ['notExternal']},
                {path: 'campaignDate', required: true},
                {
                    path: 'beneficiaryAmount',
                    default: 0,
                    required: true,
                    positiveNumber: true,
                    subscriptions: {
                        onChange: (newValue, oldValue, { module, store }) => {
                            const state = store.getState()
                            const objectMode = state.ui.objectMode
                            const campaignTypeField = module.viewMap.form.fields.find(
                                field => field.path === 'campaignType'
                            )

                            const campaignType = campaignTypeField.getValue()

                            if (objectMode === 'newObject' && newValue && campaignType && campaignType.id === 'giftVoucher&CultureVoucher') {
                                const minAmtField = module.viewMap.form.fields.find(
                                    field => field.path === 'minPerHousehold'
                                )
                                minAmtField.setValue(newValue)
                            }
                        }

                    }
                },
                {path: 'childAmount', positiveNumber: true, required: true, default: 0},
                {path: 'maxChildAge', wholePositiveNumber: true, default: 16, required: true},
                {path: 'minPerHousehold', positiveNumber: true, required: false},
                {path: 'maxPerHousehold', positiveNumber: true, required: false},
                {path: 'useCampaignDate', default: true},
                {path: 'applyProrating', default: true},
                {path: 'applySplit', default: true},
                {path: 'comments', textWidth: 950},
                {path: 'files', removable: false},
                {path: 'shareWith', tKey: 'cseRecipients', display: 'fullName'},
                {path: 'otherRecipients', type: 'creatableTags', placeholder: 'typeEmailAddressAndPressEnter'},
                {path: 'status', hidden: true},
                {path: 'simulation', hidden: true},
                {path: "buttons", hidden: true}
            ],
            onOpen: ({module, store}) => {
                const state = store.getState()
                const objectMode = state.ui.objectMode

                const statusField = module.viewMap.form.fields.find(
                    field => field.path === 'status'
                )
                const isValidated = statusField.getValue() === 'validated'

                if(objectMode === 'newObject') {
                    store.dispatch(setFormButtons([simulateButton, returnButton]))
                }
                const fields = ['e_campaignType', 'e_statuses', 'e_campaignDate', 'e_beneficiaryAmount', 'e_applyProrating', 'e_applySplit', 'e_childAmount', 'e_maxChildAge', 'e_minPerHousehold', 'e_maxPerHousehold', 'e_useCampaignDate']

                fields.forEach(fieldId => store.dispatch(changeFieldDisabled(fieldId, isValidated)))
            }
        }
    },
    filters: ['byExercise']
}
