import {GET_OBJECT_SUCCESS} from "../../../apps/KpModule/actions/api";
import {changeFieldProperty} from "../../../apps/KpModule/actions";

const _ = require('lodash')
const moment = require('moment')
const crypto = require('crypto')
const async = require('async')
const Errors = require("../../utils/Errors").default
const {fieldPathJoinGetter} = require('../../utils/kp3Utils')
const { sendMail } = require('./mails/userInitialization')
const { sendReleaseMail } = require('./mails/userRelease')
const { basicContext } = require('../../utils/contextUtils')
const { decrypt } = require('../../utils/crypto')
const { validateAge } = require('./utils')
const { setFieldStartDate } = require('../../../apps/KpModule/actions')
const { calculateExerciseEndowment } = require('./utils/refundRequestUtils')

export const entities = [
    {
        name: 'RightHolder',
        fields: [
            {path: 'lastname', encrypted: true},
            {path: 'firstname', encrypted: true},
            {path: 'birthDate'},
            'Kinship',
            {path: 'dependent', type: 'boolean'},
            {
                path: 'fullName',
                encrypted: true,
                fieldPath: ['firstname', 'lastname'],
                f: function() {
                    return ['firstname', 'lastname']
                        .map(p => _.get(this, p))
                        .join(' ')
                }
            }
        ],
        filters: [
            {
                name: "byUser",
                isDefault: false,
                async: true,
                query: (context, callback) => {
                    global.app.R.CUser.find(
                        {
                            query: { kpUser: new global.ObjectID(context.user.id) },
                            fieldPath: ['id'],
                            group: new global.ObjectID(context.group.id)
                        },
                        (e, cUsers) => {
                            if(e) callback(e)
                            const userId = cUsers[0] && cUsers[0].id

                            callback(
                                null,
                                userId
                                    ? {cUsers: new global.ObjectID(userId)}
                                    : {_id: false}
                            )
                        }
                    )
                }
            },
            {
                name: "eligible",
                isDefault: false,
                match: (rightHolder, context) => {
                    if(rightHolder.kinship === 'spouse') return true
                    const ageLimit = context.data && context.data.ageLimit
                    const exerciseStartDate = context.data && context.data.exerciseStartDate
                    if(ageLimit) {
                        if(exerciseStartDate) {
                            const isAgeValidate = validateAge(rightHolder.birthDate, exerciseStartDate, ageLimit)
                            return rightHolder.dependent && isAgeValidate
                        }
                        return false
                    }
                    return rightHolder.dependent
                }
            },
        ]
    },
    {
        name: 'CUser',
        facets: ['comments', 'files'],
        fields: [
            'Civility',
            {path: 'status', type: 'UserStatus'},
            'firstname','lastname',
            {path: 'phone', encrypted: true},
            'kpUser',
            {path: 'quotient'},
            {path: 'split', type: 'integer'},
            {path: 'registrationNumber', unique: true},
            {path: 'accountNumber', unique: true},
            {
                path: "sequence", unique: true, ps: {
                    object: [{
                        type: "nextSequence",
                        sequenceId: "r.userSeq",
                        formatResult: result => `${result}`
                    }]
                }
            },
            {path: 'address', encrypted: true},
            'rib',
            {path: 'bank', encrypted: true},
            {path: 'accountOwner', encrypted: true},
            {path: 'iban', encrypted: true},
            {path: 'bic', encrypted: true},
            {path: 'active', type: 'boolean'},
            {path: 'mail', unique: true, encrypted: true},
            {path: 'birthDate', type: 'date', nullable: true},
            {path: 'entryDate', type: 'date'},
            {path: 'releaseDate', type: 'date', nullable: true},
            {type: 'RightHolder', link: 'OTM'},
            'Language',
            {path: 'declaration1', type: 'boolean'},
            {path: 'declaration2', type: 'boolean'},
            {path: 'authorizations', type: 'Profile', link: 'MTM'},
            {
                path: 'rightHoldersNumber',
                fieldPath: ['rightHolders.id'],
                f: function () {
                    return this.rightHolders ? this.rightHolders.length : 0
                }
            },
            fieldPathJoinGetter({
                path: "fullName",
                fieldPath: ["firstname", "lastname"],
                joinString: " "
            }),
            {
                path: 'fullNameWithRegistrationNumber',
                fieldPath: ['firstname', 'lastname', 'registrationNumber'],
                f: function () {
                    return `${this.firstname} ${this.lastname} (${this.registrationNumber})`
                }
            },
            {
                path: 'maximumAmount',
                $f: async (user, context, callback) => {
                    try {
                        const ongoingExercise = await global.app.R.Exercise.get(
                            {exerciseStatus: 'ongoing'},
                            {
                                ...basicContext(context),
                                fieldPath: ['id']
                            }
                        )

                        if(!ongoingExercise) return callback(null, 'undefined')

                        const benefit = await global.app.R.Benefit.get(
                            {
                                exercise: global.ObjectID(ongoingExercise.id),
                                childrenPresenceCondition: true
                            },
                            {
                                ...basicContext(context),
                                fieldPath: ['limit.maximum']
                            }
                        )

                        if(!benefit) return callback(null, 'undefined')

                        callback(null, benefit.limit.maximum)

                    } catch (e) {
                        console.log(e)
                        callback(null, 'undefined')
                    }
                }
            },
            {
                path: 'remainingAmount',
                lazy: true,
                fieldPath: ['rightHolders.id', 'rightHolders.birthDate', 'rightHolders.dependent', 'rightHolders.kinship.id', 'status.id'],
                $f: (user, context, callback) => {
                    if(user.status.id === 'external') return callback(null, 0)
                    global.app.R.Exercise.find(
                        {
                            ...basicContext(context),
                            query:  { exerciseStatus: 'ongoing' }
                        },
                        (error, exercises) => {
                            if(error) return callback(error)
                            if(exercises.length) {
                                const exercise = exercises[0]

                                async.waterfall([
                                    async.constant(exercise),
                                    (exercise, callback) => {
                                        if(exercise) {
                                            global.app.R.Benefit.find({
                                                ...basicContext(context),
                                                query: {exercise: new global.ObjectID(exercise.id)}
                                            },(error, ongoingExerciseBenefits) => {
                                                if(error) callback(error)
                                                callback(null, exercise, ongoingExerciseBenefits)
                                            })
                                        }
                                    },
                                    (exercise, ongoingExerciseBenefits, callback) => {
                                        if(ongoingExerciseBenefits.length) {
                                            global.app.R.Refund.find({
                                                ...basicContext(context),
                                                fieldPath: ['refund'],
                                                query: {
                                                    user: new global.ObjectID(user.id),
                                                    benefit: {$in: ongoingExerciseBenefits.map(o => new global.ObjectID(o.id))},
                                                    status: { $nin: ['draft', 'rectification', 'refused', 'reallocated'] }

                                                }
                                            },(error, requests) => {
                                                if(error) callback(error)
                                                const sum = requests.reduce((acc, request) => acc + parseFloat(request.refund), 0)
                                                callback(null, exercise, sum)
                                            })
                                        } else {
                                            callback(null, exercise, 0)
                                        }

                                    },
                                    (exercise, ongoingExerciseRefund, callback) => {
                                        const exerciseEndowment = calculateExerciseEndowment(exercise, user)

                                        callback(null, _.round(exerciseEndowment - ongoingExerciseRefund, 2))
                                    }
                                ], callback)
                            } else {
                                console.warn('No Ongoing exercise');
                                return callback(null, 0)
                            }
                        }
                    )
                }
            }
        ],
        filters: [
            {
                name: "byUserForMyAdvantagesLinks",
                isDefault: true,
                query: function(context) {
                    const userId = _.get(context, 'data.userOnMyAdvantagesLinks')
                    return userId
                        ? {kpUser: new global.ObjectID(userId)}
                        : {}
                }
            },
            {
                name: "byUser",
                isDefault: false,
                query: function(context) {
                    const user = context.user
                    if(user) return {kpUser: new global.ObjectID(user.id)}
                }
            },
            {
                name: "hasDeclaration2",
                isDefault: false,
                query: () => ({declaration2: true})
            },
            {
                name: 'salariedAndRetired',
                isDefault: false,
                query: function(context) {
                    const user = context.user
                    if(user) return {status: {$in: ['retired', 'salaried']}}
                }
            },
            {
                name: 'inExercise',
                isDefault: false,
                query: function(context) {
                    const exercise = _.get(context, 'data.exercise')
                    if(exercise) return {$or: [
                            {
                                entryDate: {$in: [null, '']},
                                releaseDate: {$in: [null, '']}
                            },
                            {
                                entryDate: {$lt: new Date(exercise.endDate)},
                                releaseDate: {$in: [null, '']}
                            },
                            {
                                entryDate: {$in: [null, '']},
                                releaseDate: {$gt: new Date(exercise.startDate)}
                            },
                            {
                                entryDate: {$lt:new Date(exercise.endDate)},
                                releaseDate: {$gt: new Date(exercise.startDate)}
                            }
                        ]}
                }

            }
        ],
        ps: {
            context: [{
                $$u: function (context, callback) {
                    if (this.options.accessType === "S" && context.restAction && context.restAction.crudType === "U") {
                        context.internalFieldPath = [
                            ...new Set([
                                ...context.internalFieldPath,
                                "comments"
                            ])
                        ]
                    }
                    if (this.options.accessType === "S" && context.restAction && context.restAction.crudType === "C") {
                        context.internalFieldPath = [
                            ...new Set([
                                ...context.internalFieldPath,
                                "sequence"
                            ])
                        ]
                    }
                    if (this.options.accessType === "L") {
                        context.fieldPath = [
                            ...new Set([
                                ...context.internalFieldPath,
                                "declaration1",
                                "declaration2",
                            ])
                        ]
                    }
                    callback(null, context)
                }
            }]
        },
        birthDateRequired: function (newObject, context, callback) {
            const missingDate = newObject.rightHolders.some(rightHolder =>{
                return rightHolder.kinship.id === 'child' && !rightHolder.birthDate
            })
            if(missingDate) callback(new Errors.ValidationError('missingChildBirthDate'))
            else callback()
        },
        uniqueSpouse: function (newObject, context, callback) {
            let spouses = 0
            newObject.rightHolders.forEach(rightHolder => {
                if(rightHolder.kinship.id === 'spouse') spouses++
            })

            if(spouses > 1) callback(new Errors.ValidationError('uniqueSpouse'))
            else callback()
        },
        retiredUserReleaseDate: function (newObject, context, callback) {
            const module = context.module && context.module.name
            if( module === 'Users' && newObject.status.id === 'retired' && !newObject.releaseDate) {
                callback(new Errors.ValidationError('specifyRetiredUserReleaseDate'))
            } else callback()
        },
        splitValue: function (newObject, context, callback) {
            const split = newObject['split']
            if( split && split > 100) {
                callback(new Errors.ValidationError('splitShouldNotExceed100'))
            } else callback()
        },
        cannotDeleteRightHolder: function(newObject, oldObject, context, callback) {
            if(oldObject) {
                const deletedRightHolders = oldObject.rightHolders.filter( rightHolder => {
                    return  !newObject.rightHolders.some(rh => rh.id === rightHolder.id)
                })
                global.app.R.Refund.find({
                    ...basicContext(context),
                    fieldPath: ['beneficiaries'],
                    query: {
                        beneficiaries: { $elemMatch: {$in : deletedRightHolders.map(rh => global.ObjectID(rh.id)) } }
                    }
                }, (e, requests) => {
                    if(requests && requests.length !== 0) {
                        callback(new Errors.ValidationError('cannotDeleteBeneficiary'))
                    }
                    else callback()
                })
            } else {
                callback()
            }
        },
        cannotDeleteGeneratedFiles: function(newObject, oldObject, context, callback) {
            if(oldObject) {
                const deletedFiles = oldObject.files.filter( oldFile =>
                    !newObject.files.some(newFile => newFile.id.toString() === oldFile.id.toString())
                    && oldFile.filename.split('_')[3] === 'Attestation'
                    && oldFile.filename.split('_')[4] === 'de'
                    && oldFile.filename.split('_')[5] === 'remboursement.pdf'
                )
                if(deletedFiles && deletedFiles.length !== 0) {
                    callback(new Errors.ValidationError('cannotDeleteGeneratedFile'))
                } else callback()
            } else callback()
        },
        validateSave: function (newObject, oldObject, context, callback) {
            async.series([
                callback => this.birthDateRequired(newObject, context, callback),
                callback => this.uniqueSpouse(newObject, context, callback),
                callback => this.retiredUserReleaseDate(newObject, context, callback),
                callback => this.splitValue(newObject, context, callback),
                callback => this.cannotDeleteRightHolder(newObject, oldObject, context, callback),
                callback => this.cannotDeleteGeneratedFiles(newObject, oldObject, context, callback),
            ], callback);
        },
        beforeSave: function (newObject, oldObject, context, callback) {

            const action  = context.restAction && context.restAction.crudType
            const module = context.module && context.module.name

            const profiles = newObject.authorizations
                ? newObject.authorizations.map(auth => new global.ObjectID(auth.id))
                : []

            const currentGroup = new global.ObjectID(context.group.id)
            const currentGroupModel = new global.ObjectID(context.groupModel.id)

            const groups = [
                {
                    group: currentGroup,
                    super_: false,
                    groupModels: [
                        {
                            groupModel: currentGroupModel,
                            super_: false,
                            profiles
                        }
                    ]
                }
            ]

            if(action === 'C') {
                crypto.randomBytes(20, (err, buf) => {
                    if(err) callback(err)

                    const token = buf.toString('hex')

                    const query = {mail: newObject.mail}
                    const userObject = {
                        ..._.pick(newObject, ['civility', 'firstname', 'lastname', 'mail', 'phone', 'active']),
                        language: _.get(newObject, 'language.id'),
                        resetPasswordToken: token,
                        resetPasswordExpires: Date.now() + 86400000,
                        groups
                    }

                    global.User.collection.findOneAndUpdate(query, {$set: userObject}, {upsert: true}, (e, result) => {
                        if(e) return callback(e)
                        const {lastErrorObject, value} = result

                        const id = lastErrorObject.upserted ? lastErrorObject.upserted : value._id
                        newObject.kpUser =  new global.ObjectID(id)
                        newObject.accountNumber = `${411000000 + parseInt(newObject.sequence)}`

                        const isExternal = newObject.status.id === 'external'
                        sendMail(token, userObject, isExternal, context, (error, msg) => {console.log(msg)})

                        callback(null, newObject, oldObject)

                    })
                })
            }else {
                let changes
                if(module === 'Information') {
                    changes = newObject.rightHolders.reduce((acc, newRh) => {
                        const oldRh = oldObject.rightHolders.find(rh => newRh.id === rh.id)
                        const isChanged = oldRh &&  ['lastname', 'firstname', 'birthDate', 'dependent'].some(key => newRh[key] !== oldRh[key])
                        if(isChanged) {
                            const oldValue = `${decrypt(oldRh.lastname)} ${decrypt(oldRh.firstname)}, né(e) le ${moment(oldRh.birthDate).format('DD/MM/YYYY')}, ${context.tc(oldRh.dependent ? 'à charge' : 'non à charge')}`
                            const newValue = `${decrypt(newRh.lastname)} ${decrypt(newRh.firstname)}, né(e) le ${moment(newRh.birthDate).format('DD/MM/YYYY')}, ${context.tc(newRh.dependent ? 'à charge' : 'non à charge')}`
                            return [
                                ...acc,
                                {
                                    user: _.pick(context.user, ['id', 'name']),
                                    text: `${context.tc('changeOfField',
                                        {
                                            field: context.tc('rightHolders'),
                                            oldValue,
                                            newValue,
                                        }
                                    )}`,
                                    date: moment().format("YYYY-MM-DD HH:mm")
                                }
                            ]
                        } else return acc
                    }, [])
                }

                let profilesChanged = false

                if( module === 'Users') {
                    changes = ['status', 'entryDate', 'releaseDate', 'active', 'split'].reduce(
                        (acc, element) => {
                            let oldValue, newValue
                            switch (element) {
                                case 'status':
                                    oldValue = context.tl(oldObject[element].id, 'fr')
                                    newValue = context.tl(newObject[element].id, 'fr')
                                    break
                                case 'entryDate':
                                case 'releaseDate':
                                    oldValue = oldObject[element] ? moment(oldObject[element]).format('DD/MM/YYYY') : 'non renseignée'
                                    newValue = newObject[element] ? moment(newObject[element]).format('DD/MM/YYYY') : 'non renseignée'
                                    break
                                case 'active':
                                    oldValue = oldObject[element] ? 'oui' : 'non'
                                    newValue = newObject[element] ? 'oui' : 'non'
                                    break
                                default:
                                    oldValue = oldObject[element]
                                    newValue = newObject[element]

                            }
                            if(oldValue !== newValue) {
                                return [...acc, {
                                    user: _.pick(context.user, ['id', 'name']),
                                    text: `${context.tc('changeOfField',
                                        {
                                            field: context.tl(element, 'fr'),
                                            oldValue,
                                            newValue,
                                        }
                                    )}`,
                                    date: moment().format("YYYY-MM-DD HH:mm")
                                }]
                            } else
                                return acc
                        }, [])

                    const difference = newObject.authorizations.some(auth => !oldObject.authorizations.find(oldAuth => oldAuth.id === auth.id))
                    const difference2 = oldObject.authorizations.some(auth => !newObject.authorizations.find(newAuth => newAuth.id === auth.id))

                    profilesChanged = difference || difference2
                }
                newObject.comments = [...newObject.comments, ...changes]

                const profilesQuery = profilesChanged
                    ? {'groups.$[elem1].groupModels.$[elem2].profiles': profiles}
                    : {}

                const filterProfilesQuery = profilesChanged
                    ? { arrayFilters: [ { "elem1.group": currentGroup } , { "elem2.groupModel": currentGroupModel }]}
                    : {}

                const userObject = {
                    ..._.pick(newObject, ['civility', 'firstname', 'lastname', 'mail', 'phone', 'active']),
                    language: _.get(newObject, 'language.id'),
                    ...profilesQuery
                }

                if(newObject.mail.toUpperCase() !== oldObject.mail.toUpperCase()) {
                    crypto.randomBytes(20, (err, buf) => {
                        if(err) callback(err)
                        const token = buf.toString('hex')

                        userObject.resetPasswordToken = token
                        userObject.resetPasswordExpires = Date.now() + 86400000

                        global.User.collection.updateOne(
                            { _id: new global.ObjectID(oldObject.kpUser) },
                            { $set: userObject },
                            filterProfilesQuery,
                            e => {
                                if(e) return callback(e)
                                const isExternal = newObject.status.id === 'external'
                                sendMail(token, userObject, isExternal, context, (error, msg) => {console.log(msg)})
                                callback(null, newObject, oldObject)
                            }
                        )
                    })
                } else {
                    global.User.collection.updateOne(
                        {_id: new global.ObjectID(oldObject.kpUser)},
                        { $set: userObject },
                        filterProfilesQuery,
                        e => {
                            if(e) return callback(e)
                            callback(null, newObject, oldObject)
                        }
                    )
                }
            }
        },
        afterSave: function (newObject, oldObject, context, callback) {
            const action  = context.restAction && context.restAction.crudType
            const bankInfoUpdated = oldObject && ['bank', 'accountOwner', 'iban', 'bic'].some(path => newObject[path] !== oldObject[path])

            async.parallel([
                    function(callback) {
                        if(action === 'U' && bankInfoUpdated) {
                            const newComment = {
                                user: _.pick(context.user, ['id', 'name']),
                                text: `${context.tc('actualized')}`,
                                date: moment().format("YYYY-MM-DD HH:mm")
                            }
                            global.app.R.Refund.collection.updateMany(
                                {user: global.ObjectID(newObject.id), status: {$in: ['waiting', 'validated', 'actualized']}},
                                {$set: { status: 'actualized'}, $push: {comments: newComment}},
                                e => {
                                    if(e) callback(e)
                                    else {
                                        global.app.R.BankFlow.collection.updateMany(
                                            {userId: newObject.id, status: {$in: ['waiting', 'validated', 'actualized']} },
                                            {$set: {
                                                    status: 'actualized',
                                                    IBAN: newObject.iban,
                                                    BIC: newObject.bic,
                                                    accountNumber: newObject.accountNumber
                                                }},
                                            e => callback(e)
                                        )
                                    }
                                }
                            )
                        } else {
                            callback(null)
                        }
                    },
                    function(callback) {
                        if(action === 'U') {
                            const isExternal = newObject.status.id === 'external'
                            const statusUpdated = newObject.releaseDate && oldObject.status && newObject.status && (oldObject.status.id !== newObject.status.id)
                            const entryDateUpdated = oldObject.entryDate && newObject.entryDate &&  (oldObject.entryDate.toDateString() !== newObject.entryDate.toDateString())
                            const releaseDateUpdated = newObject.releaseDate &&  (!oldObject.releaseDate || oldObject.releaseDate.toDateString() !== newObject.releaseDate.toDateString())

                            if(!isExternal && ((statusUpdated && newObject.releaseDate) || releaseDateUpdated)) {
                                global.app.R.Exercise.collection.findOne({ exerciseStatus: 'ongoing' }, (e, exercise) => {
                                    if(e) callback(e)
                                    else if(!exercise) callback(new Errors.ValidationError('noOngoingExercise'))
                                    else if(newObject.releaseDate < exercise.startDate) return callback()
                                    else {
                                        newObject.releaseDate.setDate( newObject.releaseDate.getDate() - 1)
                                        const date = `31/12/${newObject.releaseDate.getFullYear()}`
                                        sendReleaseMail(newObject, date, context, e => callback(e))
                                    }
                                })
                            } else if(entryDateUpdated && !releaseDateUpdated) {

                                crypto.randomBytes(20, (err, buf) => {
                                    if(err) callback(err)
                                    const token = buf.toString('hex')

                                    global.User.collection.updateOne(
                                        {_id: new global.ObjectID(newObject.kpUser)},
                                        {
                                            $set: {
                                                resetPasswordToken: token,
                                                resetPasswordExpires: Date.now() + 86400000
                                            }
                                        },
                                        (e) => {
                                            if(e) return callback(e)
                                            sendMail(token, newObject, isExternal, context, (error, msg) => {console.log(msg)})
                                            return callback()
                                        })

                                })
                            } else callback()
                        } else callback()
                    }
                ],
                function(err) {
                    callback(err)
                });
        }
    }
]

export const module_ = {
    name: 'Users',
    object: 'CUser',
    tKey: 'mTitle_user',
    objectIdentifier: 'mail',
    defaultSortBy: 'lastname',
    defaultSortDirection: 'ASC',
    protectedExport: true,
    removable: false,
    category: {
        path: 'administrator',
        icon: 'clipboard'
    },
    viewMap: {
        dt: [
            {path: 'civility', initiallyNotVisible: true},
            'lastname',
            'firstname',
            {path: 'birthDate', initiallyNotVisible: true},
            'registrationNumber',
            'mail',
            {path: 'phone', initiallyNotVisible: true},
            {path: 'status', initiallyNotVisible: true},
            //{path: 'quotient', initiallyNotVisible: true},
            {path: 'split', initiallyNotVisible: true},
            {path: 'accountNumber', initiallyNotVisible: true},
            {path: 'entryDate', initiallyNotVisible: true},
            {path: 'releaseDate', initiallyNotVisible: true},
            {path: 'authorizations', tKey: 'profiles', initiallyNotVisible: true, filters: ["thisModel"]},
            {path: 'language', tKey: 'correspondenceLanguage', initiallyNotVisible: true},
            {path: 'active', tKey: 'siteAccess', width: 100}
            //'rightHoldersNumber'
        ],
        form: [
            {path: 'civility', required: true},
            {path: 'lastname', required: true, uppercase: true},
            {path: 'firstname', required: true},
            'birthDate',
            {path: 'mail', required: true, lowercase: true},
            {path: 'comments', textWidth: 1000},
            {path: 'language', tKey: 'correspondenceLanguage', required: true},
            {path: 'phone', type: 'phoneNumber'},
            'status',
            //{path: 'quotient', default: 100, positiveNumber: true},
            {path: 'split', default: 0, positiveNumber: true},
            {path: 'registrationNumber', required: true},
            {path: 'accountNumber', disabled: true, placeholder: ' '},
            {
                path: 'entryDate',
                required: true,
                subscriptions: {
                    onChange: (newValue, oldValue, {store}) => {
                        store.dispatch(setFieldStartDate('e_releaseDate', newValue))
                    }
                }
            },
            'releaseDate',
            {path: 'authorizations', tKey: 'profiles', required: true},
            {path: 'active', tKey: 'siteAccess', default: true},
            {
                path: 'rightHolders',
                defaultSortBy: 'birthDate',
                defaultSortDirection: 'ASC',
                newable: false,
                removable: true,
                viewMap: {
                    dt: ['firstname','lastname', 'birthDate', {path: 'kinship', translate: true}, 'dependent'],
                    form: [
                        {path: 'firstname', editable: false},
                        {path: 'lastname', editable: false},
                        {path: 'birthDate', editable: false},
                        {path: 'kinship', editable: false},
                        {path: 'dependent', tKey: 'fiscallyDependent', default: false, editable: false}
                    ]
                }
            },
            {path: 'address'},
            {path: 'bank'},
            {path: 'accountOwner'},
            {path: 'iban'},
            {path: 'bic'},
            {path: 'files', tKey: 'documents'},
            {path: 'declaration1', tKey: 'declaration1_abbrev', default : false, disabled: true},
            {path: 'declaration2', tKey: 'declaration2_abbrev', default : false, disabled: true},
            {path: 'maximumAmount', hidden: true}
        ]
    },
    actionSubscriptions: [
        {
            actionType: GET_OBJECT_SUCCESS,
            subscription: ({ store, t }) => {
                const state = store.getState()

                const maximumAmount = _.get(state, 'edit.object.data.maximumAmount')

                const maximumNotUndefined = !!maximumAmount && maximumAmount !== 'undefined'

                store.dispatch(changeFieldProperty(
                    'e_declaration2',
                    'tKey',
                    maximumNotUndefined
                        ? t( 'declaration2_user', {max: maximumAmount})
                        : 'declaration2_abbrev'
                ))
            }
        }
    ],
}
