const moment = require('moment')
const _ = require('lodash')
const { basicContext } = require("../../../utils/contextUtils")
const {generate} = require('./generatePdf')

export const generateTaxReturnDocument = async (object, oldObject, context, callback) => {

    const usersQuery = object.beneficiaries && object.beneficiaries.length !== 0
        ? {_id : {$in : object.beneficiaries.map(user => global.ObjectID(user.id))}}
        : {}

    const users = await global.app.R.CUser.find({
        ...basicContext(context),
        fieldPath: ['civility.id', 'fullName', 'registrationNumber', 'language'],
        filters: ['inExercise', 'salariedAndRetired'],
        data: {exercise: object.exercise},
        query: usersQuery
    })

    object.beneficiariesNumber = users.length

    const exerciseBenefits = await global.app.R.Benefit.find({
        ...basicContext(context),
        query: {
            exercise: global.ObjectID(object.exercise.id),
            taxReturnDocument: true
        }

    })

    const refunds = await global.app.R.Refund.find({
        ...basicContext(context),
        fieldPath: ['refund', 'status', 'user.id', 'benefit.exercise.id'],
        query: {
            benefit: {$in: exerciseBenefits.map(o => new global.ObjectID(o.id))},
            user: {$in: users.map(user => global.ObjectID(user.id))},
            status: 'paid'

        }
    })

    const refundsByUser = _.groupBy(refunds, 'user.id')

    const secretary = await global.app.R.Contacts.collection.findOne({
        function: 'secretary'
    })

    generate(users, refundsByUser, object, secretary, context, (e, file) => {
        object.file = {
            ...file,
            user: _.pick(context.user, ['id', 'name']),
            date: moment().format("YYYY-MM-DD HH:mm")
        }
        callback(null, object, oldObject)
    })

}
