
import {decrypt} from "../../../utils/crypto";

const path = require("path");
const _ = require("lodash");

const defaultMail = {
    from: '"myCSEManCo" <assistance@mycsemanco.com>',
    replyTo: "assistance@mycsemanco.com",
    content: 'userInfoUpdate.html',
    verbose: {
        general: true,
    },
    service: "mailjet"
}

const sendMail = function(users, context) {

    const logMessage = () => {
        console.log('...Mailing')
    }
    _.uniqBy(users, user => user.mail).forEach(user => {
        const language = user.language ? user.language.id : 'fr'
        const mail = _.defaults({
            to: user.mail && decrypt(user.mail),
            context: {
                firstName: user.firstname,
                lastName : user.lastname,
                civility : context.tl(user.civility.id, language),
                url: global.isProd
                    ? `https://${context.host}/business/${context.groupModel.id}`
                    : `http://localhost:3000/business/${context.groupModel.id}`
            },
            subject: {template: `myCSEManCo : ${context.tl('bankDetailsUpdate', language)}`},
            templateDir: path.join(
                global.appRoot,
                global.isProd
                    ? 'buildServer/server/models/fncs/mails/templates'
                    : 'src/server/models/fncs/mails/templates',
                language
            ),
        }, defaultMail);

        global.mailer.sendMail(mail, logMessage)
    })
}

export async function sendUserMail(userIds, context) {
    const users = await global.app.R.CUser.find({
        query: {
            _id: {$in: userIds.map(id => global.ObjectID(id))}
        },
        fieldPath: ['firstname', 'lastname', 'mail', 'civility.name', 'language.id'],
        group: new global.ObjectID(context.group.id)
    })
    if (users.length !== 0) sendMail(users, context)

}
