const async = require("async")
const moment = require('moment')
const _ = require('lodash')
const Errors = require("../../utils/Errors").default
const { requestButtons, saveButton, returnButton, validateAge } = require('./utils')
const {workflowMailing} = require('./mails/refundWorkflowMails')
const { basicContext } = require('../../utils/contextUtils')
const {toastr} = require ('react-redux-toastr')
const { generateFetchFieldListAction, fetchList } = require('../../../apps/KpModule/actions/api')
const { getRandomListData } = require('../../../apps/KpModule/selectors')
const { setUiMode, setObjectMode, emptyEditObject, setFormButtons, setDataList } = require('../../../apps/KpModule/actions')
const { calculateExerciseEndowment, calculateBenefitEndowment } = require('./utils/refundRequestUtils')

const getRefundByStatus = (allRequests, statuses) => {
    let requests = 0
    return allRequests && allRequests.reduce((acc, request) => {
        if(statuses.includes(request.status)) {
            requests++
            return acc + (parseFloat(request.refund) || 0)
        }
        return acc
    }, 0).toFixed(2)
}

const getAmountByStatus = (allRequests, statuses) => {
    let requests = 0
    return allRequests && allRequests.reduce((acc, request) => {
        if(statuses.includes(request.status)) {
            requests++
            return acc + (parseFloat(request.amount) || 0)
        }
        return acc
    }, 0).toFixed(2)
}

export const entity = {
    name: 'RequestGroup',
    facets: ['files', 'comments'],
    fields: [
        {path: 'name'},
        {path: 'user', type: 'CUser', index: true, nullable: true},
        {path: 'requests', type: 'Refund', link: {type: "OTM", oppositeField: {index: true}}},
        {path: 'issued', type: 'date', nullable: true},
        {
            path: "greenStyledRow",
            fieldPath: ['requests.id', 'requests.benefit', 'requests.benefit.exercise.exerciseStatus'],
            $f: function (requestGroup, context, callback) {
                const module = context.clientContext && context.clientContext.moduleId
                const getExerciseStatus = request => _.get(request, 'benefit.exercise.exerciseStatus.id')

                async.parallel(
                    requestGroup.requests.map(request =>
                        callback => {
                            global.app.R.Reallocation.collection.findOne({
                                requests: { $elemMatch: { $eq: new global.ObjectID(request.id) } },
                                status: {$nin: ['refused', 'validated']}
                            }, (err, reallocation) => {
                                if(module === 'm-R-requestGroup')
                                    callback(err, !reallocation && ['draft', 'rectification'].includes(request.status) && getExerciseStatus(request) !== 'finalClosure')
                                else if(module === 'm-R-requestGroupControl')
                                    callback(err, !reallocation && ['ongoing', 'justification'].includes(request.status) && getExerciseStatus(request) === 'ongoing')
                                else if(module === 'm-R-requestGroupValidation')
                                    callback(err, !reallocation && ('controlled' === request.status) && getExerciseStatus(request) === 'ongoing')
                                else callback(err, false)
                            })
                        }
                    ),
                    (err, results) => {
                        callback(err, results.some(result => result))
                    });

            }
        },
        {
            path: "orangeStyledRow",
            fieldPath: ['requests.id', 'requests.user.id', 'requests.user.status.id', 'requests.user.rightHolders.id', 'requests.user.rightHolders.kinship.id'],
            $f: function (requestGroup, context, callback) {
                async.parallel(requestGroup.requests.map(
                    request => callback => {
                        const user = request.user

                        async.parallel([
                            callback =>
                                global.app.R.Exercise.find(
                                    {
                                        ...basicContext(context),
                                        query:  { exerciseStatus: 'ongoing' }
                                    },
                                    (error, exercises) => {
                                        if(error) return callback(error)
                                        if(exercises.length) {
                                            const exercise = exercises[0]

                                            async.waterfall([
                                                async.constant(exercise),
                                                (exercise, callback) => {
                                                    if(exercise) {
                                                        global.app.R.Benefit.find({
                                                            ...basicContext(context),
                                                            query: {exercise: new global.ObjectID(exercise.id)}
                                                        },(error, ongoingExerciseBenefits) => {
                                                            if(error) callback(error)
                                                            callback(null, exercise, ongoingExerciseBenefits)
                                                        })
                                                    }
                                                },
                                                (exercise, ongoingExerciseBenefits, callback) => {
                                                    if(ongoingExerciseBenefits.length) {
                                                        global.app.R.Refund.find({
                                                            ...basicContext(context),
                                                            fieldPath: ['refund'],
                                                            query: {
                                                                user: new global.ObjectID(user.id),
                                                                benefit: {$in: ongoingExerciseBenefits.map(o => new global.ObjectID(o.id))},
                                                                status: { $nin: ['draft', 'rectification', 'refused', 'reallocated'] }

                                                            }
                                                        },(error, requests) => {
                                                            if(error) callback(error)
                                                            const sum = requests.reduce((acc, request) => acc + parseFloat(request.refund), 0)
                                                            callback(null, exercise, sum)
                                                        })
                                                    } else {
                                                        callback(null, exercise, 0)
                                                    }

                                                },
                                                (exercise, ongoingExerciseRefund, callback) => {

                                                    const exerciseEndowment = calculateExerciseEndowment(exercise, user)

                                                    callback(null, (_.round(exerciseEndowment - ongoingExerciseRefund)) < 0)
                                                }
                                            ], callback)
                                        } else {
                                            console.warn('No Ongoing exercise');
                                            return callback(null, false)
                                        }
                                    }
                                ),
                            callback => {
                                const benefit = request.benefit
                                const benefitEndowment = calculateBenefitEndowment(benefit, user)
                                global.app.R.Refund.find({
                                    ...basicContext(context),
                                    fieldPath: ['refund'],
                                    query: {
                                        user: new global.ObjectID(user.id),
                                        benefit: new global.ObjectID(benefit.id),
                                        status: { $nin: ['draft', 'rectification', 'refused', 'reallocated'] }

                                    }
                                },(error, requests) => {
                                    if(error) callback(error)
                                    const totalRefund = requests.reduce((acc, request) => acc + parseFloat(request.refund), 0)
                                    callback(null, (_.round(benefitEndowment - totalRefund)) < 0)
                                })

                            },
                            callback => callback(null,
                                request.refund === '0.00' && ['ongoing', 'controlled'].includes(request.status)
                            )

                        ], function(err, results) {
                            callback(null, results[0] || results[1] || results[2])
                        })
                    }), function(err, results) {
                    const isOrange = results.some(result => result)
                    callback(null, isOrange)
                });

            }
        },
        {
            path: "sumAmount",
            fieldPath: ['requests.id', 'requests.id', 'requests.amount'],
            f: function () {
                return this.requests && this.requests.reduce((acc, request) => acc + parseFloat(request.amount), 0).toFixed(2)
            }
        },
        {
            path: "refundAmount",
            fieldPath: ['requests.id', 'requests.id', 'requests.amount'],
            f: function () {
                let refundedRequests = 0
                return this.requests && this.requests.reduce((acc, request) => {
                    if(request.refund) {
                        refundedRequests++
                        return acc + (parseFloat(request.refund) || 0)
                    }
                    return acc
                }, 0).toFixed(2)
            }
        },
        {
            path: 'toProcess',
            fieldPath: ['requests.id', 'requests.id', 'requests.amount', 'requests.benefit.exercise'],
            f: function () {
                const filteredRequests = this.requests.filter(request =>
                    request.refund && _.get(request, 'benefit.exercise.exerciseStatus.id') !== 'finalClosure'
                )
                return getAmountByStatus(filteredRequests, ['draft', 'rectification'])
            }
        },
        {
            path: 'toProcessByController',
            fieldPath: ['requests.id', 'requests.id', 'requests.amount', 'requests.benefit.exercise'],
            f: function () {
                const filteredRequests = this.requests.filter(request =>
                    request.refund && _.get(request, 'benefit.exercise.exerciseStatus.id') !== 'finalClosure'
                )
                return getAmountByStatus(filteredRequests, ['ongoing', 'justification'])
            }
        },
        {
            path: 'toProcessByValidator',
            fieldPath: ['requests.id', 'requests.id', 'requests.amount'],
            f: function () {
                const filteredRequests = this.requests.filter(request =>
                    request.refund && _.get(request, 'benefit.exercise.exerciseStatus.id') !== 'finalClosure'
                )
                return getAmountByStatus(filteredRequests, ['controlled'])
            }
        },
        {
            path: 'paid',
            fieldPath: ['requests.id', 'requests.id', 'requests.amount'],
            f: function () {
                return getRefundByStatus(this.requests, ['paid'])
            }
        },
        {
            path: 'refusedOrReallocated',
            fieldPath: ['requests.id', 'requests.id', 'requests.amount'],
            f: function () {
                return getAmountByStatus(this.requests, ['refused', 'reallocated'])
            }
        },
        {
            path: 'fileRequests',
            fieldPath: ['requests.id', 'requests.reference', 'requests.amount', 'requests.refund', 'requests.status'],
            $f: function (requestGroup, context, callback) {
                callback(
                    null,
                    requestGroup.requests.map(request => `${request.reference} : ${request.amount}, ${request.refund}, ${context.tc(request.status)}`).join(' ; ')
                )
            }
        },
        {
            path: 'fileRequestsReferences',
            fieldPath: ['requests.id', 'requests.reference'],
            f: function () {
                return (this.requests || []).map(request => `${request.reference}`).join(' ; ')
            }
        },
        {
            path: 'statuses',
            fieldPath: ['requests.id', 'requests.id', 'requests.amount'],
            f: function () {
                const nbRequestsByStatus = this.requests.reduce((acc, request) => ({
                    ...acc,
                    [request.status]: acc[request.status]
                        ? acc[request.status] + 1
                        : 1
                }), {})
                return Object.keys(nbRequestsByStatus).map(key => `${key} (${nbRequestsByStatus[key]})`).join(', ')

            }
        },
        {
            path: "buttons",
            $f: function (requestGroup, context, callback) {
                if(context.clientContext.moduleId === 'm-R-requestGroup') {
                    const toProcess = requestGroup.requests.some(request => ['draft', 'rectification'].includes(request.status))
                    callback(null, toProcess
                        ? requestButtons
                        : [
                            {
                                ...saveButton,
                                tKey: 'saveMyRequest'
                            },
                            returnButton
                        ]
                    )
                } else callback(null, [saveButton, returnButton])
            }
        },
        {
            path: "noDeleteButtonAccess",
            $f: function (requestGroup, context, callback) {
                const module = context.clientContext && context.clientContext.moduleId

                if(module === 'm-R-requestGroup')
                    callback(null, requestGroup.requests.some(request => {
                        return !['draft', 'rectification'].includes(request.status)
                    }))
                else
                    callback(null, true)

            }
        }
    ],
    filters: [
        {
            name: "byUser",
            isDefault: false,
            async: true,
            query: (context, callback) => {
                global.app.R.CUser.collection.findOne(
                    { kpUser: new global.ObjectID(context.user.id) },
                    (e, user) => {
                        if(e) callback(e)
                        const userId = user && user._id

                        callback(
                            null,
                            userId
                                ? {user: new global.ObjectID(userId)}
                                : {user: false}
                        )
                    }
                )
            }
        }
    ],
    benefitIsUsedInReallocationRequest: function(newObject, oldObject, context, callback) {
        const module = context.clientContext && context.clientContext.moduleId
        if(module === 'm-R-requestGroup' && context.action === 'validate') {
            async.parallel(newObject.requests.filter( request => ['draft', 'rectification'].includes(request.status)).map(request => callback => {
                global.app.R.CUser.collection.findOne(
                    {kpUser: new global.ObjectID(context.user.id)},
                    (e, cUser) => {
                        global.app.R.Reallocation.collection.findOne(
                            {
                                beneficiary:  new global.ObjectID(cUser._id),
                                benefit: new global.ObjectID(request.benefit.id),
                                status: {$nin: ['refused', 'validated']},
                            }, (e, reallocation) => {
                                if(e) return callback(e)
                                else if(reallocation) return callback(new Errors.ValidationError(context.tc('benefitUndergoingReallocation', {benefit: request.benefit.name})))
                                callback()
                            }
                        )
                    })
            }),err => {
                if(err) return callback(err)
                callback()
            })
        } else if( ['m-R-requestGroupControl', 'm-R-requestGroupValidation'].includes(module) && context.action === 'save') {
            async.parallel(newObject.requests.filter( request => request.action).map(request => callback => {
                global.app.R.Reallocation.collection.findOne(
                    {
                        beneficiary:  new global.ObjectID(newObject.user.id),
                        benefit: new global.ObjectID(request.benefit.id),
                        status: {$nin: ['refused', 'validated']},
                    }, (e, reallocation) => {
                        if(e) return callback(e)
                        else if(reallocation) return callback(new Errors.ValidationError(context.tc('benefitUndergoingReallocation2', {benefit: request.benefit.name})))
                        callback()
                    }
                )
            }),err => {
                if(err) return callback(err)
                callback()
            })
        }


        else callback()
    },
    validateSave: function(newObject, oldObject, context, callback) {
        async.series([
            callback => this.benefitIsUsedInReallocationRequest(newObject, oldObject, context, callback)
        ], callback)
    },
    beforeSave: function (newObject, oldObject, context, callback) {
        const module = context.module && context.module.name

        const beneficiaryQuery = module === 'RequestGroup'
            ? { kpUser: new global.ObjectID(context.user.id) }
            : { _id: new global.ObjectID(newObject.requests[0].user.id) }

        async.waterfall([
            callback => {
                const currentBenefits = newObject.requests.map(request => request.benefit)
                global.app.R.Benefit.find({
                    ...basicContext(context),
                    query: {exercise: {$in: currentBenefits.map(benefit => new global.ObjectID(benefit.exercise.id))}}
                },(error, currentExercisesBenefits) => {
                    if(error) return callback(error)
                    callback(null, currentExercisesBenefits)
                })
            },
            (currentExercisesBenefits, callback) => global.app.R.CUser.get(
                {kpUser: new global.ObjectID(context.user.id)},
                {
                    fieldPath: [],
                    group: context.group
                },
                (error, user) => {
                    if(error) return callback(error)
                    callback(null, currentExercisesBenefits, user)
                }
            ),
            (currentExercisesBenefits, user, callback) => global.app.R.CUser.get(
                beneficiaryQuery,
                {
                    fieldPath: [
                        'rightHolders.id', 'rightHolders.kinship', 'rightHolders.dependent', 'status.id'
                    ],
                    group: context.group
                },
                (error, beneficiary) => {
                    if(error) return callback(error)
                    else if(!beneficiary) return callback(new Errors.ValidationError('missingNecessaryPermissions'))
                    callback(null, currentExercisesBenefits, user, beneficiary)
                }
            ),
            (currentExercisesBenefits, user, beneficiary, callback) => global.db.collection('r.refund').aggregate([
                {
                    $match: {
                        user: new global.ObjectID(beneficiary.id),
                        status: { $nin: ['draft', 'rectification', 'refused', 'reallocated'] },
                        benefit: {$in: currentExercisesBenefits.map(benefit => new global.ObjectID(benefit.id))}
                    }
                },
                {
                    $lookup: {
                        from: 'r.benefit',
                        localField: 'benefit',
                        foreignField: '_id',
                        as: 'benefit'
                    }
                },
                {
                    $lookup: {
                        from: 'r.exercise',
                        localField: 'benefit.exercise',
                        foreignField: '_id',
                        as: 'exercise'
                    }
                },
                {
                    $lookup: {
                        from: 'r.limit',
                        localField: 'benefit.limit',
                        foreignField: '_id',
                        as: 'limit'
                    }
                },
                {
                    $unwind: '$benefit'
                },
                {
                    $unwind: '$exercise'
                },
                {
                    $unwind: '$limit'
                },
                {
                    $project: {
                        benefit: 1,
                        exercise: 1,
                        amount: 1,
                        refund: 1,
                        limit: 1
                    }
                },
                {
                    $group: {
                        _id: {benefit: '$benefit'},
                        exercise: {$first: '$exercise'},
                        limit: {$first: '$limit'},
                        elements: { $push: {amount: '$amount', refund: '$refund'} }
                    }
                }
            ]).toArray()
                .then(objects => {
                    return callback(null, currentExercisesBenefits, user, beneficiary, objects.map(object => ({
                        benefit: _.get(object, '_id.benefit'),
                        amount: _.sumBy(object.elements, function(o) { return parseFloat(o.amount); }),
                        refund: _.sumBy(object.elements, function(o) { return parseFloat(o.refund); }),
                        exercise: object.exercise,
                        limit: object.limit
                    })))
                })
                .catch(error => {
                    callback(error)
                })
            ,
            (currentExercisesBenefits, user, beneficiary, refundsByBenefit, callback) => {

                if(context.restAction && context.restAction.crudType === 'C') {
                    newObject.issued = moment().format("YYYY-MM-DD")
                    newObject.user = new global.ObjectID(beneficiary.id)
                }

                const benefitsByExercise = _.groupBy(currentExercisesBenefits, 'exercise.id')
                const refundsByExercise = _.groupBy(refundsByBenefit, 'exercise._id')

                const accountStatements = []
                const automaticComments= []

                let accumulatedRefundByBenefit = {}
                let accumulatedRefundByExercise = {}

                for(let i = 0; i < newObject.requests.length; i++) {
                    if(newObject.requests[i].status === 'draft' && !newObject.requests[i].reference) {
                        newObject.requests[i].status = 'draft'
                        newObject.requests[i].reference = `${beneficiary.registrationNumber}-${newObject.requests[i].benefit.code}-${newObject.requests[i].sequence}`
                        newObject.requests[i].user = new global.ObjectID(beneficiary.id)
                        newObject.requests[i].userFullName = `${beneficiary.firstname} ${beneficiary.lastname}`
                        newObject.requests[i].journal = 'VEN'
                        newObject.requests[i].refund = "0.00"
                    }
                    const currentBenefit = newObject.requests[i].benefit
                    const currentBenefitRefundObject = refundsByBenefit.find(benefitRefund => benefitRefund.benefit._id.toString() === currentBenefit.id)
                    const currentExerciseRefundObject = refundsByExercise[currentBenefit.exercise.id]
                    const currentBenefitRefund = currentBenefitRefundObject ? currentBenefitRefundObject.refund : 0
                    const currentExerciseRefund = currentExerciseRefundObject ? currentExerciseRefundObject.reduce((acc, benefit) => acc + benefit.refund, 0) : 0

                    const variableLimit = parseFloat(currentBenefit.limit.variable)
                    const fixedLimit = parseFloat(currentBenefit.limit.fixed)
                    const startDate = currentBenefit.exercise.startDate
                    const childrenPresenceCondition = currentBenefit.childrenPresenceCondition


                    const childrenNumber = beneficiary.rightHolders
                        .filter(rh => rh.kinship.id === 'child' && rh.dependent)
                        .filter( rh => validateAge(rh.birthDate, startDate, 25))
                        .length

                    const fixedAndVariable = childrenPresenceCondition && !childrenNumber
                        ?  0
                        : (childrenNumber * variableLimit ) + fixedLimit

                    const exerciseVariableDot = parseFloat(currentBenefit.exercise.variableDot)
                    const exerciseFixedDot = parseFloat(currentBenefit.exercise.fixedDot)

                    const exerciseStartDate = currentBenefit.exercise.startDate
                    const exerciseEndDate = currentBenefit.exercise.endDate
                    const entryDate = beneficiary.entryDate
                    const releaseDate = beneficiary.releaseDate

                    if(releaseDate) {
                        releaseDate.setDate( releaseDate.getDate() - 1)
                    }

                    const start = entryDate
                        ? entryDate < exerciseStartDate ? exerciseStartDate : entryDate
                        : exerciseStartDate
                    const end = !releaseDate || beneficiary.status.id === 'retired'
                        ?  exerciseEndDate
                        : releaseDate < exerciseEndDate
                            ? releaseDate
                            : exerciseEndDate

                    const monthsDiff = (date1, date2) => {
                        const years = date2.getFullYear() - date1.getFullYear()
                        return (years * 12) + (date2.getMonth() - date1.getMonth() + 1) ;
                    }

                    const presenceRatio = (entryDate && entryDate > exerciseEndDate) || (releaseDate && releaseDate < exerciseStartDate)
                        ? 0
                        : monthsDiff(start, end) / monthsDiff(exerciseStartDate, exerciseEndDate)

                    const userSplit = beneficiary['split'] || 0

                    const getProratedValue = value => ((value * (100 - userSplit)) / 100) * presenceRatio

                    const limitForTheExerciseBeforeProrating = (childrenNumber * exerciseVariableDot ) + exerciseFixedDot

                    const limitForTheExercise = getProratedValue(limitForTheExerciseBeforeProrating)

                    const limitForTheBenefit = currentBenefit.limit.maximum && getProratedValue(fixedAndVariable) >= parseFloat(currentBenefit.limit.maximum)
                        ? parseFloat(currentBenefit.limit.maximum)
                        : getProratedValue(fixedAndVariable)


                    const benefitsLimits = benefitsByExercise[currentBenefit.exercise.id].reduce((acc, benefit) => {
                        const fixedAndVariable = childrenPresenceCondition && !childrenNumber
                            ? 0
                            : (childrenNumber * benefit.limit.variable ) + benefit.limit.fixed

                        const benefitLimit =  currentBenefit.limit.maximum && getProratedValue(fixedAndVariable) >= parseFloat(benefit.limit.maximum)
                            ? parseFloat(benefit.limit.maximum)
                            : getProratedValue(fixedAndVariable)

                        return {
                            ...acc,
                            [benefit.id]: _.round(benefitLimit, 2).toFixed(2)
                        }
                    }, {})

                    const benefitsRest = refundsByExercise[new global.ObjectID(currentBenefit.exercise.id)]
                        ? refundsByExercise[new global.ObjectID(currentBenefit.exercise.id)].reduce((acc, object) => ({
                            ...acc,
                            [_.get(object, 'benefit._id').toString()]: _.round(acc[_.get(object, 'benefit._id').toString()] - object.refund, 2).toFixed(2)
                        }), benefitsLimits)
                        : benefitsLimits

                    const action =  newObject.requests[i].action && newObject.requests[i].action.id
                    if(context.action === 'validate') {
                        const dateToday = moment().format('YYYY-MM-DD')

                        if (newObject.requests[i].status === 'draft' && (dateToday < newObject.requests[i].benefit.refundStartDate || dateToday > newObject.requests[i].benefit.refundEndDate)) {
                            return callback(new Errors.ValidationError("refundDateValidation"))
                        }

                        if(['draft', 'rectification'].includes(newObject.requests[i].status)) {

                            if( _.get(currentBenefit, 'exercise.exerciseStatus.id') !== 'ongoing') {
                                newObject.requests[i].refund = null

                                newObject.requests[i].status = 'ongoing'
                                newObject.requests[i].issued = moment().format("YYYY-MM-DD")

                                automaticComments.push({
                                    user: _.pick(context.user, ['id', 'name']),
                                    text: `${newObject.requests[i].reference}: ${context.tc(newObject.requests[i].status)}`,
                                    date: moment().format("YYYY-MM-DD HH:mm")
                                })
                            } else {
                                let actualRefund

                                const bankInfo = ['bank', 'accountOwner', 'iban', 'bic']
                                const bankInfoNotInformed = bankInfo.some(info => !beneficiary[info])
                                if(bankInfoNotInformed) return callback(new Errors.ValidationError("incompleteBankInformation"))

                                let actualAmountHowCanBeTakenExercise = 0

                                accumulatedRefundByBenefit[currentBenefit.id] = accumulatedRefundByBenefit[currentBenefit.id]
                                    ? accumulatedRefundByBenefit[currentBenefit.id]
                                    : 0

                                accumulatedRefundByExercise[currentBenefit.exercise.id] = accumulatedRefundByExercise[currentBenefit.exercise.id]
                                    ? accumulatedRefundByExercise[currentBenefit.exercise.id]
                                    : 0

                                const benefitAmount = (currentBenefit.participation * parseFloat(newObject.requests[i].amount)) / 100

                                const actualAmountHowCanBeTakenLimit = limitForTheBenefit < currentBenefitRefund + accumulatedRefundByBenefit[currentBenefit.id] + benefitAmount
                                    ? limitForTheBenefit - currentBenefitRefund - accumulatedRefundByBenefit[currentBenefit.id]
                                    : benefitAmount

                                if(_.round(actualAmountHowCanBeTakenLimit, 2) <= 0) actualRefund = 0
                                else {
                                    actualAmountHowCanBeTakenExercise = limitForTheExercise < currentExerciseRefund + accumulatedRefundByExercise[currentBenefit.exercise.id] + actualAmountHowCanBeTakenLimit
                                        ? limitForTheExercise - currentExerciseRefund - accumulatedRefundByExercise[currentBenefit.exercise.id]
                                        : actualAmountHowCanBeTakenLimit

                                    actualRefund = _.round(actualAmountHowCanBeTakenExercise, 2) > 0
                                        ? _.round(actualAmountHowCanBeTakenExercise, 2)
                                        : 0
                                }

                                accumulatedRefundByBenefit[currentBenefit.id] += actualRefund
                                accumulatedRefundByExercise[currentBenefit.exercise.id] += actualRefund

                                newObject.requests[i].refund = actualRefund.toFixed(2)

                                newObject.requests[i].status = 'ongoing'
                                newObject.requests[i].issued = moment().format("YYYY-MM-DD")
                                if(newObject.requests[i].comment) {
                                    newObject.requests[i].lastComment = newObject.requests[i].comment
                                    automaticComments.push({
                                        user: _.pick(context.user, ['id', 'name']),
                                        text: `${newObject.requests[i].reference}: ${newObject.requests[i].lastComment}`,
                                        date: moment().format("YYYY-MM-DD HH:mm")
                                    })
                                    newObject.requests[i].comment = null
                                }

                                automaticComments.push({
                                    user: _.pick(context.user, ['id', 'name']),
                                    text: `${newObject.requests[i].reference}: ${context.tc(newObject.requests[i].status)}`,
                                    date: moment().format("YYYY-MM-DD HH:mm")
                                })

                                accountStatements.push({
                                    reference: newObject.requests[i].reference,
                                    exerciseId: currentBenefit.exercise.id,
                                    benefitId: currentBenefit.id,
                                    userId: newObject.requests[i].user.id || beneficiary.id,
                                    accountStatementReason: 'submitted',
                                    accountStatementOperation: 'D',
                                    amount: (-parseFloat(newObject.requests[i].amount)).toFixed(2),
                                    refund: (-actualRefund).toFixed(2),
                                    globalRest: _.round(limitForTheExercise - currentExerciseRefund - accumulatedRefundByExercise[currentBenefit.exercise.id], 2).toFixed(2),
                                    restByBenefit: Object.keys(benefitsRest).reduce((acc, key) => {
                                        return {
                                            ...acc,
                                            [key]: accumulatedRefundByBenefit[key]
                                                ? _.round(parseFloat(benefitsRest[key]) - accumulatedRefundByBenefit[key], 2).toFixed(2)
                                                : _.round(parseFloat(benefitsRest[key]), 2).toFixed(2)
                                        }
                                    }, {}),
                                    date: new Date()
                                })
                            }
                        }
                    }
                    switch (action) {
                        case 'validate':
                            if(newObject.requests[i].refund === '0.00') return callback(new Errors.ValidationError('cannotValidateNullRefund'))
                            switch (newObject.requests[i].status) {
                                case 'ongoing':
                                case 'justification':
                                    if(user.id === newObject.requests[i].user.id) return callback(new Errors.ValidationError('cannotActOnYourOwnRequest'))
                                    newObject.requests[i].status = 'controlled'
                                    newObject.requests[i].controllerFullName = `${user.firstname} ${user.lastname}`
                                    newObject.requests[i].controller = new global.ObjectID(user.id)
                                    newObject.requests[i].updated = moment().format("YYYY-MM-DD")
                                    break
                                case 'controlled':
                                    if(user.id === newObject.requests[i].user.id) return callback(new Errors.ValidationError('cannotActOnYourOwnRequest'))
                                    newObject.requests[i].status = 'validated'
                                    newObject.requests[i].validatorFullName = `${user.firstname} ${user.lastname}`
                                    newObject.requests[i].validator = new global.ObjectID(user.id)
                                    newObject.requests[i].updated = moment().format("YYYY-MM-DD")
                            }
                            break
                        case 'refuse':
                            if(user.id === newObject.requests[i].user.id) return callback(new Errors.ValidationError('cannotActOnYourOwnRequest'))
                            accountStatements.push({
                                reference: newObject.requests[i].reference,
                                exerciseId: currentBenefit.exercise.id,
                                benefitId: currentBenefit.id,
                                userId: newObject.requests[i].user.id || beneficiary.id,
                                accountStatementReason: 'rejected',
                                accountStatementOperation: 'C',
                                amount: parseFloat(newObject.requests[i].amount).toFixed(2),
                                refund: parseFloat(newObject.requests[i].refund).toFixed(2),
                                globalRest: _.round(limitForTheExercise - currentExerciseRefund + parseFloat(newObject.requests[i].refund), 2).toFixed(2),
                                restByBenefit: {
                                    ...benefitsRest,
                                    [currentBenefit.id]: _.round(parseFloat(benefitsRest[currentBenefit.id]) + parseFloat(newObject.requests[i].refund), 2).toFixed(2)
                                },
                                date: new Date()
                            })
                            newObject.requests[i].updated = moment().format("YYYY-MM-DD")
                            newObject.requests[i].refund = "0.00"
                            switch (newObject.requests[i].status) {
                                case 'ongoing':
                                case 'justification':
                                    newObject.requests[i].controllerFullName = `${user.firstname} ${user.lastname}`
                                    newObject.requests[i].controller = new global.ObjectID(user.id)
                                    newObject.requests[i].status = 'refused'
                                    break
                                case 'controlled':
                                    newObject.requests[i].validatorFullName = `${user.firstname} ${user.lastname}`
                                    newObject.requests[i].validator = new global.ObjectID(user.id)
                                    newObject.requests[i].status = 'refused'
                            }
                            break
                        case 'rectify':
                            if(user.id === newObject.requests[i].user.id) return callback(new Errors.ValidationError('cannotActOnYourOwnRequest'))
                            accountStatements.push({
                                reference: newObject.requests[i].reference,
                                exerciseId: currentBenefit.exercise.id,
                                benefitId: currentBenefit.id,
                                userId: newObject.requests[i].user.id || beneficiary.id,
                                accountStatementReason: 'returned',
                                accountStatementOperation: 'C',
                                amount: parseFloat(newObject.requests[i].amount).toFixed(2),
                                refund: parseFloat(newObject.requests[i].refund).toFixed(2),
                                globalRest: _.round(limitForTheExercise - currentExerciseRefund+ parseFloat(newObject.requests[i].refund), 2).toFixed(2),
                                restByBenefit: {
                                    ...benefitsRest,
                                    [currentBenefit.id]: _.round(parseFloat(benefitsRest[currentBenefit.id]) + parseFloat(newObject.requests[i].refund), 2).toFixed(2)
                                },
                                date: new Date()
                            })
                            newObject.requests[i].status = 'rectification'
                            newObject.requests[i].controllerFullName = `${user.firstname} ${user.lastname}`
                            newObject.requests[i].controller = new global.ObjectID(user.id)
                            newObject.requests[i].updated = moment().format("YYYY-MM-DD")
                            newObject.requests[i].refund = "0.00"
                            break
                        case 'justify':
                            if(user.id === newObject.requests[i].user.id) return callback(new Errors.ValidationError('cannotActOnYourOwnRequest'))
                            newObject.requests[i].status = 'justification'
                            newObject.requests[i].validatorFullName = `${user.firstname} ${user.lastname}`
                            newObject.requests[i].validator = new global.ObjectID(user.id)
                            newObject.requests[i].updated = moment().format("YYYY-MM-DD")
                            break
                        default:
                            console.log('no action !!!')
                    }

                    if(action) {

                        if(newObject.requests[i].comment) {
                            newObject.requests[i].lastComment = newObject.requests[i].comment
                            automaticComments.push({
                                user: _.pick(context.user, ['id', 'name']),
                                text: `${newObject.requests[i].reference}: ${newObject.requests[i].lastComment}`,
                                date: moment().format("YYYY-MM-DD HH:mm")
                            })
                            newObject.requests[i].comment = null
                        }

                        automaticComments.push({
                            user: _.pick(context.user, ['id', 'name']),
                            text: `${newObject.requests[i].reference}: ${context.tc(newObject.requests[i].status)}`,
                            date: moment().format("YYYY-MM-DD HH:mm")
                        })

                        newObject.requests[i].action = null
                    }

                    if(newObject.requests[i].noFormAccess) {
                        newObject.requests[i] = _.omit(newObject.requests[i], ['status'])
                    }
                }

                if(automaticComments.length) {
                    newObject.comments = [
                        ...newObject.comments,
                        ...automaticComments
                    ]
                }

                if(accountStatements.length) {
                    global.db.collection('r.accountStatement').insertMany( accountStatements.map(as => ({
                        ...as,
                        reference: as.reference,
                        exercise: new global.ObjectID(as.exerciseId),
                        benefit: new global.ObjectID(as.benefitId),
                        user: new global.ObjectID(as.userId),
                        date: as.date,
                        group: new global.ObjectID(context.group.id)
                    })), e => {
                        if(e) return callback(e)
                        callback(null, newObject, oldObject)
                    })
                } else {
                    callback(null, newObject, oldObject)
                }

            }

        ], callback)

    },
    afterSave: function(newObject, oldObject, context, callback) {

        async.parallel(
            [
                ...newObject.requests
                    .filter(request => {
                        const oldRequest = oldObject && oldObject.requests.find(oldRequest => oldRequest.id === request.id)
                        return !request.noFormAccess && (!oldRequest || request.status !== oldRequest.status)
                    })
                    .map(request => callback => {
                        workflowMailing(request, newObject.files, context)

                        if(request.status === 'validated') {

                            global.app.R.BankFlow.collection.findOne({
                                reason: request.reference
                            }, (error, bankOrder) => {
                                if(!bankOrder) {
                                    const toDayDate = new Date()
                                    const earliestDate = toDayDate < request.benefit.exercise.endDate
                                        ? toDayDate
                                        : request.benefit.exercise.endDate
                                    const defaultObject = {
                                        date: moment(earliestDate).format('YYYY-MM-DD'),
                                        generated: moment().format("YYYY-MM-DD"),
                                        journal: 'VEN',
                                        amount: request.refund,
                                        wording: request.reference,
                                        recordType: 'refund',
                                        issued: request.issued,
                                        status: request.status,
                                        group: new global.ObjectID(context.group.id)
                                    }

                                    global.app.R.AnalyticalCode.get(
                                        request.benefit.analyticalCode.id,
                                        {group: context.group, user: context.user},
                                        (error, analyticalCode) => {
                                            if(error) return callback(error)

                                            global.app.R.AccountingEntries.collection.insertMany(
                                                [
                                                    {...defaultObject, type: 'G', generalAccount: request.benefit.accountNumber, direction: 'D', analyticSection: analyticalCode.code},
                                                    {...defaultObject, type: 'G', generalAccount: '411000000', auxiliaryAccount: request.user.accountNumber, direction: 'C'}
                                                ], e => {
                                                    if (e) {
                                                        console.warn(e)
                                                        callback(e)
                                                    }
                                                    else {
                                                        global.app.R.BankFlow.collection.insertOne({
                                                            bank: 'ASC',
                                                            process: 'Salarié',
                                                            processType: 'refund',
                                                            recipient: request.user.fullName,
                                                            reason: request.reference,
                                                            IBAN: request.user.iban,
                                                            BIC: request.user.bic,
                                                            userId: request.user.id,
                                                            accountNumber: request.user.accountNumber,
                                                            amount: request.refund,
                                                            generated: moment().format("YYYY-MM-DD"),
                                                            status: request.status,
                                                            group: new global.ObjectID(context.group.id)
                                                        }, e => {
                                                            if(e) console.warn(e)
                                                            return callback(e)
                                                        })
                                                    }
                                                })
                                        }
                                    )
                                } else callback(null)
                            })
                        } else callback(null)


                    }),
                callback => {
                    if(context.module && context.module.name === 'RequestGroup') {
                        global.app.R.CUser.collection.findOne(
                            {kpUser: new global.ObjectID(context.user.id)},
                            (error, user) => {
                                if(error) callback(error)
                                else {
                                    const declarations = []
                                    const requestWithChildrenPresenceCondition = newObject.requests.find(request => request.benefit.childrenPresenceCondition)

                                    const max = requestWithChildrenPresenceCondition?.benefit.limit?.maximum

                                    if(!user.declaration1) {
                                        declarations.push('declaration1')
                                    }

                                    if(!user.declaration2 && !!requestWithChildrenPresenceCondition) {
                                        declarations.push('declaration2')
                                    }

                                    const query = declarations.reduce((acc, declaration) => {
                                        return {
                                            $set: {
                                                ...acc.$set,
                                                [declaration]: true
                                            },
                                            $push: {
                                                comments: {
                                                    $each: [
                                                        ...acc.$push.comments.$each,
                                                        {
                                                            user: _.pick(context.user, ['id', 'name']),
                                                            text: `${context.tc(declaration, {max})}`,
                                                            date: moment().format("YYYY-MM-DD HH:mm")
                                                        }
                                                    ]
                                                }
                                            },
                                        }
                                    }, {
                                        $set: {},
                                        $push: { comments: { $each: [] } },
                                    })

                                    if(declarations.length > 0) {
                                        global.app.R.CUser.collection.updateOne(
                                            {kpUser: new global.ObjectID(context.user.id)},
                                            query,
                                            (error) => callback(error)
                                        )

                                    }else {
                                        callback()
                                    }
                                }
                            }
                        )
                    } else callback()
                }
            ], e => {
                console.log(e)
                return callback(e)
            }
        )
    }
}

export const modules = [
    {
        object: 'RequestGroup',
        name: 'RequestGroup',
        tKey: 'mTitle_folders',
        objectIdentifier: 'fileRequestsReferences',
        removable: true,
        defaultSortBy: 'toProcess',
        defaultSortDirection: 'DESC',
        category: {
            path: 'myBinder',
            icon: 'folder'
        },
        viewMap: {
            dt: [
                {path: 'name', tKey: 'folder'},
                {path: 'fileRequests'},
                {path: 'sumAmount', tKey: 'amount(€)_abbreviation', tooltip: true},
                {path: 'toProcess', tKey: 'toProcess(€)', tooltip: true},
                {path: 'refusedOrReallocated', tKey: 'refusedOrReallocated(€)', tooltip: true},
                {path: 'refundAmount', tKey: 'refundAbbreviated', tooltip: true},
                {path: 'paid', tKey: 'paid(€)', tooltip: true},
                {path: 'greenStyledRow', hidden: true},
                {path: 'noDeleteButtonAccess', hidden: true},
            ],
            form: {
                fields: [
                    {path: 'name', tKey: 'folder', placeholder: 'folderPlaceholder', maxLength30: true},
                    {
                        path: 'requests',
                        removable: true,
                        noRowsMessage: 'addRequestsMessage',
                        required: true,
                        viewMap: {
                            dt: [
                                {path: 'reference', tKey:'number2', initiallyNotVisible: true, width: 300},
                                {path: 'benefitFullName', tKey: 'benefit', width: 300},
                                {path: 'amount', tKey: 'amount(€)_abbreviation', tooltip: true, width: 130},
                                {path: 'refund', tKey: 'refundAbbreviated', tooltip: true, width: 130},
                                {path: 'status', translate: true, width: 140},
                                {path: 'exercise', width: 130},
                                {path: 'issued', width: 180},
                                {path: 'updated', width: 180},
                                {path: 'noFormAccess', hidden: true},
                                {path: 'greenStyledRow', hidden: true},
                            ],
                            form: [
                                {path: 'reference', tKey:'number2', disabled: true, placeholder: ' '},
                                {
                                    path: 'benefit',
                                    fieldPath: [
                                        'id', 'code', 'name', 'ageLimit', 'childrenPresenceCondition',
                                        'exercise.id', 'exercise.code', 'exercise.startDate', 'exercise.endDate',
                                        'exercise.exerciseStatus', 'exercise.fixedDot', 'exercise.variableDot',
                                        'limit.fixed', 'limit.variable', 'limit.maximum',
                                    ],
                                    display: 'name',
                                    required: true,
                                    editable: false,
                                    filters: ['accessibleForRefund', 'userHasBalance', 'notClosedExercise'],
                                    subscriptions: {
                                        onChange: (newValue, oldValue, { module, store, t }) => {
                                            const state = store.getState()
                                            const requestsField = module.viewMap.form.fields.find(
                                                field => field.path === 'requests'
                                            )

                                            const benefitField = requestsField.viewMap.form.fields.find(
                                                field => field.path === 'benefit'
                                            )

                                            const exerciseField = requestsField.viewMap.form.fields.find(
                                                field => field.path === 'exercise'
                                            )

                                            const benefitNameField = requestsField.viewMap.form.fields.find(
                                                field => field.path === 'benefitFullName'
                                            )

                                            const beneficiariesField = requestsField.viewMap.form.fields.find(
                                                field => field.path === 'beneficiaries'
                                            )

                                            if(newValue) {
                                                exerciseField.setValue(newValue.exercise.code)
                                                benefitNameField.setValue(newValue.name)

                                                const data = {
                                                    ageLimit: newValue.ageLimit,
                                                    exerciseStartDate: newValue.exercise.startDate
                                                }

                                                store.dispatch(
                                                    generateFetchFieldListAction(
                                                        "m-R-requestGroup.Refund_beneficiaries",
                                                        store.getState,
                                                        'form',
                                                        {data}
                                                    )
                                                ).then(() => {
                                                    const newState = store.getState()
                                                    const list = newState.dataLists.byId['m-R-requestGroup.Refund_beneficiaries'].list
                                                    const currentBeneficiaries = beneficiariesField.getValue()
                                                    const newBeneficiaries = currentBeneficiaries.filter(beneficiary => list.allIds.includes(beneficiary.id))
                                                    beneficiariesField.setValue(newBeneficiaries)
                                                })

                                                const userObject = getRandomListData(state)[0]

                                                if(userObject && !userObject.declaration2  && newValue.childrenPresenceCondition) {
                                                    toastr.confirm(t('declaration2Invitation', {max: newValue.limit.maximum}), {
                                                        onOk: () => console.log('OK: clicked'),
                                                        onCancel: () => {
                                                            benefitField.setValue(null)
                                                        },
                                                        okText: `${t('accept')}`,
                                                        cancelText: `${t('cancel')}`,
                                                        id: 'custom-toastr-style'
                                                    })
                                                }
                                            }
                                        }
                                    }
                                },
                                {path: 'currencyAmount', positiveNumber: true, required: true,
                                    subscriptions: {
                                        onChange: (newValue, oldValue, { formInitialize, module, store }) => {
                                            if (!formInitialize && newValue && newValue.length > 0) {

                                                const requestsField = module.viewMap.form.fields.find(
                                                    field => field.path === 'requests'
                                                )

                                                const rate = requestsField.viewMap.form.fields.find(
                                                    field => field.path === 'rate'
                                                )
                                                const field = requestsField.viewMap.form.fields.find(
                                                    field => field.path === 'amount'
                                                )
                                                const amount = _.round(newValue / rate.getValue(), 2)

                                                field.setValue(amount)
                                            }
                                        }
                                    }
                                },
                                {path: 'rate', tKey: 'currencyRate', positiveNumber: true, required: true, default: 1,
                                    subscriptions: {
                                        onChange: (newValue, oldValue, { formInitialize, module, store }) => {
                                            if (!formInitialize && newValue && newValue.length > 0) {
                                                const requestsField = module.viewMap.form.fields.find(
                                                    field => field.path === 'requests'
                                                )

                                                const currencyAmount = requestsField.viewMap.form.fields.find(
                                                    field => field.path === 'currencyAmount'
                                                )
                                                const field = requestsField.viewMap.form.fields.find(
                                                    field => field.path === 'amount'
                                                )
                                                const amount = _.round(currencyAmount.getValue() / newValue, 2)

                                                field.setValue(amount)
                                            }
                                        }
                                    }
                                },
                                {path: 'amount', positiveNumber: true, tKey: 'amount(€)', disabled: true},
                                {path: 'beneficiaries', filters:["byUser", "eligible"], display: 'fullName', placeholder: 'selectSpouseAndChildren', required: false},
                                {path: 'comment', type: 'textarea'},
                                {path: 'sequence', hidden: true},
                                {path: 'noFormAccess', hidden: true, default: false},
                                {path: 'greenStyledRow', hidden: true, default: true},
                                {path: 'noDeleteButtonAccess', hidden: true, default: true},
                                {path: 'status', hidden: true, default: 'draft'},
                                {path: 'exercise', hidden: true},
                                {path: 'benefitFullName', hidden: true},
                                {path: 'user', hidden: true},
                                {path: 'userFullName', hidden: true},
                            ]
                        },
                        subscriptions: {
                            onChange: (newValue, oldValue, { store }) => {
                                if(newValue) {
                                    const toProcess = newValue.some(request => ['draft', 'rectification'].includes(request.status))
                                    store.dispatch(setFormButtons(
                                        toProcess ? requestButtons : [{...saveButton, tKey: 'saveMyRequest'}, returnButton])
                                    )
                                }
                            }
                        }
                    },
                    {path: 'files', placeholder: 'filesPlaceholder', noRowsMessage: 'noRowsMessage', tKey: 'supportingDocuments', maxLength: 5, required: true},
                    {path: 'comments', textWidth: 500, disabled: true},
                    {path: "buttons", hidden: true},
                    {path: 'fileRequestsReferences', hidden: true}
                ],
                onOpen: ({state, store, t}) => {
                    const query = {kpUser: state.user.id}
                    store.dispatch(fetchList(query,'m-R-information','r.cuser','m-R-information-dt')).then(() => {
                        const state = store.getState()
                        const userObject = getRandomListData(state)[0]
                        if(userObject && !userObject.declaration1) {
                            toastr.confirm(t('declaration1Invitation'), {
                                onOk: () => console.log('OK: clicked'),
                                onCancel: () => {
                                    store.dispatch(setUiMode('list'))
                                    store.dispatch(emptyEditObject())
                                    store.dispatch(setObjectMode(''))
                                },
                                okText: `${t('attest')}`,
                                cancelText: `${t('cancel')}`,
                                id: 'custom-toastr-style'
                            })
                        }
                    })
                },
                afterSave: ({store}) => {
                    store.dispatch(
                        generateFetchFieldListAction(
                            "m-R-requestGroup.Refund_benefit",
                            store.getState,
                            'form'
                        )
                    )
                }
            }
        },
        filters: [
            'byUser',
            {
                object: 'Exercise',
                path: 'exercise',
                display: 'code',
                sortList: false,
                fieldPath: ['code'],
                objectFieldPath: ['requests.status', 'requests.benefit'],
                clearable: false,
                client: true,
                isDefault: false,
                match: (object, context) => {
                    const filterExercise = _.get(context, 'data.exercise.code')
                    return object.requests.some(request => _.get(request, 'benefit.exercise.code') === filterExercise)
                }
            }
        ]
    },
    {
        object: 'RequestGroup',
        name: 'RequestGroupControl',
        tKey: 'mTitle_requestGroupControl',
        newable: false,
        removable: false,
        protectedExport: true,
        objectIdentifier: 'fileRequestsReferences',
        defaultSortBy: 'toProcessByController',
        defaultSortDirection: 'DESC',
        category: {
            path: 'refund',
            icon: 'briefcase'
        },
        defaultFormButtons: [saveButton, returnButton],
        viewMap: {
            dt: [
                {path: 'name', tKey: 'folder'},
                {path: 'fileRequests'},
                {path: 'user', tKey: 'beneficiary', display: 'fullName'},
                {path: 'sumAmount', tKey: 'amount(€)_abbreviation', tooltip: true},
                {path: 'toProcessByController', tKey: 'toProcess(€)', tooltip: true},
                {path: 'refundAmount', tKey: 'refundAbbreviated', tooltip: true},
                {path: 'greenStyledRow', hidden: true},
                {path: 'orangeStyledRow', hidden : true}
            ],
            form: {
                fields: [
                    {path: 'name', tKey: 'folder', editable: false},
                    {
                        path: 'requests',
                        required: true,
                        newable: false,
                        viewMap: {
                            dt: [
                                {path: 'reference', tKey:'number2', initiallyNotVisible: true, width: 300},
                                {path: 'benefitFullName', tKey: 'benefit', width: 300},
                                {path: 'amount', tKey: 'amount(€)_abbreviation', tooltip: true, width: 130},
                                {path: 'refund', tKey: 'refundAbbreviated', tooltip: true, width: 130},
                                {path: 'status', translate: true, width: 140},
                                {path: 'exercise', width: 130},
                                {path: 'issued', width: 180},
                                {path: 'updated', width: 180},
                                {path: 'action', width: 160},
                                {path: 'noFormAccess', hidden: true},
                                {path: 'greenStyledRow', hidden: true},
                                {path: 'orangeStyledRow', hidden : true}
                            ],
                            form: [
                                {path: 'reference', tKey:'number2', editable: false},
                                {
                                    path: 'benefit',
                                    fieldPath: ['id', 'code', 'name', 'ageLimit', 'childrenPresenceCondition'],
                                    display: 'name',
                                    editable: false,
                                },
                                {path: 'currencyAmount', positiveNumber: true, required: true, editable: false},
                                {path: 'rate', tKey: 'currencyRate', positiveNumber: true, required: true, default: 1, editable: false,},
                                {path: 'amount', positiveNumber: true, tKey: 'amount(€)', disabled: true},
                                {path: 'beneficiaries', filters:["byUser", "eligible"], display: 'fullName', placeholder: 'selectSpouseAndChildren', required: false, editable: false},
                                {path: 'action', filters: ['controlActions']},
                                {path: 'comment', type: 'textarea'},
                                {path: 'status', hidden: true},
                                {path: 'user', hidden: true},
                                {path: 'userFullName', hidden: true}
                            ]
                        }
                    },
                    {path: 'files', tKey: 'supportingDocuments', maxLength: 5, required: true, editable: false},
                    {path: 'comments', textWidth: 500, disabled: true},
                    {path: 'user', hidden: true},
                    {path: 'fileRequestsReferences', hidden: true}
                ],
                onOpen: ({state, store}) => {
                    const requests = state.edit.object.data.requests

                    const beneficiaries = _.flatMap(requests, request => request.beneficiaries)
                    if(beneficiaries) {
                        store.dispatch(
                            setDataList(
                                "m-R-requestGroupControl.Refund_beneficiaries",
                                beneficiaries
                            )
                        )
                    }
                }
            }
        },
        filters: [
            {
                object: 'Exercise',
                path: 'exercise',
                display: 'code',
                fieldPath: ['id', 'code'],
                filters: ['notClosed'],
                clearable: false,
                client: true,
                isDefault: false,
                async: true,
                query: (context, callback) => {
                    const id =  _.get(context, 'data.exercise.id')
                    global.app.R.Benefit.find({
                            ...basicContext(context),
                            query: {exercise: new global.ObjectID(id)},
                        }, (e, benefits) => {
                            if(e) return callback(e)

                            global.db.collection("r.refund").find({
                                status: {$in: ['ongoing', 'justification']},
                                benefit: {$in: benefits.map(benefit => new global.ObjectID(benefit.id))}
                            }).toArray((e, refunds) => {
                                callback(
                                    null,
                                    {_id: {$in: refunds.map(refund => refund.requestGroup)}}
                                )
                            })
                        }
                    )
                }
            }
        ]
    },
    {
        object: 'RequestGroup',
        name: 'RequestGroupValidation',
        tKey: 'mTitle_requestGroupValidation',
        newable: false,
        removable: false,
        protectedExport: true,
        objectIdentifier: 'fileRequestsReferences',
        defaultSortBy: 'toProcessByValidator',
        defaultSortDirection: 'DESC',
        category: {
            path: 'refund',
            icon: 'briefcase'
        },
        defaultFormButtons: [saveButton, returnButton],
        viewMap: {
            dt: [
                {path: 'name', tKey: 'folder'},
                {path: 'fileRequests'},
                {path: 'user', tKey: 'beneficiary', display: 'fullName'},
                {path: 'sumAmount', tKey: 'amount(€)_abbreviation', tooltip: true},
                {path: 'toProcessByValidator', tKey: 'toProcess(€)', tooltip: true},
                {path: 'refundAmount', tKey: 'refundAbbreviated', tooltip: true},
                {path: 'greenStyledRow', hidden: true},
                {path: 'orangeStyledRow', hidden : true}
            ],
            form: {
                fields: [
                    {path: 'name', tKey: 'folder', editable: false},
                    {
                        path: 'requests',
                        newable: false,
                        required: true,
                        viewMap: {
                            dt: [
                                {path: 'reference', tKey:'number2', initiallyNotVisible: true, width: 300},
                                {path: 'benefitFullName', tKey: 'benefit', width: 300},
                                {path: 'amount', tKey: 'amount(€)_abbreviation', tooltip: true, width: 130},
                                {path: 'refund', tKey: 'refundAbbreviated', tooltip: true, width: 130},
                                {path: 'status', translate: true, width: 140},
                                {path: 'exercise', width: 130},
                                {path: 'issued', width: 180},
                                {path: 'updated', width: 180},
                                {path: 'action', width: 160},
                                {path: 'overconsumption'},
                                {path: 'noFormAccess', hidden: true},
                                {path: 'greenStyledRow', hidden: true},
                                {path: 'orangeStyledRow', hidden : true}
                            ],
                            form: [
                                {path: 'reference', tKey:'number2', editable: false},
                                {
                                    path: 'benefit',
                                    fieldPath: ['id', 'code', 'name', 'ageLimit', 'childrenPresenceCondition'],
                                    display: 'name',
                                    editable: false,
                                },
                                {path: 'currencyAmount', positiveNumber: true, required: true, editable: false},
                                {path: 'rate', tKey: 'currencyRate', positiveNumber: true, required: true, default: 1, editable: false,},
                                {path: 'amount', positiveNumber: true, tKey: 'amount(€)', disabled: true},
                                {path: 'beneficiaries', filters:["byUser", "eligible"], display: 'fullName', placeholder: 'selectSpouseAndChildren', required: false, editable: false},
                                {path: 'action', filters: ['validationActions']},
                                {path: 'comment', type: 'textarea'},
                                {path: 'status', hidden: true},
                                {path: 'user', hidden: true},
                                {path: 'controller', hidden: true},
                                {path: 'userFullName', hidden: true}
                            ]
                        }
                    },
                    {path: 'files', tKey: 'supportingDocuments', maxLength: 5, required: true, editable: false},
                    {path: 'comments', textWidth: 500, disabled: true},
                    {path: 'user', hidden: true},
                    {path: 'fileRequestsReferences', hidden: true}
                ],
                onOpen: ({state, store}) => {
                    const requests = state.edit.object.data.requests

                    const beneficiaries = _.flatMap(requests, request => request.beneficiaries)
                    if(beneficiaries) {
                        store.dispatch(
                            setDataList(
                                "m-R-requestGroupValidation.Refund_beneficiaries",
                                beneficiaries
                            )
                        )
                    }
                }
            }
        },
        filters: [
            {
                object: 'Exercise',
                path: 'exercise',
                display: 'code',
                fieldPath: ['id', 'code'],
                filters: ['notClosed'],
                clearable: false,
                client: true,
                isDefault: false,
                async: true,
                query: (context, callback) => {
                    const id =  _.get(context, 'data.exercise.id')
                    global.app.R.Benefit.find({
                            ...basicContext(context),
                            query: {exercise: new global.ObjectID(id)},
                        }, (e, benefits) => {
                            if(e) return callback(e)

                            global.db.collection("r.refund").find({
                                status: 'controlled',
                                benefit: {$in: benefits.map(benefit => new global.ObjectID(benefit.id))}
                            }).toArray((e, refunds) => {
                                callback(
                                    null,
                                    {_id: {$in: refunds.map(refund => refund.requestGroup)}}
                                )
                            })
                        }
                    )
                }
            }
        ]
    }
]
