const async = require('async')
const _ = require('lodash')
const moment = require('moment')
const { basicContext } = require("../../../utils/contextUtils")
const {workflowMailing} = require('./../mails/refundWorkflowMails')
const { calculateExerciseEndowment, calculateBenefitEndowment } = require("./refundRequestUtils")
const { generate } = require("../reportsUtils/generatePdf")

export async function storeData(exercise, context) {
    const users = await global.app.R.CUser.find({
        ...basicContext(context),
        fieldPath: ['status.id', 'entryDate', 'releaseDate', 'rightHolders.id', 'rightHolders.kinship.id', 'rightHolders.dependent', 'split'],
        query: {}
    })

    const benefits = await global.app.R.Benefit.find({
        ...basicContext(context),
        query: {exercise: new global.ObjectID(exercise.id)}

    })

    const date = new Date()

    const objects = users.map(user => {

        const proratedExerciseLimit = calculateExerciseEndowment(exercise, user)

        return {
            exercise: new global.ObjectID(exercise.id),
            user: new global.ObjectID(user.id),
            status: user.status.id,
            date,
            exerciseLimit: _.round(proratedExerciseLimit, 2),
            limitByBenefit : benefits.reduce((acc, benefit) => {

                const proratedBenefitLimit = calculateBenefitEndowment(benefit, user)

                return {...acc, [benefit.code]: _.round(proratedBenefitLimit, 2)}
            }, {}),
            group: new global.ObjectID(context.group.id)
        }
    })
    await global.app.R.EndowmentHistory.collection.insertMany(objects)
    await global.app.R.CUser.collection.updateMany({}, {
        $set: {
            declaration1 : false,
            declaration2 : false,

        },
        $push: {
            comments: {
                user: _.pick(context.user, ['id', 'name']),
                text: `${context.tc('exerciseClosureReinitialisation')}`,
                date: moment().format("YYYY-MM-DD HH:mm")
            }
        }
    })
    return console.log('storing data done')

}

export async function handleRefundRequests(exercise, context) {
    const benefits = await global.app.R.Benefit.find({
        ...basicContext(context),
        query: {exercise: new global.ObjectID(exercise.id)}

    })
    const refunds = await global.app.R.Refund.find({
        ...basicContext(context),
        fieldPath: ['benefit.id', 'benefit.exercise.id', 'benefit.limit.id', 'user.id', 'user.firstname', 'user.lastname', 'user.status.id', 'user.entryDate', 'user.releaseDate', 'user.rightHolders.id', 'user.rightHolders.kinship.id', 'user.rightHolders.dependent', 'user.split', 'beneficiaries.id', 'beneficiaries.firstname', 'beneficiaries.lastname', 'requestGroup.name', 'requestGroup.files', 'requestGroup.comments'],
        query: {
            status: 'ongoing',
            refund: null,
            benefit: {$in: benefits.map(benefit => new global.ObjectID(benefit.id))}
        }
    })
    async.series(
        refunds.map(refund => callback => {
            global.db.collection('r.refund').aggregate([
                {
                    $match: {
                        user: new global.ObjectID(refund.user.id),
                        status: { $nin: ['draft', 'rectification', 'refused', 'reallocated'] },
                        benefit: {$in: benefits.map(benefit => new global.ObjectID(benefit.id))}
                    }
                },
                {
                    $lookup: {
                        from: 'r.benefit',
                        localField: 'benefit',
                        foreignField: '_id',
                        as: 'benefit'
                    }
                },
                {
                    $lookup: {
                        from: 'r.exercise',
                        localField: 'benefit.exercise',
                        foreignField: '_id',
                        as: 'exercise'
                    }
                },
                {
                    $lookup: {
                        from: 'r.limit',
                        localField: 'benefit.limit',
                        foreignField: '_id',
                        as: 'limit'
                    }
                },
                {
                    $unwind: '$benefit'
                },
                {
                    $unwind: '$exercise'
                },
                {
                    $unwind: '$limit'
                },
                {
                    $project: {
                        benefit: 1,
                        exercise: 1,
                        amount: 1,
                        refund: 1,
                        limit: 1
                    }
                },
                {
                    $group: {
                        _id: {benefit: '$benefit'},
                        exercise: {$first: '$exercise'},
                        limit: {$first: '$limit'},
                        elements: { $push: {amount: '$amount', refund: '$refund'} }
                    }
                }
            ]).toArray()
                .then(objects => {
                    const refundsByBenefit = objects.map(object => ({
                        benefit: _.get(object, '_id.benefit'),
                        amount: _.sumBy(object.elements, function(o) { return o.amount ? parseFloat(o.amount): 0; }),
                        refund: _.sumBy(object.elements, function(o) { return o.refund ? parseFloat(o.refund) : 0; }),
                        exercise: object.exercise,
                        limit: object.limit
                    }))
                    const refundsByExercise = _.groupBy(refundsByBenefit, refund => refund.exercise._id.toString())
                    const currentBenefitRefundObject = refundsByBenefit.find(benefitRefund => benefitRefund.benefit._id.toString() === refund.benefit.id)
                    const currentExerciseRefundObject = refundsByExercise[refund.benefit.exercise.id]
                    const currentBenefitRefund = currentBenefitRefundObject ? currentBenefitRefundObject.refund : 0
                    const currentExerciseRefund = currentExerciseRefundObject ? currentExerciseRefundObject.reduce((acc, benefit) => acc + benefit.refund, 0) : 0

                    const limitForTheExercise = calculateExerciseEndowment(exercise, refund.user)
                    const limitForTheBenefit = calculateBenefitEndowment(refund.benefit, refund.user)

                    let actualAmountHowCanBeTakenExercise = 0

                    const benefitAmount = (refund.benefit.participation * parseFloat(refund.amount)) / 100

                    const actualAmountHowCanBeTakenLimit = limitForTheBenefit < currentBenefitRefund + benefitAmount
                        ? limitForTheBenefit - currentBenefitRefund
                        : benefitAmount

                    actualAmountHowCanBeTakenExercise = limitForTheExercise < currentExerciseRefund + actualAmountHowCanBeTakenLimit
                        ? limitForTheExercise - currentExerciseRefund
                        : actualAmountHowCanBeTakenLimit

                    const actualRefund = actualAmountHowCanBeTakenExercise <= 0
                        ? 0
                        : _.round(actualAmountHowCanBeTakenExercise, 2)

                    const benefitsLimits = benefits.reduce((acc, benefit) => {
                        const benefitLimit = calculateBenefitEndowment(benefit, refund.user)

                        return {
                            ...acc,
                            [benefit.id]: _.round(benefitLimit, 2).toFixed(2)
                        }
                    }, {})

                    const benefitsRest = refundsByExercise[exercise.id]
                        ? refundsByExercise[exercise.id].reduce((acc, object) => ({
                            ...acc,
                            [_.get(object, 'benefit._id').toString()]: _.round(acc[_.get(object, 'benefit._id').toString()] - object.refund, 2).toFixed(2)
                        }), benefitsLimits)
                        : benefitsLimits

                    const accStatement = {
                        reference: refund.reference,
                        exercise: new global.ObjectID(exercise.id),
                        benefit: new global.ObjectID(refund.benefit.id),
                        user: new global.ObjectID(refund.user.id),
                        accountStatementReason: 'submitted',
                        accountStatementOperation: 'D',
                        amount: (-parseFloat(refund.amount)).toFixed(2),
                        refund: (-actualRefund).toFixed(2),
                        globalRest: _.round(limitForTheExercise - currentExerciseRefund - actualRefund, 2).toFixed(2),
                        restByBenefit: {
                            ...benefitsRest,
                            [refund.benefit.id]: _.round(parseFloat(benefitsRest[refund.benefit.id]) - actualRefund, 2).toFixed(2)
                        },
                        date: new Date(),
                        group: new global.ObjectID(context.group.id)
                    }

                    refund.lastComment = refund.comment

                    global.app.R.Refund.collection.updateOne(
                        {
                            _id: new global.ObjectID(refund.id)
                        },
                        {
                            $set: {refund: actualRefund.toFixed(2), comment: null}
                        },
                        (err) => {
                            if(err) callback(err)
                            else {
                                async.parallel([
                                    callback => {
                                        if(refund.lastComment) {
                                            global.db.collection('r.requestGroup').updateOne(
                                                {_id: global.ObjectID(refund.requestGroup.id)},
                                                { $push: {
                                                    comments: {
                                                        _id: new global.ObjectID(),
                                                        user: {id: refund.user.id, name: `${refund.user.firstname} - ${refund.user.lastname}`},
                                                        text: `${refund.reference}: ${refund.lastComment}`,
                                                        date: moment().format("YYYY-MM-DD HH:mm"),
                                                        group: new global.ObjectID(context.group.id)
                                                    }
                                                }},
                                                e => callback(e)
                                            )
                                        } else callback()
                                    },
                                    callback => global.db.collection('r.accountStatement').insertOne( accStatement, e => callback(e)),
                                    callback => {
                                        workflowMailing(refund, refund.requestGroup.files, context)
                                        callback(null)
                                    }
                                ], err => callback(err)
                            )
                            }
                        }
                    )

                })
                .catch(error => {
                    callback(error)
                })
            }),
        (err) => {
            if(err) console.log('error', err)
            else console.log('handling refund requests done')

        }
    )
}

export async function generateTaxReturnDocument(object, oldObject, context) {

    const users = await global.app.R.CUser.find({
        ...basicContext(context),
        fieldPath: ['civility.id', 'fullName', 'registrationNumber', 'language'],
        filters: ['inExercise', 'salariedAndRetired'],
        data: {exercise: object}
    })

    const exerciseBenefits = await global.app.R.Benefit.find({
        ...basicContext(context),
        query: {
            exercise: global.ObjectID(object.id),
            taxReturnDocument: true
        }

    })

    const refunds = await global.app.R.Refund.find({
        ...basicContext(context),
        fieldPath: ['refund', 'status', 'user.id', 'benefit.exercise.id'],
        query: {
            benefit: {$in: exerciseBenefits.map(o => new global.ObjectID(o.id))},
            user: {$in: users.map(user => global.ObjectID(user.id))},
            status: {$in: ['validated', 'waiting', 'actualized', 'paid']}

        }
    })

    const refundsByUser = _.groupBy(refunds, 'user.id')

    const secretary = await global.app.R.Contacts.collection.findOne({
        function: 'secretary',
        group: new global.ObjectID(context.group.id)
    })

    const counter = await global.db.collection("counters").findOneAndUpdate(
        { _id: 'r.reportSeq' },
        { $inc: { seq: 1} },
        {}
    );

    const data = {
        data: moment().format("YYYY-MM-DD HH:mm:ss"),
        sequence: ++counter.value.seq,
        exercise: object,
        archive: true,
        emailing: true
    }

    generate(users, refundsByUser, data, secretary, context, (e, file) => {
        const report = {
            report: 'taxReturnDocument',
            exercise: global.ObjectID(object.id),
            beneficiariesNumber: users.length,
            comparisonExercise: null,
            beneficiaries: [],
            archive: true,
            emailing: true,
            date: moment().format("YYYY-MM-DD HH:mm:ss"),
            file: {
                ...file,
                user: _.pick(context.user, ['id', 'name']),
                date: moment().format("YYYY-MM-DD HH:mm")
            },
            group: global.ObjectID(context.group.id)
        }
        global.app.R.Reports.collection.insertOne(
            report,
            error => {
                if(error) throw(error)
                console.log('report successfully created')
            }
        )
    })
    return console.log('launching report creation')
}

export async function purgeData(exercise, context) {

    const exerciseToPurge = await global.app.R.Exercise.collection.findOne({code: (parseInt(exercise.code) - 3).toString()})
    const exercisesToPurge = await global.app.R.Exercise.collection.find({code: {$lte: (parseInt(exercise.code) - 3).toString()}}).toArray()
    if(!exerciseToPurge && !exercisesToPurge.length) return
    const endDate = exerciseToPurge.endDate
    const benefits = await global.app.R.Benefit.find({
        ...basicContext(context),
        query: {exercise: {$in: exercisesToPurge.map(exercise => exercise._id)}}

    })

    const refundRequests = await global.app.R.Refund.find({
        ...basicContext(context),
        fieldPath: ['reference', 'requestGroup.id'],
        query: {benefit: {$in: benefits.map(benefit => global.ObjectID(benefit.id))}}

    })

    const groupsRefundRequests = await global.app.R.Refund.find({
        ...basicContext(context),
        fieldPath: ['requestGroup.id', 'requestGroup.comments', 'requestGroup.files'],
        query: {requestGroup: {$in: _.uniq(refundRequests.map(request => request.requestGroup.id)).map(id => global.ObjectID(id))}}

    })

    const groupedByGroupId = _.groupBy(groupsRefundRequests, 'requestGroup.id')

    const groupsToDelete = Object.keys(groupedByGroupId)
        .filter(groupId => !groupedByGroupId[groupId].some(request => !refundRequests.some(refundRequest => refundRequest.id === request.id)))

    const groupsToNotDelete = Object.keys(groupedByGroupId)
        .filter(groupId => !groupsToDelete.includes(groupId))

    const filteredCommentsByGroupId =  groupsToNotDelete.reduce((acc, groupId) => {
        const comments = groupedByGroupId[groupId][0].requestGroup.comments
        return {
            ...acc,
            [groupId]: comments
                ? comments.filter(comment => !refundRequests.some(request => request.reference === comment.text.split(':')[0]))
                : []
        }
    }, {})

    const filteredDocumentsByGroupId =  groupsToNotDelete.reduce((acc, groupId) => {
        const files = groupedByGroupId[groupId][0].requestGroup.files
        return {
            ...acc,
            [groupId]: files
                ? files.filter(file => file.date > moment(endDate).format('YYYY-MM-DD hh:mm'))
                : []
        }
    }, {})



    const invoices = await global.app.R.Invoice.find({
        ...basicContext(context),
        fieldPath: ['reference'],
        query: {
            invoiceDate: {$lte: moment(endDate).format('YYYY-MM-DD')}
        }
    })

    const refundPaymentOrders = await global.app.R.BankFlow.find({
        ...basicContext(context),
        query: {reason: {$in: refundRequests.map(request => request.reference)}}

    })

    const invoicePaymentOrders = await global.app.R.BankFlow.find({
        ...basicContext(context),
        query: {reason: {$in: invoices.map(invoice => invoice.wording)}}

    })

    const paymentOrdersIds = _.concat(
        refundPaymentOrders.map(request => request.id),
        invoicePaymentOrders.map(invoice => invoice.id)
    )

    const paymentBatches = await global.app.R.Batch.find({
        ...basicContext(context),
        fieldPath: ['reference', 'orderLines.id'],
        query: {orderLines: {$elemMatch : {$in: paymentOrdersIds.map(id => global.ObjectID(id))}}}

    })

    const users = await global.app.R.CUser.find({
        ...basicContext(context),
        fieldPath: ['id', 'comments', 'files'],
        query: {}

    })


    await async.parallel(
        [
            ...users.map(user => callback => {

                return global.app.R.CUser.collection.updateOne(
                    {_id: global.ObjectID(user.id)},
                    {
                        $set:
                            {
                                comments: user.comments.filter(comment => comment.date > moment(endDate).format('YYYY-MM-DD hh:mm')).map(o => ({
                                    _id: o.id && global.ObjectID(o.id),
                                    ..._.pick(o, ['user', 'text', 'date', 'group'])
                                })),
                                files: user.files.filter(comment => comment.date > moment(endDate).format('YYYY-MM-DD hh:mm')).map(o => ({
                                    _id: o.id && global.ObjectID(o.id),
                                    ..._.pick(o, ['user', 'filename', 'date', 'group'])
                                }))
                            }
                    },
                    callback
                )
            }),
            ...paymentBatches.map(batch => {
                const filteredOrderLines = batch.orderLines.filter(line => !paymentOrdersIds.includes(line.id) )

                return filteredOrderLines.length
                    ? callback => global.app.R.Batch.collection.updateOne(
                        {_id: global.ObjectID(batch.id)},
                        {$set: {
                            orderLines: filteredOrderLines.map(line => global.ObjectID(line.id)),
                            orderLinesLength: filteredOrderLines.map(line => global.ObjectID(line.id)).length
                        }}, callback)
                    : callback => global.app.R.Batch.collection.deleteOne({_id: global.ObjectID(batch.id)}, callback)
            }),
            callback => global.app.R.AccountingEntries.collection.deleteMany({
                $or: [
                    {
                        wording: {
                            $in: _.concat(
                                refundRequests.map(request => request.reference),
                                invoices.map(invoice => invoice.wording)
                            )
                        }
                    },
                    {
                        reference: {$in: paymentBatches.filter(batch => !batch.orderLines.some(line => !paymentOrdersIds.includes(line.id))).map(batch => batch.reference)}
                    }
                ]
            }, callback),
            callback => global.app.R.BankFlow.collection.deleteMany({_id: {$in: paymentOrdersIds.map(id => global.ObjectID(id))}}, callback),
            callback => global.app.R.Reallocation.collection.deleteMany({benefit: {$in: benefits.map(benefit => global.ObjectID(benefit.id))}}, callback),
            callback => global.app.R.RequestGroup.collection.deleteMany({_id: {$in: groupsToDelete.map(id => global.ObjectID(id))}}, callback),
            ...groupsToNotDelete.map(id => callback =>
                global.app.R.RequestGroup.collection.updateOne(
                    {_id: global.ObjectID(id)},
                    {
                        $set: {
                            comments: filteredCommentsByGroupId[id]
                                .map(comment => ({...comment, _id: global.ObjectID(comment.id), group: global.ObjectID(comment.group.id)}))
                                .map(o => _.pick(o, ['_id', 'text', 'date', 'user', 'group'])),
                            files: filteredDocumentsByGroupId[id]
                                .map(file => ({...file, _id: global.ObjectID(file.id), group: global.ObjectID(file.group.id)}))
                                .map(o => _.pick(o, ['_id', 'filename', 'date', 'user', 'password', 'group'])),
                        }
                    },
                    callback
                )
            ),
            callback => global.app.R.Refund.collection.deleteMany({_id: {$in: refundRequests.map(request => global.ObjectID(request.id))}}, callback),
            callback => global.app.R.Invoice.collection.deleteMany({_id: {$in: invoices.map(invoice => global.ObjectID(invoice.id))}}, callback),
            callback => global.app.R.InvoiceLine.collection.deleteMany({invoice: {$in: invoices.map(invoice => global.ObjectID(invoice.id))}}, callback),
            callback => global.app.R.Benefit.collection.deleteMany({_id: {$in: benefits.map(benefit => global.ObjectID(benefit.id))}}, callback),
            callback => global.app.R.EndowmentHistory.collection.deleteMany({exercise: {$in: exercisesToPurge.map(exercise => exercise._id)}}, callback),
            callback => global.app.R.AccountStatement.collection.deleteMany({exercise: {$in: exercisesToPurge.map(exercise => exercise._id)}}, callback),
            callback => global.app.R.EntriesExtraction.collection.deleteMany({
                creationDate: {$lte: moment(endDate).format('YYYY-MM-DD hh:mm')}
            }, callback),
            callback => global.app.R.Gifts.collection.deleteMany(
                {
                    files: {
                        $elemMatch: {
                            date: {
                                $lte: moment(endDate).format('YYYY-MM-DD hh:mm')
                            }
                        }
                    }
                }, callback
            ),
            callback => global.app.R.Ticket.collection.deleteMany(
                {
                    creationDate: {
                        $lte: endDate
                    }
                }, callback
            ),
            callback => global.app.R.MessagingPlatform.collection.deleteMany(
                {
                    sendDate: {
                        $ne: '',
                        $lte: moment(endDate).format('YYYY-MM-DD hh:mm:ss')
                    }
                }, callback
            ),
            callback => global.app.R.Reports.collection.deleteMany({exercise: {$in: exercisesToPurge.map(exercise => exercise._id)}}, callback)
        ]
    )
    console.log('purge done')
}

export async function purgeUsers(exercise, context) {
    const exerciseToPurge = await global.app.R.Exercise.collection.findOne({code: (parseInt(exercise.code) - 3).toString()})
    const exercisesToPurge = await global.app.R.Exercise.collection.find({code: {$lte: (parseInt(exercise.code) - 3).toString()}}).toArray()
    if(!exerciseToPurge) return
    const endDate = exerciseToPurge.endDate

    const cUsers = await global.app.R.CUser.find({
        ...basicContext(context),
        fieldPath: ['id', 'rightHolders.id', 'kpUser'],
        query: {
            releaseDate: {
                $lte: endDate
            },
            active: false
        }

    })

    const nextExercise = await global.app.R.Exercise.collection.findOne({code: (parseInt(exercise.code)).toString()})

    const benefits = await global.app.R.Benefit.find({
        ...basicContext(context),
        query: {exercise: nextExercise._id}

    })

    const refundRequests = await global.app.R.Refund.find({
        ...basicContext(context),
        fieldPath: ['id', 'reference', 'requestGroup.id'],
        query: {
            benefit: {$in: benefits.map(benefit => global.ObjectID(benefit.id))},
            user: {$in: cUsers.map(user => global.ObjectID(user.id))}
        }

    })

    const documentDbs = await global.app.R.DocumentDatabase.collection.find({
        $or: [
            {files: {$elemMatch: {'user.id': {$in: cUsers.map(user => user.kpUser.toString())}}}},
            {comments: {$elemMatch: {'user.id': {$in: cUsers.map(user => user.kpUser.toString())}}}},
        ]
    }).toArray()


    await async.parallel(
        [
            ...documentDbs.map(documentDb => callback => {
                const filteredComments = documentDb.comments.filter(comment => {
                    return !cUsers.some(user => user.kpUser.toString() === _.get(comment, 'user.id'))
                })

                const filteredFiles = documentDb.files.filter(file => {
                    return !cUsers.some(user => user.kpUser.toString() === _.get(file, 'user.id'))
                })
                const lastDocument = _.sortBy(filteredFiles, 'date' ).reverse()[0]

                if(!!filteredFiles.length) {
                    return global.app.R.DocumentDatabase.collection.updateOne({_id: documentDb._id}, {
                        $set: {
                            comments: filteredComments,
                            files: filteredFiles,
                            document: lastDocument
                        }
                    }, callback)
                }

                return global.app.R.DocumentDatabase.collection.deleteOne({_id: documentDb._id}, callback)
            }),
            callback => global.app.R.Refund.collection.deleteMany({_id: {$in: refundRequests.map(request => global.ObjectID(request.id))}}, callback),
            callback => global.app.R.RequestGroup.collection.deleteMany({_id: {$in: _.uniq(refundRequests.map(request => request.requestGroup.id)).map(id => global.ObjectID(id))}}, callback),
            callback => global.app.R.DocumentModelPlatform.collection.deleteMany({files: {$elemMatch: {'user.id': {$in: cUsers.map(user => user.kpUser.toString())}}}}, callback),
            callback => global.app.R.CUser.collection.deleteMany({_id: {$in: cUsers.map(user => global.ObjectID(user.id))}}, callback),
            callback => global.app.R.RightHolder.collection.deleteMany({_id: {$in: _.flatMap(cUsers, user => user.rightHolders).map(rh => global.ObjectID(rh.id)) }}, callback),
            callback => global.User.collection.deleteMany({_id: {$in: cUsers.map(user => global.ObjectID(user.kpUser.toString()))}}, callback),
            callback => global.app.R.Exercise.collection.deleteMany({_id: {$in: exercisesToPurge.map(exercise => exercise._id)}}, callback),
        ],
        () => console.log('users purge done')
    )

}
