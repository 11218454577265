import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { Creatable } from '../../../../components/Form'
import { getListOptions } from '../../selectors/index'
import getFormValidations from './formValidations'
import {colourStyles} from '../Filter/SelectStyles'
import {style} from "redux-logger/src/diff";

const createOption = label => ({
    label,
    value: label,
})

// binding for redux-form
class Component extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            inputValue: ''
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
    }

    handleInputChange(inputValue){
        this.setState({ inputValue })
    }

    handleKeyDown(event){
        const { inputValue } = this.state;
        const { input: { value, onChange } } = this.props
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':


                onChange(
                    value
                        ? [value, inputValue].join(';')
                        : inputValue
                )
                this.setState({ inputValue: '' });
                event.preventDefault();
        }
    };
    render(){
        const {
            input: { value, onChange, onBlur },
            meta: { touched, error },
            placeholder,
            disabled,
            editable,
            clearable,
            path,
            getPreview,
            t
        } = this.props

        const translatedError = t(error)
        return (
            <Creatable
                isMulti={true}
                menuIsOpen={false}
                path={path}
                styles={colourStyles}
                style={this.props.style}
                value={value && value.split(';').map(value => createOption(value))}
                onChange={options => {
                    onChange(
                        options && options.map(option => option.value).join(';')
                    )
                }}
                inputValue={this.state.inputValue}
                onInputChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown}
                touched={touched}
                error={translatedError}
                onBlur={() => onBlur(value)}
                isDisabled={disabled || !editable}
                isClearable={clearable}
                placeholder={placeholder}
                getPreview={getPreview}
                t={t}
            />
        )
    }
}

const CreatableTags = ({ field, t, objectMode }) => {
    const validations = getFormValidations(field, t)
    console.log('field style', field.style)
    return (
        <Field
            name={field.path}
            path={field.tKey || field.path}
            component={Component}
            placeholder={field.placeholder}
            disabled={field.disabled || !field.writable}
            editable={!field.editable ? objectMode === 'newObject' : field.editable  }
            validate={validations}
            clearable={field.clearable}
            style={field.style}
            getPreview={field.getPreview}
            t={t}
        />
    )
}

const mapStateToProps = (state, { field }) => ({
    options: getListOptions(state, field.id, field.sortList)
})

export default connect(mapStateToProps)(CreatableTags)
