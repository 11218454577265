const moment = require('moment')
const _ = require('lodash')
const async = require("async")
const Errors = require("../../utils/Errors").default
const {toastr} = require ('react-redux-toastr')
const { basicContext } = require('../../utils/contextUtils')
const {requestButtons, controlButtons, validationButtons, saveButton, returnButton, validateAge} = require('./utils')
const { generateFetchFieldListAction, fetchList } = require('../../../apps/KpModule/actions/api')
const { getRandomListData } = require('../../../apps/KpModule/selectors')
const { setUiMode, setObjectMode, emptyEditObject } = require('../../../apps/KpModule/actions')
const { calculateExerciseEndowment, calculateBenefitEndowment } = require('./utils/refundRequestUtils')

const defaultTranslation = {
    t: path => path,
    tc: path => _.upperFirst(path)
}

const translation = (context={}) => ({
    t: context.t || defaultTranslation.t,
    tc: context.tc || defaultTranslation.tc,
})

export const entity = {
    name: 'Refund',
    fields: [
        {path: 'action', type: 'Action', nullable: true},
        'journal',
        {path: 'reference', index: true},
        {type: 'Benefit', index: true},
        'benefitFullName',
        {path: 'currencyAmount', type: 'decimal'},
        {path: 'rate', type: 'decimal'},
        {path: 'beneficiaries', type: 'RightHolder', link: 'MTM', nullable: true},
        {path: 'date'},
        {path: 'amount', type: 'financial'},
        {path: 'refund', type: 'financial', nullable: true},
        'status',
        {path: 'issued', type: 'date', nullable: true},
        {path: 'updated', type: 'date', nullable: true},
        'comment',
        {
            path: 'files',
            fieldPath: ['requestGroup.id', 'requestGroup.files'],
            f: function () {
                return this.requestGroup && this.requestGroup.files
            }
        },
        {
            path: 'referenceWithStatus',
            fieldPath: ['reference', 'status'],
            $f: function(request, context, callback) {
                callback(null, `${request.reference} (${translation(context).tc(request.status)})`)
            }
        },
        {
            path: 'comments',
            fieldPath: ['reference', 'requestGroup.id', 'requestGroup.comments'],
            f: function () {
                return this.requestGroup && this.requestGroup.comments.filter(comment => {
                    const reference = comment.text.split(':')[0]
                    return this.reference === reference
                })
            }
        },
        {
            path: "folder",
            fieldPath: ["requestGroup.id", "requestGroup.name"],
            f: function () {
                return this.requestGroup && this.requestGroup.name
            }
        },
        {
            path: "sequence", unique: true, ps: {
                object: [{
                    type: "nextSequence",
                    sequenceId: "r.refundSeq",
                    formatResult: result => `${result}`
                }]
            }
        },
        {
            path: "beneficiariesNumber",
            fieldPath: ["beneficiaries.id"],
            f: function () {
                return this.beneficiaries && this.beneficiaries.length || 0
            }
        },
        {
            path: "exercise",
            fieldPath: ["benefit.exercise.id", "benefit.exercise.code"],
            f: function () {
                return this.benefit && this.benefit.exercise.code
            }
        },
        {
            path: "userRegistrationNumber",
            fieldPath: ["user.id", "user.registrationNumber"],
            f: function () {
                return this.user && this.user.registrationNumber
            }
        },
        'userFullName',
        {path: 'user', type: 'CUser', nullable: true},
        {path: 'controller', type: 'CUser', nullable: true},
        {path: 'validator', type: 'CUser', nullable: true},
        {
            path: "buttons",
            $f: function (request, context, callback) {
                const module = _.get(context, 'clientContext.moduleId')
                if(module === 'm-R-refundKpModule') {
                    callback(null, [ saveButton, returnButton])
                } else if(['draft', 'rectification'].includes(request.status) && module === 'm-R-request') {
                    callback(null, requestButtons)
                } else if(['ongoing', 'justification'].includes(request.status)  && module === 'm-R-supervisor') {
                    callback(null, controlButtons)
                } else if(request.status === 'controlled' && module === 'm-R-validator') {
                    callback(null, validationButtons)
                } else {
                    callback(null, [returnButton])
                }
            }
        },
        {
            path: "noFormAccess",
            fieldPath: ['benefit', 'benefit.exercise.exerciseStatus'],
            $f: function (request, context, callback) {
                const module = context.clientContext && context.clientContext.moduleId

                const status = request.status
                const exerciseStatus = _.get(request, 'benefit.exercise.exerciseStatus.id')

                if(module === 'm-R-requestGroup')
                    callback(null, !['draft', 'rectification'].includes(status) || exerciseStatus === 'finalClosure')
                else if(module === 'm-R-requestGroupControl')
                    callback(null, !['ongoing', 'justification'].includes(status) || exerciseStatus !== 'ongoing')
                else if(module === 'm-R-requestGroupValidation')
                    callback(null, !['controlled'].includes(request.status) || exerciseStatus !== 'ongoing')
                else
                    callback(null, false)

            }
        },
        {
            path: "greenStyledRow",
            fieldPath: ['benefit', 'benefit.exercise.exerciseStatus'],
            $f: function (request, context, callback) {
                const module = context.clientContext && context.clientContext.moduleId

                const status = request.status
                const exerciseStatus = _.get(request, 'benefit.exercise.exerciseStatus.id')

                global.app.R.Reallocation.collection.findOne({
                    requests: { $elemMatch: { $eq: new global.ObjectID(request.id) } },
                    status: {$nin: ['refused', 'validated']}
                }, (err, reallocation) => {
                    if(module === 'm-R-requestGroup')
                        callback(err, !reallocation && ['draft', 'rectification'].includes(status) && exerciseStatus !== 'finalClosure')
                    else if(module === 'm-R-requestGroupControl')
                        callback(err, !reallocation && ['ongoing', 'justification'].includes(status) && exerciseStatus === 'ongoing')
                    else if(module === 'm-R-requestGroupValidation')
                        callback(err, !reallocation && ('controlled' === request.status) && exerciseStatus === 'ongoing')
                    else
                        callback(err, true)
                })
            }
        },
        {
            path: "orangeStyledRow",
            fieldPath: ['user.id', 'user.status.id', 'user.rightHolders.id', 'user.rightHolders.kinship.id'],
            $f: function (request, context, callback) {
                const user = request.user

                async.parallel([
                    callback =>
                        global.app.R.Exercise.find(
                            {
                                ...basicContext(context),
                                query:  { exerciseStatus: 'ongoing' }
                            },
                            (error, exercises) => {
                                if(error) return callback(error)
                                if(exercises.length) {
                                    const exercise = exercises[0]

                                    async.waterfall([
                                        async.constant(exercise),
                                        (exercise, callback) => {
                                            if(exercise) {
                                                global.app.R.Benefit.find({
                                                    ...basicContext(context),
                                                    query: {exercise: new global.ObjectID(exercise.id)}
                                                },(error, ongoingExerciseBenefits) => {
                                                    if(error) callback(error)
                                                    callback(null, exercise, ongoingExerciseBenefits)
                                                })
                                            }
                                        },
                                        (exercise, ongoingExerciseBenefits, callback) => {
                                            if(ongoingExerciseBenefits.length) {
                                                global.app.R.Refund.find({
                                                    ...basicContext(context),
                                                    fieldPath: ['refund'],
                                                    query: {
                                                        user: new global.ObjectID(user.id),
                                                        benefit: {$in: ongoingExerciseBenefits.map(o => new global.ObjectID(o.id))},
                                                        status: { $nin: ['draft', 'rectification', 'refused', 'reallocated'] }

                                                    }
                                                },(error, requests) => {
                                                    if(error) callback(error)
                                                    const sum = requests.reduce((acc, request) => acc + parseFloat(request.refund), 0)
                                                    callback(null, exercise, sum)
                                                })
                                            } else {
                                                callback(null, exercise, 0)
                                            }

                                        },
                                        (exercise, ongoingExerciseRefund, callback) => {

                                            const exerciseEndowment = calculateExerciseEndowment(exercise, user)

                                            callback(null, _.round(exerciseEndowment - ongoingExerciseRefund) < 0)
                                        }
                                    ], callback)
                                } else {
                                    console.warn('No Ongoing exercise');
                                    return callback(null, false)
                                }
                            }
                        ),
                    callback => {
                        const benefit = request.benefit
                        const benefitEndowment = calculateBenefitEndowment(benefit, user)
                        global.app.R.Refund.find({
                            ...basicContext(context),
                            fieldPath: ['refund'],
                            query: {
                                user: new global.ObjectID(user.id),
                                benefit: new global.ObjectID(benefit.id),
                                status: { $nin: ['draft', 'rectification', 'refused', 'reallocated'] }

                            }
                        },(error, requests) => {
                            if(error) callback(error)
                            const totalRefund = requests.reduce((acc, request) => acc + parseFloat(request.refund), 0)
                            callback(null, _.round(benefitEndowment - totalRefund) < 0)
                        })

                    },
                    callback => callback(null,
                        request.refund === '0.00' && ['ongoing', 'controlled'].includes(request.status)
                    )

                ], function(err, results) {
                    callback(null, results[0] || results[1] || results[2])
                })
            }
        },
        {
            path: "noDeleteButtonAccess",
            $f: function (request, context, callback) {
                const module = context.clientContext && context.clientContext.moduleId

                if(module === 'm-R-requestGroup')
                    callback(null, !['draft', 'rectification'].includes(request.status))
                else
                    callback(null, true)

            }
        }
    ],
    filters: [
        {
            name: 'notDraft',
            isDefault: false,
            query: () => {
                return {status: {$ne: 'draft'}}
            }
        },
        {
            name: 'notDraftReallocatedNorRefused',
            isDefault: false,
            query: () => {
                return {status: {$nin: ['draft', 'refused', 'reallocated']}}
            }
        },
        {
            name: "byBeneficiary",
            isDefault: false,
            query: (context) => {
                const userId =  _.get(context, 'data.user.id')
                return userId
                    ? {user: new global.ObjectID(userId)}
                    : {user: false}
            }
        },
        {
            name: "byUser",
            isDefault: false,
            async: true,
            query: (context, callback) => {
                global.app.R.CUser.collection.findOne(
                    { kpUser: new global.ObjectID(context.user.id) },
                    (e, user) => {
                        if(e) callback(e)
                        const userId = user && user._id

                        callback(
                            null,
                            userId
                                ? {user: new global.ObjectID(userId)}
                                : {user: false}
                        )
                    }
                )
            }
        },
        {
            name: 'byExercise',
            path: 'exercise',
            object: 'Exercise',
            display: "code",
            sortList: false,
            client: true,
            clearable: false,
            isDefault: false,
            async: true,
            query: (context, callback) => {
                const id =  _.get(context, 'data.exercise.id')
                global.app.R.Benefit.find({
                        ...basicContext(context),
                        query: {exercise: new global.ObjectID(id)},
                    }, (e, benefits) => {
                        if(e) callback(e)

                        callback(
                            null,
                            {benefit: {$in: benefits.map(benefit => new global.ObjectID(benefit.id))}}
                        )
                    }
                )
            }
        },
        {
            name: 'byBenefit',
            path: 'exercise',
            object: 'Exercise',
            display: "code",
            sortList: false,
            clearable: false,
            isDefault: false,
            query: (context) => {
                const benefitId =  _.get(context, 'data.benefit.id')
                return benefitId
                    ? {benefit: new global.ObjectID(benefitId)}
                    : {benefit: false}
            }
        }
    ],
    ps: {
        context: [{
            $$u: function (context, callback) {
                if (this.options.accessType === "S" && context.restAction && context.restAction.crudType === "C") {
                    context.internalFieldPath = [
                        ...new Set([
                            ...context.internalFieldPath,
                            "benefit.code",
                            "benefit.accountNumber",
                            "sequence"
                        ])
                    ]
                }
                callback(null, context)
            }
        }]
    },
    notExternalUser: function(context, callback){
        global.app.R.CUser.get(
            {kpUser: new global.ObjectID(context.user.id)},
            {
                fieldPath: ['status'],
                group: context.group
            },
            (error, cUser) => {
                if(error) callback(error)
                if(cUser.status.id === 'external') callback(new Errors.ValidationError("notAllowedForExternalUser"))
                else callback()
            }
        )
    },
    requestDateValidation: function(newObject, context, callback){
        if(newObject.date < newObject.benefit.startDate || newObject.date > newObject.benefit.endDate){
            callback(new Errors.ValidationError("requestDateValidation"))
        } else callback()
    },
    refundDateValidation: function(newObject, context, callback){
        const dateToday = moment().format('YYYY-MM-DD')
        if (dateToday < newObject.benefit.refundStartDate || dateToday > newObject.benefit.refundEndDate) {
            callback(new Errors.ValidationError("refundDateValidation"))
        } else callback()
    },
    validateSave: function (newObject, oldObject, context, callback) {
        async.series([
            callback => this.notExternalUser(context, callback),
            //callback => this.requestDateValidation(newObject, context, callback),
            //callback => this.refundDateValidation(newObject, context, callback),
        ], callback)
    }
}

export const module_ = {
    object: 'Refund',
    name: 'Request',
    tKey: 'mTitle_requests',
    defaultSortBy: 'benefit',
    defaultSortDirection: 'ASC',
    displayLog: false,
    deletionConditions: {
        'status': ['draft', 'rectification']
    },
    category: {
        path: 'myBinder',
        icon: 'folder'
    },
    defaultFormButtons: requestButtons,
    viewMap: {
        dt: [
            {path: 'reference', tKey:'number2', initiallyNotVisible: true},
            'benefit',
            {path: 'date', tKey: 'dateOfInvoice', initiallyNotVisible: true},
            {path: 'amount', tKey: 'amount(€)_abbreviation', tooltip: true},
            {path: 'refund', tKey: 'refundAbbreviated', tooltip: true},
            {path: 'issued', initiallyNotVisible: true},
            {path: 'status', translate: true},
            {path: 'updated', initiallyNotVisible: true}
        ],
        form: {
            fields: [
                {path: 'reference', tKey:'number2', hidden: true},
                {
                    path: 'benefit',
                    fieldPath: ['id', 'name', 'ageLimit', 'childrenPresenceCondition'],
                    display: 'name',
                    required: true,
                    editable: false,
                    filters: ['userHasBalance'],
                    subscriptions: {
                        onChange: (newValue, oldValue, { module, store, t }) => {
                            const state = store.getState()
                            const date = module.viewMap.form.fields.find(
                                field => field.path === 'date'
                            )

                            const benefitField = module.viewMap.form.fields.find(
                                field => field.path === 'benefit'
                            )

                            const data = {
                                ageLimit: newValue && newValue.ageLimit,
                                dateOfInvoice: date.getValue()
                            }
                            store.dispatch(
                                generateFetchFieldListAction(
                                    "m-R-request.Refund_beneficiaries",
                                    store.getState,
                                    'form',
                                    {data}
                                )
                            )

                            const userObject = getRandomListData(state)[0]
                            if(newValue && userObject && !userObject.declaration2  && newValue.childrenPresenceCondition) {
                                toastr.confirm(t('declaration2Invitation'), {
                                    onOk: () => console.log('OK: clicked'),
                                    onCancel: () => {
                                        benefitField.setValue(null)
                                    },
                                    okText: `${t('accept')}`,
                                    cancelText: `${t('cancel')}`,
                                    id: 'custom-toastr-style'
                                })
                            }
                        }
                    }
                },
                {path: 'date', type: 'datePicker', tKey: 'dateOfInvoice', required: true, endDate: moment().format("YYYY-MM-DD"),
                    subscriptions: {
                        onChange: (newValue, oldValue, { formInitialize, module, store }) => {
                            const benefit = module.viewMap.form.fields.find(
                                field => field.path === 'benefit'
                            )
                            const benefitValue = benefit.getValue()
                            const data = {
                                ageLimit: benefitValue && benefitValue.ageLimit,
                                dateOfInvoice: newValue
                            }
                            store.dispatch(
                                generateFetchFieldListAction(
                                    "m-R-request.Refund_beneficiaries",
                                    store.getState,
                                    'form',
                                    {data}
                                )
                            )
                        }
                    }
                },
                {path: 'currencyAmount', positiveNumber: true, required: true,
                    subscriptions: {
                        onChange: (newValue, oldValue, { formInitialize, module, store }) => {
                            if (!formInitialize && newValue && newValue.length > 0) {

                                const rate = module.viewMap.form.fields.find(
                                    field => field.path === 'rate'
                                )
                                const field = module.viewMap.form.fields.find(
                                    field => field.path === 'amount'
                                )
                                const amount = _.round(newValue / rate.getValue(), 2)

                                field.setValue(amount)
                            }
                        }
                    }
                },
                {path: 'rate', tKey: 'currencyRate', positiveNumber: true, required: true, default: 1,
                    subscriptions: {
                        onChange: (newValue, oldValue, { formInitialize, module, store }) => {
                            if (!formInitialize && newValue && newValue.length > 0) {
                                const currencyAmount = module.viewMap.form.fields.find(
                                    field => field.path === 'currencyAmount'
                                )
                                const field = module.viewMap.form.fields.find(
                                    field => field.path === 'amount'
                                )
                                const amount = _.round(currencyAmount.getValue() / newValue, 2)

                                field.setValue(amount)
                            }
                        }
                    }
                },
                {path: 'amount', positiveNumber: true, tKey: 'amount(€)', disabled: true},
                {path: 'beneficiaries', filters:["byUser", "eligible"], display: 'fullName', placeholder: 'selectSpouseAndChildren', required: false},
                {path: 'files', tKey: 'supportingDocuments', maxLength: 5, required: true},
                'comments',
                {path: 'status', hidden: true},
                {path: 'reference', hidden: true},
                {path: 'user', hidden: true},
                {path: 'userFullName', hidden: true},
                {
                    path: "buttons",
                    hidden: true
                }
            ],
            onOpen: ({state, store, t}) => {
                const query = {kpUser: state.user.id}
                store.dispatch(fetchList(query,'m-R-information','r.cuser','m-R-information-dt')).then(() => {
                    const state = store.getState()
                    const userObject = getRandomListData(state)[0]
                    if(userObject && !userObject.declaration1) {
                        toastr.confirm(t('declaration1Invitation'), {
                            onOk: () => console.log('OK: clicked'),
                            onCancel: () => {
                                store.dispatch(setUiMode('list'))
                                store.dispatch(emptyEditObject())
                                store.dispatch(setObjectMode(''))
                            },
                            okText: `${t('attest')}`,
                            cancelText: `${t('cancel')}`,
                            id: 'custom-toastr-style'
                        })
                    }
                })
            },
            afterSave: ({store}) => {
                store.dispatch(
                    generateFetchFieldListAction(
                        "m-R-request.Refund_benefit",
                        store.getState,
                        'form'
                    )
                )
            }
        }
    },
    filters:['byUser']
}
