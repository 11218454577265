const fs = require("fs");
const async = require("async")
const moment = require('moment')
const path = require('path')
const _ = require('lodash')
const { templatesDirPath } = require('./templates/index')
const { sendReport } = require('./sendReportMail')

function base64Encode(file) {
    return fs.readFileSync(file, {encoding: 'base64'});
}

function getBase64EncodedUrlImage(fileName, style= "") {

    const imagePath = path.join(global.appRoot, global.isProd ? "buildServer/img" : "src/img", fileName);

    return '<img alt="logo" style="'+ style +'" src="data:image/png;base64,' + base64Encode(imagePath) + '"/>';
}

export const generate = function(users, refundsByUser, object, secretary, context, callback) {
    const outputName = `${moment(object.date).format("YYYY-MM-DD_HH-mm-ss")}_${object.exercise.code}_Attestation_de_remboursement.pdf`

    const config = {
        margin: {
          top: '0px',
          bottom: '0px'
        },
        pdfName: outputName,
        outputInDB: true,
        displayHeaderFooter: false,
        cssSheet: ''
    }

    const configuration = {
        content: users.map(user => {
            const refund = refundsByUser[user.id] ? refundsByUser[user.id].reduce((acc, refundRequest) => acc + parseFloat(refundRequest.refund), 0) : 0
            return callback => {
                const language = user.language ? user.language.id : 'fr'
                fs.readFile(path.join(templatesDirPath(language), 'taxReturnDocument.html'), "utf8", (e, data) => {
                    if(e) return callback(e);
                    global.pdf.applyValuesToTemplate({
                        secretary: secretary.firstname + ' ' + secretary.lastname,
                        civility: context.tc(user.civility.id),
                        fullName: user.fullName,
                        exerciseYear: object.exercise.code,
                        period: `${moment(object.exercise.startDate).format('DD/MM/YYYY')} - ${moment(object.exercise.endDate).format('DD/MM/YYYY')}`,
                        documentId: `${object.exercise.code}-${user.registrationNumber}-${object.sequence}`,
                        date: moment().format('DD/MM/YYYY'),
                        refund: refund.toFixed(2)
                    }, data, (err, result) => {
                        if(object.archive || object.emailing) {
                            global.tmpManager.requestFolder((e, tmpFolder) => {
                                if(e) return callback(e);
                                global.pdf.renderAndExport(result, config, tmpFolder, (e, file) => {
                                    if(e) return callback(e)
                                    global.tmpManager.removeFolder(tmpFolder);
                                    //send emails and/or archive files
                                    async.parallel([
                                        callback => {
                                            if(object.archive) {
                                                global.app.R.CUser.collection.update(
                                                    { _id: new global.ObjectID(user.id) },
                                                    {$push: {
                                                            files: {
                                                                ...file,
                                                                user: _.pick(context.user, ['id', 'name']),
                                                                date: moment().format('YYYY-MM-DD HH:mm')
                                                            }
                                                        }},
                                                    error => callback(error)
                                                )
                                            } else callback(null)
                                        },
                                        callback => {
                                            if(object.emailing) {
                                                sendReport(user, object, file, context,(err) => {
                                                    if (!err) console.log('email sent...')
                                                    callback(err)
                                                })
                                            } else callback(null)
                                        }
                                        ],err => callback(err, result)
                                    )
                                })
                            })
                        } else callback(err, result)
                    })
                })
            }
        }),
        configuration: config
    }

    global.pdf.generatePdf(configuration, callback)
}
