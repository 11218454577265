const properties = {
    url: 'https://clients-api.prod.tech.glady.com/client/token',
    headers: {
        'Content-Type': 'application/json',
        'client_id': '1aae793e-be2e-5ae6-bd90-4850e9c88366-1681476052',
        'client_secret': 'D3G7SrTFJNFxUCXOhYISNii2BgqZFE6B'
    }
}
export const modules = [
    {
        externalLink: {
            link: 'giftVouchers',
            redirect: 'services/gift/endowments/1/offerwall',
            module: 'giftVouchers',
            ...properties
        },
        object: 'CUser',
        name: 'giftVouchers',
        category: {
            path: 'myAdvantages',
            icon: 'gift'
        },
        displayLog: false,
        viewMap: {dt: ['registrationNumber']}
    },
    {
        externalLink: {
            link: 'cultureVouchers',
            redirect: 'services/gift/endowments/2/offerwall',
            module: 'cultureVouchers',
            ...properties
        },
        object: 'CUser',
        name: 'cultureVouchers',
        category: {
            path: 'myAdvantages',
            icon: 'gift'
        },
        displayLog: false,
        viewMap: {dt: ['registrationNumber']}
    },
    {
        externalLink: {
            link: 'tips',
            redirect: 'services/gift/endowments/3/offerwall',
            module: 'tips',
            ...properties
        },
        object: 'CUser',
        name: 'tips',
        category: {
            path: 'myAdvantages',
            icon: 'gift'
        },
        displayLog: false,
        viewMap: {dt: ['registrationNumber']}
    },
    {
        externalLink: {
            link: 'MyFairBook',
            module: 'myfairbook',
            simpleUrl: 'https://myfairbook.com'
        },
        object: 'CUser',
        name: 'MyFairBook',
        category: {
            path: 'myAdvantages',
            icon: 'gift'
        },
        displayLog: false,
        viewMap: {dt: ['registrationNumber']}
    }
]
