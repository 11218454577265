import {GET_OBJECT_SUCCESS} from "../../../apps/KpModule/actions/api"
import _ from "lodash";
import {changeFieldProperty} from "../../../apps/KpModule/actions"

export const module_ = {
    name: 'Information',
    object: 'CUser',
    tKey: 'mTitle_information',
    objectIdentifier: 'mail',
    removable: false,
    newable: false,
    defaultSortBy: 'firstname',
    defaultSortDirection: 'ASC',
    category: {
        path: 'myBinder',
        icon: 'folder'
    },
    viewMap: {
        dt: [
            'firstname', 'lastname', 'remainingAmount'
        ],
        form: [
            {path: 'civility', required: true},
            {path: 'lastname', required: true, uppercase: true},
            {path: 'firstname', required: true},
            {path: 'birthDate', required: true},
            {path: 'mail', required: true, lowercase: true},
            {path: 'language', tKey: 'correspondenceLanguage', required: true},
            {path: 'phone', type: 'phoneNumber'},
            {path: 'split', disabled: true},
            {path: 'registrationNumber', editable: false},
            {
                path: 'rightHolders',
                defaultSortBy: 'firstname',
                defaultSortDirection: 'ASC',
                removable: false,
                viewMap: {
                    dt: ['firstname', 'lastname'],
                    form: [
                        {path: 'kinship', required: true},
                        {path: 'lastname', required: true, uppercase: true},
                        {path: 'firstname', required: true},
                        {path: 'birthDate', type: 'datePicker', required: false,},
                        {path: 'dependent', default: false}
                    ]
                }
            },
            {path: 'address', required: true},
            {path: 'bank', required: true},
            {path: 'accountOwner', required: true},
            {path: 'iban', required: true, noSpaces: true, maxLength34: true, bankData: true},
            {path: 'bic', required: true, noSpaces: true, lengthEquals8or11: true, bankData: true},
            {path: 'files', tKey: 'documents', editable: false},
            {path: 'comments', textWidth: 1000, editable: false},
            {path: 'declaration1', tKey: 'declaration1_abbrev', default : false, disabled: true},
            {path: 'declaration2', tKey: 'declaration2_abbrev', default : false, disabled: true},
            {path: 'maximumAmount', hidden: true}
        ]
    },
    filters: [
        'byUser'
    ],
    actionSubscriptions: [
        {
            actionType: GET_OBJECT_SUCCESS,
            subscription: ({ store, t }) => {
                const state = store.getState()

                const maximumAmount = _.get(state, 'edit.object.data.maximumAmount')

                const maximumNotUndefined = !!maximumAmount && maximumAmount !== 'undefined'

                store.dispatch(changeFieldProperty(
                    'e_declaration2',
                    'tKey',
                    maximumNotUndefined
                        ? t( 'declaration2_user', {max: maximumAmount})
                        : 'declaration2_abbrev'
                ))
            }
        }
    ],
}
