import async from "async";
const _ = require("lodash")
const moment = require("moment")
const {encrypt, decrypt} = require("../../utils/crypto")
const Errors = require("../../utils/Errors").default
const {setFormButtons, setFieldEdition, setFieldVisibility, setFieldRemovable, setFieldNewable} = require("../../../apps/KpModule/actions")
const {getObjectMode, getUser} = require("../../../apps/KpModule/selectors")
const {validateEmail} = require("../../../apps/KpModule/components/Form/formValidations")

export const entities = () =>[
    {
        name: "TextLanguage",
        type: "static",
        facets: ["translatedField"],
        fields: [
            {path: "id", type: "string"},
            {path: "name", type: "string"},
        ],
        filters: [
            {
                name: 'ByClient',
                isDefault: true,
                match: function (object) {
                    return global.config.languages.includes(object.id)
                }
            }
        ],
        objects: [
            {id: "en", name: 'english'},
            {id: "es", name: 'español'},
            {id: "fr", name: 'français'},
            {id: "it", name: "italiano"},
            {id: "nl", name: "nederlands"}
        ]
    },
    {
        name: "TextMessage",
        facets: [{name: "files"}],
        fields: [
            {path: "language", type: "TextLanguage"},
            {path: "message"},
        ]
    },
    {
        name: "TextingRecipient",
        type: "mongoInternal",
        fields: [
            {path: "phoneNumbers"},
            {path: "language", type: "TextLanguage"},
        ]
    },
    {
        name: "TextingPlatform",
        fields: [
            {path: "title", unique: true},
            'senderName',
            "description",
            {path: "broadcastList", type: "BroadcastListPlatform", nullable: true},
            {path: 'createdBy', type: 'User', nullable: true},
            {path: "recipients", type: "User", link: "MTM"},
            {path: "otherRecipients", type: "TextingRecipient", link: "OTM"
                //{type: "OTM", onParent: true, onChild: false}
            },
            {path: "numberOfRecipients", fieldPath: ["recipients", "otherRecipients"], type: 'integer', $f: (object, context, callback) => {
                    callback(
                        null,
                        _.concat(
                            object.recipients,
                            object.otherRecipients ?
                                _.flatMap(object.otherRecipients, line => line.phoneNumbers.split(';'))
                                : []
                        ).length
                    )
                }},
            {path: 'messages', type: 'TextMessage', link: 'OTM'},
            {path: 'status'},
            {path: 'sendDate'},
            {
                path: "noDeleteButtonAccess",
                fieldPath: ['createdBy.id'],
                $f: function (message, context, callback) {
                    const isMessageCreator = _.get(message, 'createdBy.id') === _.get(context, 'user.id')
                    callback(null, !isMessageCreator)
                }
            },
            {
                path: "sender",
                fieldPath: ['createdBy.mail'],
                $f: function (message, context, callback) {
                    const senderMail = _.get(message, 'createdBy.mail')
                    callback(null, senderMail && decrypt(senderMail))
                }
            }
        ],
        correctEmailFormat: function(object, oldObject, context, callback) {
            let wrongEmail
            const otherRecipients = _.flatMap(object.otherRecipients, line => line.phoneNumbers.split(';'))

            const duplicates = otherRecipients.some(
                recipient => {
                    const isValid = validateEmail(recipient)
                    if(!isValid) {
                        wrongEmail = recipient
                    }
                    return !isValid
                }
            )

            if(duplicates) return callback(new Errors.ValidationError(context.tc('incorrectEmailFormat', {mail: wrongEmail})))
            return callback()
        },
        uniqueRecipients: function(object, oldObject, context, callback) {
            let duplicatePhone
            const recipients = object.recipients.map(user => ({phone: user.phone && decrypt(user.phone)}))
            const otherRecipients = _.flatMap(object.otherRecipients, line => line.phoneNumbers.split(';').map(phone => ({phone, language: line.language.id})))
            const duplicates = otherRecipients.some(
                otherRecipient => recipients.some(
                    recipient => {
                        const isDuplicate = recipient.phone === otherRecipient.phone
                        if(isDuplicate) {
                            duplicatePhone = recipient.phone
                        }
                        return isDuplicate
                    }
                )
            )
            if(duplicates) return callback(new Errors.ValidationError(context.tc('duplicateUser', {mail: duplicatePhone})))
            return callback()
        },
        uniqueOtherRecipients: function(object, oldObject, context, callback) {
            let duplicatePhone
            const otherRecipients = _.flatMap(object.otherRecipients, line => line.phoneNumbers.split(';'))

            const duplicates = otherRecipients.some(
                (recipient, index) => {
                    const isDuplicate = otherRecipients.indexOf(recipient) !== index
                    if(isDuplicate) {
                        duplicatePhone = recipient
                    }
                    return isDuplicate
                }
            )

            if(duplicates) return callback(new Errors.ValidationError(context.tc('duplicateOtherRecipient', {mail: duplicatePhone})))
            return callback()
        },
        uniqueMessageByLanguage: function(object, oldObject, context, callback) {
            let duplicateLanguage

            const messagesLanguages = object.messages.map(message => _.get(message, 'language.name'))

            const duplicates = messagesLanguages.some(
                (language, index) => {
                    const isDuplicate = messagesLanguages.indexOf(language) !== index
                    if(isDuplicate) {
                        duplicateLanguage = language
                    }
                    return isDuplicate
                }
            )

            if(duplicates) return callback(new Errors.ValidationError(context.tc('duplicateMessageLanguage', {language: duplicateLanguage})))
            return callback()
        },
        requiredEmailLanguages: function(object, oldObject, context, callback) {
            let missingLanguage

            const recipients = object.otherRecipients
                ? _.concat(
                    object.recipients,
                    _.flatMap(object.otherRecipients, line => line.phoneNumbers.split(';').map(phone => ({phone, language: line.language})))
                )
                : object.recipients

            const isMissingLanguage = recipients.some(
                recipient => {
                    const isLanguagePresent = object.messages.some(message => message.language.id === recipient.language.id)
                    if(!isLanguagePresent) {
                        missingLanguage = recipient.language.name
                    }
                    return !isLanguagePresent
                }
            )
            if(isMissingLanguage) {
                return callback(new Errors.ValidationError(context.tc('emailIsMissingLanguage', {language: missingLanguage})))
            }
            return callback()
        },
        validateSave: function(object, oldObject, context, callback) {
            async.series([
                //callback => this.correctEmailFormat(object, oldObject, context, callback),
                callback => this.uniqueRecipients(object, oldObject, context, callback),
                callback => this.uniqueOtherRecipients(object, oldObject, context, callback),
                callback => this.requiredEmailLanguages(object, oldObject, context, callback),
                callback => this.uniqueMessageByLanguage(object, oldObject, context, callback)
            ], callback)
        },
        beforeSave: (object, oldObject, context, callback) => {
            const action  = context.restAction && context.restAction.crudType

            if(action === 'C') {
                object.createdBy = {
                    id: _.get(context, 'user.id'),
                    name: _.get(context, 'user.name'),
                    mail: encrypt(_.get(context, 'user.mail'))
                }
            }
            if (context.action === "validate") {

                const userName = _.get(object, 'createdBy.name')

                const messages = _.uniq(
                    object.otherRecipients
                        ? _.concat(
                            object.recipients.map(user => ({
                                phone: user.phone && decrypt(user.phone),
                                language: user.language
                            })),
                            _.flatMap(object.otherRecipients, line => line.phoneNumbers.split(';').map(phone => ({phone, language: line.language})))
                        )
                        : object.recipients.map(user => ({
                            phone: user.phone && decrypt(user.phone),
                            language: user.language
                        })),
                ).map(user => {
                    let messageElements
                    if(user.language) messageElements = object.messages.find(message => message.language.id === (user.language.id))
                    if(!messageElements) messageElements = object.messages.find(message => message.language.id === 'fr')
                    if(!messageElements) messageElements = object.messages[0]

                    return {
                        to: user.phone,
                        body: messageElements.message
                    }
                })

                async.parallel(messages.map(message => callback => {
                    global.sms.sendMailJetMessage(object.senderName || userName, message.to, message.body)
                        .then(() => callback())
                        .catch(e => console.log(e))
                }), () => {
                    object.status = 'sent'
                    object.sendDate = moment().format('YYYY-MM-DD HH:mm:ss')
                    return callback(null, object, oldObject)
                })

            } else {
                object.status = 'draft'
                callback(null, object, oldObject)
            }
        }
    }
]

export const modules = () =>[
    {
        tKey: "m-T-Texting",
        object: "TextingPlatform",
        defaultSortBy: 'sendDate',
        objectIdentifier: 'title',
        defaultSortDirection: 'DESC',
        category: {
            path: 'communication',
            icon: 'messageCircle'
        },
        viewMap: {
            dt: [
                "title",
                //{path: "numberOfRecipients"},
                {path: 'status', translate: true, width: 70},
                {path: 'sender'},
                {path: 'numberOfRecipients', tKey: 'recipients'},
                {path: 'sendDate', tKey: "dateAndTime", width: 70},
                {path: "noDeleteButtonAccess", hidden: true}
            ],
            form: {
                fields: [
                    {path: "title", required: true},
                    {path: "description", type: "textarea"},
                    {path: 'sender'},
                    {path: 'senderName'},
                    {
                        path: "broadcastList",
                        fieldPath: ['id', 'name', 'profiles.id'],
                        subscriptions: {
                            onChange: (newValue, oldValue, { module, store, formInitialize, getObjectSuccessAction }) => {
                                if(!formInitialize && !getObjectSuccessAction) {
                                    const statusField = module.viewMap.form.fields.find(
                                        field => field.path === 'status'
                                    )

                                    if(statusField.getValue() === 'sent') return

                                    const state = store.getState()
                                    const recipientsField = module.viewMap.form.fields.find(
                                        field => field.path === 'recipients'
                                    )

                                    const list = state.dataLists.byId[`m-${module.model}-textingPlatform.TextingPlatform_recipients`].list
                                    recipientsField.setValue(
                                        newValue && newValue.id
                                            ? list.allIds
                                                .filter(id => list.byId[id].groupModelProfiles.some(profileObject => {
                                                    return newValue.profiles.some(profile => profile.id === profileObject.id)
                                                }))
                                                .map(id => list.byId[id])
                                            : []
                                    )
                                }
                            }
                        }
                    },
                    {
                        path: "recipients",
                        tKey: "recipient(s)",
                        type: 'dualBoxList',
                        display: 'completeInfo',
                        fieldPath: ['id', 'language.id', 'mail', 'name', 'groupModelProfiles', 'completeInfo'],
                        filters: ['isActive', 'isInGroupModel', 'userKNotAllowed']
                    },
                    {
                        path: "otherRecipients",
                        tKey: "otherRecipient(s)",
                        defaultSortBy: "language",
                        defaultSortDirection: "ASC",
                        removable: true,
                        viewMap: {
                            dt: [
                                'language',
                                {path: 'phoneNumbers'},
                            ],
                            form: [
                                {path: 'language', fieldPath: ['id', 'name'], required: true},
                                {path: 'phoneNumbers', type: 'creatableTags', placeholder: 'typePhoneNumberAndPressEnter', required: true},
                            ]
                        }
                    },
                    {
                        path: "messages",
                        removable: true,
                        required: true,
                        defaultSortBy: 'language',
                        defaultSortDirection: 'ASC',
                        viewMap: {
                            dt: ["language"],
                            form: [
                                {path: "language", display: 'name', required: true},
                                {path: "message", type: 'textarea', required: true}
                            ]
                        }
                    },
                    {path: "status", hidden: true},
                    {path: "createdBy", hidden: true},
                ],
                onOpen: ({ store }) => {
                    const state = store.getState()
                    const user = getUser(state)
                    const objectMode = getObjectMode(state)
                    const status = _.get(state, 'edit.object.data.status')
                    const isOwner = _.get(state, 'edit.object.data.createdBy.id') === user.id
                    const isOwnerCondition = objectMode === 'newObject' || isOwner
                    const objectIsEditable =  isOwnerCondition && status !== 'sent'

                    store.dispatch(setFormButtons(
                        objectIsEditable
                            ? [
                                {type: "action", tKey: 'send', bsStyle: 'primary', action: 'validate'},
                                {type: "save", tKey: 'save', bsStyle: 'success'},
                                {type: "return", tKey: 'return', bsStyle: 'default'}
                            ]: [
                                {type: "return", tKey: 'return', bsStyle: 'default'}
                            ]
                    ))
                    store.dispatch(setFieldVisibility(`e_sender`, objectMode !== 'newObject'))

                    const editFields = ['recipients', 'title', 'senderName', 'description', 'otherRecipients', 'messages']
                    editFields.forEach(field => {
                        store.dispatch(setFieldEdition(`e_${field}`, objectIsEditable))
                    })
                    store.dispatch(setFieldVisibility(`e_broadcastList`, objectIsEditable))
                    store.dispatch(setFieldRemovable(`e_otherRecipients`, objectIsEditable))
                    store.dispatch(setFieldRemovable(`e_messages`, objectIsEditable))
                    store.dispatch(setFieldNewable(`e_otherRecipients`, objectIsEditable))
                    store.dispatch(setFieldNewable(`e_messages`, objectIsEditable))
                }
            }
        }
    }
]

export const jobs = () => []
