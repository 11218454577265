import React from 'react'
import PropTypes from 'prop-types'
import CreatableSelect from 'react-select/creatable'
import ValidationUI from '../ValidationUI'
import './Select.css'

const getWidthClass = width => {
    if(width) return `col-xs-12 col-sm-12 col-md-${width} col-lg-${width}`
    else return 'col-xs-12 col-sm-12 col-md-12 col-lg-12'
}
const Creatable = (props) => {
    return (
        <div className={`Select-Container Creatable ${getWidthClass(props.width)}`}>
            <label className="Creatable-label" >{props.t(props.path)}</label>
            <CreatableSelect {...props} placeholder={props.t(props.placeholder || 'select', { capitalize: true })} onBlur={event => event.preventDefault()} />
            { props.touched && (props.error && <ValidationUI error={props.error}/>) }
            { props.getPreview && <div style={{marginTop: "5px"}}>{props.getPreview(props.value, props.t)}</div> }
        </div>
    )
}

Creatable.propTypes = {
    value: PropTypes.any,
    isMulti: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
}

export default Creatable
