const _ = require('lodash')
const moment = require('moment')
const { basicContext } = require("../../../utils/contextUtils")
const { validateAge } = require("../utils")
const { generateExcel } = require("./generateExcel")

export const generateConsumptionsReport = async (object, oldObject, context, callback) => {
    object.archive = false
    object.emailing = false

    const exerciseStartDate = object.exercise.startDate
    const exerciseEndDate = object.exercise.endDate

    const users = await global.app.R.CUser.find({
        ...basicContext(context),
        fieldPath: ['civility.id', 'rightHolders.id'],
        query: {status: {$ne: 'external'}}
    })

    object.beneficiariesNumber = users.length

    const exercisesIds = [
        object.exercise.id,
        ...(object.comparisonExercise ? [object.comparisonExercise.id] : [])
    ];

    const exerciseBenefits = await global.app.R.Benefit.find({
        //fieldPath: ['exercise.code'],
        ...basicContext(context),
        query: {exercise: {$in: exercisesIds.map(id => new global.ObjectID(id))}}

    })

    const currentExerciseBenefits = exerciseBenefits.filter(benefit => benefit.exercise.code === object.exercise.code)

    const previousExerciseBenefits = object.comparisonExercise ? exerciseBenefits.filter(benefit => benefit.exercise.code === object.comparisonExercise.code) : []


    const refunds = await global.app.R.Refund.find({
        ...basicContext(context),
        fieldPath: ['refund', 'status', 'user.id', 'benefit.exercise.id'],
        query: {
            user: {$in: users.map(user => new global.ObjectID(user.id))},
            benefit: {$in: exerciseBenefits.map(o => new global.ObjectID(o.id))},
            status: { $in: ['validated', 'waiting', 'actualized', 'paid'] }

        }
    })

    const refundsByExercise = refunds.reduce((acc, refund) => {
        const exerciseId = _.get(refund, 'benefit.exercise.id')
        const userId = _.get(refund, 'user.id')

        const refundsByExercise = acc[exerciseId] = acc[exerciseId] || {};
        const refundsByUser = refundsByExercise[userId] = refundsByExercise[userId] || [];

        refundsByUser.push(refund)
        return acc
    }, {})

    const endowmentHistory = await global.app.R.EndowmentHistory.find({
        ...basicContext(context),
        fieldPath: ['user.id', 'exercise.id', 'exerciseLimit'],
        query: {
            exercise: {$in: exercisesIds.map(id => new global.ObjectID(id))},
        }

    })

    const endowmentHistoryByExercise = endowmentHistory.reduce((acc, endowment) => {
        const exerciseId = _.get(endowment, 'exercise.id')
        const userId = _.get(endowment, 'user.id')

        const endowmentsByExercise = acc[exerciseId] = acc[exerciseId] || {};
        const endowmentsByUser = endowmentsByExercise[userId] = endowmentsByExercise[userId] || [];

        endowmentsByUser.push(endowment)
        return acc
    }, {})

    const data = users.map(user => {

        const userEndowmentHistory = endowmentHistoryByExercise[object.exercise.id]
            && endowmentHistoryByExercise[object.exercise.id][user.id]
            && endowmentHistoryByExercise[object.exercise.id][user.id][0]

        const entryDate = user.entryDate && new Date(user.entryDate)
        const releaseDate = user.releaseDate && new Date(user.releaseDate)

        if(releaseDate) {
            releaseDate.setDate( releaseDate.getDate() - 1)
        }

        const start = entryDate
            ? entryDate < exerciseStartDate ? exerciseStartDate : entryDate
            : exerciseStartDate

        const end = !releaseDate || user.status === 'retired'
            ? exerciseEndDate
            : releaseDate < exerciseEndDate
                ? releaseDate
                : exerciseEndDate

        const monthsDiff = (date1, date2) => {
            const years = date2.getFullYear() - date1.getFullYear()
            return (years * 12) + (date2.getMonth() - date1.getMonth() + 1) ;
        }

        const presenceRatio = (entryDate && entryDate > exerciseEndDate) || (releaseDate && releaseDate < exerciseStartDate)
            ? 0
            : monthsDiff(start, end) / monthsDiff(exerciseStartDate, exerciseEndDate)

        const userSplit = user['split'] || 0

        const getProratedValue = value => ((value * (100 - userSplit)) / 100) * presenceRatio

        const isExerciseOngoing = _.get(object, 'exercise.exerciseStatus.id') === 'ongoing'
        const isExerciseClosed = _.get(object, 'exercise.exerciseStatus.id') === 'finalClosure'
        const childrenNumber = user.rightHolders
            .filter(rh => rh.kinship === 'child' && rh.dependent)
            .filter( rh => validateAge(rh.birthDate, exerciseStartDate, 25))
            .length

        const exerciseVariableDot = parseFloat(object.exercise.variableDot)
        const exerciseFixedDot = parseFloat(object.exercise.fixedDot)


        const limitForTheExerciseBeforeSplit = user.status === 'external'
            ? 0
            : childrenNumber * exerciseVariableDot + exerciseFixedDot

        const limitForTheExercise = getProratedValue(limitForTheExerciseBeforeSplit)

        const userRefundRequests = refundsByExercise[object.exercise.id]
            ? refundsByExercise[object.exercise.id][user.id] || []
            : []

        const totalExerciseRefund = userRefundRequests
            .reduce((acc, refundRequest) => acc + (parseFloat(refundRequest.refund) || 0), 0)


        const totalEndowment = isExerciseOngoing
            ? _.round(limitForTheExercise, 2)
            : isExerciseClosed && userEndowmentHistory
                ? userEndowmentHistory.exerciseLimit
                : '---'

        const endowmentBalance = isExerciseOngoing
            ?  _.round(limitForTheExercise - totalExerciseRefund, 2)
            : isExerciseClosed && userEndowmentHistory
                ? _.round(userEndowmentHistory.exerciseLimit - totalExerciseRefund, 2)
                : '---'

        const endowmentByBenefit = currentExerciseBenefits
            .reduce((acc, benefit) => {

                const benefitRefundRequests = userRefundRequests.filter(refund => {
                    return refund.benefit.id === benefit.id
                })

                const totalBenefitRefund = benefitRefundRequests
                    .reduce((acc, refundRequest) => acc + (parseFloat(refundRequest.refund) || 0), 0)

                const consumptionKey = `Conso. ${benefit.code}`
                const consumptionPercentageKey = `% Conso. ${benefit.code}`
                return {
                    ...acc,
                    [consumptionKey] : _.round(totalBenefitRefund, 2),
                    [consumptionPercentageKey] : totalExerciseRefund === 0
                        ? 0
                        :_.round((totalBenefitRefund/ totalExerciseRefund) * 100, 2)
                }
            }, {})

        const previousExerciseAndVarData = {}

        const averageRefund = userRefundRequests.length ? _.round(totalExerciseRefund / userRefundRequests.length, 2) : 0

        if(object.comparisonExercise) {
            const isPreviousExerciseClosed = _.get(object, 'comparisonExercise.exerciseStatus.id') === 'finalClosure'

            const userPreviousRefundRequests =  refundsByExercise[object.comparisonExercise.id]
                ? refundsByExercise[object.comparisonExercise.id][user.id] || []
                : []

            const totalPreviousExerciseRefund = userPreviousRefundRequests
                .reduce((acc, refundRequest) => acc + (parseFloat(refundRequest.refund) || 0), 0)

            const userPreviousEndowmentHistory = endowmentHistoryByExercise[object.comparisonExercise.id]
                && endowmentHistoryByExercise[object.comparisonExercise.id][user.id]
                && endowmentHistoryByExercise[object.comparisonExercise.id][user.id][0]

            const previousTotalEndowment = isPreviousExerciseClosed && userPreviousEndowmentHistory
                ? userPreviousEndowmentHistory.exerciseLimit
                : '---'

            const previousEndowmentBalance = isPreviousExerciseClosed && userPreviousEndowmentHistory
                ? _.round(userPreviousEndowmentHistory.exerciseLimit - totalPreviousExerciseRefund, 2)
                : '---'

            const previousAverageRefund = userPreviousRefundRequests.length ? _.round(totalPreviousExerciseRefund / userPreviousRefundRequests.length, 2) : 0

            previousExerciseAndVarData.previousTotalEndowment = previousTotalEndowment === '---' ?  previousTotalEndowment : previousTotalEndowment
            previousExerciseAndVarData.previousConsumption = _.round(totalPreviousExerciseRefund, 2)
            previousExerciseAndVarData.previousEndowmentBalance = previousEndowmentBalance
            previousExerciseAndVarData.previousRequestsNb = userPreviousRefundRequests.length
            previousExerciseAndVarData.previousConsumptionPercentage = previousTotalEndowment === '---' || previousEndowmentBalance === '---' || !previousTotalEndowment
                ? '---'
                : _.round((totalPreviousExerciseRefund/previousTotalEndowment)*100, 2)
            previousExerciseAndVarData.totalEndowmentVar = totalEndowment === '---' || previousTotalEndowment === '---'
                ? '---'
                : (totalEndowment - previousTotalEndowment)
            previousExerciseAndVarData.consumptionVar = _.round(totalExerciseRefund - totalPreviousExerciseRefund, 2)

            previousExerciseAndVarData.requestsNbVar = userRefundRequests.length - userPreviousRefundRequests.length

            previousExerciseAndVarData.previousOverconsumption = previousEndowmentBalance === '---'
                ? '---'
                :previousEndowmentBalance >= 0 ? 0 : previousEndowmentBalance
            previousExerciseAndVarData.previousUnderconsumption = previousEndowmentBalance === '---'
                ? '---'
                :previousEndowmentBalance >= 0 ? previousEndowmentBalance : 0

            previousExerciseAndVarData.previousAverageRefund = previousAverageRefund
            previousExerciseAndVarData.averageRefundVar = (averageRefund - previousAverageRefund)

            previousExerciseBenefits
                .forEach(benefit => {

                    const benefitRefundRequests = userPreviousRefundRequests.filter(refund => {
                        return refund.benefit.id === benefit.id
                    })

                    const totalBenefitRefund = benefitRefundRequests
                        .reduce((acc, refundRequest) => acc + (parseFloat(refundRequest.refund) || 0), 0)

                    const consumptionKey = `Conso. ${benefit.code}`
                    const consumptionPercentageKey = `% Conso. ${benefit.code}`

                    previousExerciseAndVarData[consumptionKey] = _.round(totalBenefitRefund, 2)
                    previousExerciseAndVarData[consumptionPercentageKey] = totalPreviousExerciseRefund === 0
                        ? 0
                        : _.round((totalBenefitRefund/ totalPreviousExerciseRefund)*100, 2)
                })

        }

        return {
            civility: context.tc(user.civility.id),
            lastname: user.lastname,
            firstname: user.firstname,
            registrationNumber: user.registrationNumber,
            status: context.tc(user.status),
            entryDate: user.entryDate && moment(user.entryDate).format('DD/MM/YYYY'),
            releaseDate: user.releaseDate && moment(user.releaseDate).format('DD/MM/YYYY'),
            totalEndowment: totalEndowment === '---' ?  totalEndowment : totalEndowment,
            consumption: _.round(totalExerciseRefund, 2),
            endowmentBalance: endowmentBalance,
            overconsumption: endowmentBalance === '---'
                ? '---'
                :endowmentBalance >= 0 ? 0 : endowmentBalance,
            underconsumption: endowmentBalance === '---'
                ? '---'
                :endowmentBalance >= 0 ? endowmentBalance : 0,
            consumptionPercentage: totalEndowment === '---' || endowmentBalance === '---' || !totalEndowment
                ? '---'
                : _.round((totalExerciseRefund/totalEndowment)*100, 2),
            requestsNb: userRefundRequests.length,
            averageRefund: averageRefund,
            ...endowmentByBenefit,
            ...previousExerciseAndVarData
        }
    })

    const fileName = `${moment(object.date).format("YYYY-MM-DD_HH-mm-ss")}_${object.exercise.code}_Consommations`

    const currentBenefitsFieldPaths = currentExerciseBenefits
        .sort(function(a, b) {
            return _.get(a, 'code') - _.get(b, 'code')
        })
        .reduce((acc, benefit) =>  {

            const consumptionKey = `Conso. ${benefit.code}`
            const consumptionPercentageKey = `% Conso. ${benefit.code}`

            return [...acc, consumptionKey, consumptionPercentageKey]
        }, [])

    const previousBenefitsFieldPaths = previousExerciseBenefits
        .sort(function(a, b) {
            return _.get(a, 'code') - _.get(b, 'code')
        })
        .reduce((acc, benefit) =>  {

            const consumptionKey = `Conso. ${benefit.code}`
            const consumptionPercentageKey = `% Conso. ${benefit.code}`

            return [...acc, consumptionKey, consumptionPercentageKey]
        }, [])

    const benefitsInitialValues = [...currentBenefitsFieldPaths, ...previousBenefitsFieldPaths]
        .reduce((acc, key) =>  {

            return {...acc, [key]: 0}
        }, {})

    const dataTotal = data.reduce((acc, dataLine) => {
        const requestsNbInt = acc.requestsNb + dataLine.requestsNb
        const totalEndowmentInt = dataLine.totalEndowment === '---'
            ? parseFloat(acc.totalEndowment)
            : parseFloat(acc.totalEndowment) + parseFloat(dataLine.totalEndowment)
        const consumptionInt = acc.consumption + dataLine.consumption
        const consumptionPerInt = parseFloat(dataLine.totalEndowment) === 0 || dataLine.totalEndowment === '---' || dataLine.endowmentBalance === '---'
            ?   acc.consumptionPercentage
            : (consumptionInt/totalEndowmentInt)*100

        const endowmentBalance = dataLine.endowmentBalance === '---'
            ? acc.endowmentBalance
            : acc.endowmentBalance + dataLine.endowmentBalance

        const overconsumptionInt =  dataLine.overconsumption === '---'
            ? acc.overconsumption
            : acc.overconsumption + dataLine.overconsumption

        const underconsumptionInt = dataLine.underconsumption === '---'
            ? acc.underconsumption
            : acc.underconsumption + dataLine.underconsumption

        const averageRefundInt = requestsNbInt === 0
            ? 0
            : consumptionInt / requestsNbInt

        const previousExerciseTotal = {}

        if(object.comparisonExercise) {
            const previousRequestsNbInt = acc.previousRequestsNb + dataLine.previousRequestsNb
            const previousTotalEndowmentInt = dataLine.previousTotalEndowment === '---'
                ? parseFloat(acc.previousTotalEndowment)
                : parseFloat(acc.previousTotalEndowment) + parseFloat(dataLine.previousTotalEndowment)

            const previousConsumptionInt = dataLine.previousConsumption === '---'
                ? acc.previousConsumption
                : acc.previousConsumption + dataLine.previousConsumption

            const previousEndowmentBalance = dataLine.previousEndowmentBalance === '---'
                ? acc.previousEndowmentBalance
                : acc.previousEndowmentBalance + dataLine.previousEndowmentBalance

            const previousConsumptionPerInt = parseFloat(dataLine.previousTotalEndowment) === 0 || dataLine.previousTotalEndowment === '---' || dataLine.previousEndowmentBalance === '---'
                ?   parseFloat(acc.previousConsumptionPercentage)
                : (previousConsumptionInt/previousTotalEndowmentInt)*100

            const previousOverconsumptionInt = dataLine.previousOverconsumption === '---'
                ? acc.previousOverconsumption
                : acc.previousOverconsumption + dataLine.previousOverconsumption

            const previousUnderconsumptionInt = dataLine.previousOverconsumption === '---'
                ? acc.previousUnderconsumption
                : acc.previousUnderconsumption + dataLine.previousUnderconsumption

            const previousAverageRefundInt = previousRequestsNbInt === 0
                ? 0
                : previousConsumptionInt / previousRequestsNbInt

            const totalEndowmentVarInt = totalEndowmentInt - previousTotalEndowmentInt
            const consumptionVar = consumptionInt - previousConsumptionInt
            const averageRefundVarInt = averageRefundInt - previousAverageRefundInt

            previousExerciseTotal.previousTotalEndowment = previousTotalEndowmentInt
            previousExerciseTotal.previousConsumption = previousConsumptionInt
            previousExerciseTotal.previousEndowmentBalance = previousEndowmentBalance
            previousExerciseTotal.previousRequestsNb = previousRequestsNbInt
            previousExerciseTotal.previousConsumptionPercentage = _.round(previousConsumptionPerInt, 2)
            previousExerciseTotal.previousOverconsumption = _.round(previousOverconsumptionInt, 2)
            previousExerciseTotal.previousUnderconsumption = _.round(previousUnderconsumptionInt, 2)
            previousExerciseTotal.previousAverageRefund = _.round(previousAverageRefundInt, 2)

            previousExerciseTotal.totalEndowmentVar = totalEndowmentVarInt
            previousExerciseTotal.consumptionVar = consumptionVar
            previousExerciseTotal.requestsNbVar = acc.requestsNbVar + dataLine.requestsNbVar
            previousExerciseTotal.averageRefundVar = _.round(averageRefundVarInt, 2)

            previousBenefitsFieldPaths.forEach(key => {
                const isPercentage = key.split(' ')[0] === '%'
                if(isPercentage) {
                    const keys = key.split(' ')
                    keys.shift()
                    const benefitKey = keys.join(' ')

                    previousExerciseTotal[key] = previousConsumptionInt === 0
                        ? 0
                        : _.round(((acc[benefitKey] + dataLine[benefitKey])/previousConsumptionInt)*100, 2)
                } else previousExerciseTotal[key] = acc[key] + dataLine[key]
            })


        }
        return currentBenefitsFieldPaths.reduce((acc, key) => {
            const isPercentage = key.split(' ')[0] === '%'
            if(isPercentage) {
                const keys = key.split(' ')
                keys.shift()
                const benefitKey = keys.join(' ')
                return {
                    ...acc,
                    [key]: consumptionInt === 0
                        ? 0
                        : _.round(((acc[benefitKey] + dataLine[benefitKey])/consumptionInt)*100, 2)
                }
            }
            return {
                ...acc,
                [key]: acc[key] + dataLine[key]
            }
        }, {
            ...acc,
            totalEndowment: totalEndowmentInt,
            consumption: consumptionInt,
            endowmentBalance: endowmentBalance,
            consumptionPercentage: _.round(consumptionPerInt, 2),
            overconsumption: _.round(overconsumptionInt, 2),
            underconsumption: _.round(underconsumptionInt, 2),
            requestsNb: requestsNbInt,
            averageRefund: _.round(averageRefundInt, 2),
            ...previousExerciseTotal
        })

    }, {civility: 'Total', totalEndowment: 0, consumption: 0, requestsNb: 0, consumptionPercentage: 0, endowmentBalance: 0, overconsumption: 0, underconsumption: 0, averageRefund: 0,
        previousTotalEndowment: 0, previousConsumption: 0, previousConsumptionPercentage: 0, previousEndowmentBalance: 0, previousRequestsNb: 0, previousOverconsumption: 0,
        previousUnderconsumption: 0, previousAverageRefund: 0, totalEndowmentVar: 0, consumptionVar: 0, requestsNbVar: 0, averageRefundVar: 0, ...benefitsInitialValues})


    const fieldPaths = object.comparisonExercise
        ? ['civility', 'lastname', 'firstname', 'registrationNumber', 'status', 'entryDate', 'releaseDate', 'totalEndowment', 'previousTotalEndowment', 'totalEndowmentVar','consumption', 'previousConsumption','consumptionVar', 'consumptionPercentage', 'previousConsumptionPercentage', 'endowmentBalance', 'previousEndowmentBalance', 'overconsumption', 'previousOverconsumption', 'underconsumption', 'previousUnderconsumption', 'requestsNb', 'previousRequestsNb', 'requestsNbVar', 'averageRefund', 'previousAverageRefund', 'averageRefundVar', ...currentBenefitsFieldPaths, ...previousBenefitsFieldPaths]
        : ['civility', 'lastname', 'firstname', 'registrationNumber', 'status', 'entryDate', 'releaseDate', 'totalEndowment', 'consumption', 'consumptionPercentage', 'endowmentBalance', 'overconsumption', 'underconsumption', 'requestsNb', 'averageRefund', ...currentBenefitsFieldPaths]

    await generateExcel(fileName, object, data, dataTotal, fieldPaths, context)
        .then(file => {
            object.file = {
                ...file,
                user: _.get(context, "user.name", "unknown user"),
                date: moment().format("YYYY-MM-DD HH:mm")
            }
            callback(null, object, oldObject)
        })
        .catch(err => callback(err))
}
