const _ = require('lodash')
const moment = require('moment')
const { refundTemplatesDirectoryPath} = require('./utils')
const { decrypt } = require('../../../utils/crypto')

const getQueryFromStatus = object => {
    switch (object.status) {
        case 'ongoing':
            return {'authorizations.code': '000002' }
        case 'controlled':
            return {'authorizations.code': '000003' }
        case 'rectification':
        case 'validated':
        case 'refused':
            return {_id: new global.ObjectID(object.user.id)}
        case 'justification':
            return {_id: new global.ObjectID(object.controller.id)}
        default:
            return {_id: null}
    }
}

export async function workflowMailing(refundObject, files, context) {
    const cUsers = await global.app.R.CUser.collection
        .aggregate([
            { '$lookup': {
                    'from': 'profile',
                    'let': { 'authId': '$authorizations' },
                    'pipeline': [
                        { '$match': { '$expr': { '$in': ['$_id', '$$authId'] } } }
                    ],
                    'as':'authorizations'
                }
            },
            {
                $match: getQueryFromStatus(refundObject)
            },
        ])
        .toArray()

    const exerciseStatus = _.get(refundObject, 'benefit.exercise.exerciseStatus.id')
        || _.get(refundObject, 'benefit.exercise.exerciseStatus')

    if (cUsers.length !== 0 && refundObject.status !== 'draft' && exerciseStatus === 'ongoing') {

        const attachments = files.map(file => {
            return {
                id: new global.ObjectID(file.id),
                filename: file.filename
            }
        })

        const subjectUser = ['validated', 'refused', 'rectification'].includes(refundObject.status)
            ? 'myCSEManCo'
            : refundObject.userFullName

        sendMail({
            users: cUsers,
            translate: context.tl,
            subjectUser: subjectUser,
            status: refundObject.status,
            refund: refundObject.refund,
            object: refundObject,
            template: `${refundObject.status}Refund.html`,
            url: global.isProd
                ? `https://${context.host}/business/${context.groupModel.id}`
                : `http://localhost:3000/business/${context.groupModel.id}`,
            attachments
        })
    }
}

const sendMail = ({ users, translate, subjectUser, status, object, refund, template, url, attachments }) => {
    const logMessage = () => {
        console.log('...Mailing')
    }
    users.forEach(user => {
        const language = user.language || 'fr'
        const mail = _.defaults(
            {
                to: user.mail && decrypt(user.mail),
                context: {
                    user: user,
                    object,
                    beneficiaries: object.beneficiaries.map(beneficiary => ({
                        firstname: decrypt(beneficiary.firstname),
                        lastname: decrypt(beneficiary.lastname)
                    })),
                    date: moment(object.issued).format('YYYY/MM/DD'),
                    comment: object.lastComment,
                    refund,
                    url
                },
                content: template,
                subject: {
                    template: ` ${subjectUser} : ${translate('refundRequest', language)} ${object.reference} (${translate(status, language)})`
                },
                templateDir: refundTemplatesDirectoryPath(language),
                //attachments
            },
            defaultMail
        )

        global.mailer.sendMail(mail, logMessage)
    })
}

const defaultMail = {
    from: '"myCSEManCo" <assistance@mycsemanco.com>',
    replyTo: 'assistance@mycsemanco.com',
    verbose: {
        general: true
    }
}
